import React from 'react'
import {
  Box,
  Container,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import {
  maintainance,
  logo
} from '../assets';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FFCB00',
    },
    secondary: {
      main: '#010039',
    },
  },
});

const MaintenancePage = () => {

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ThemeProvider theme={theme}>
      <Box my={2} textAlign='center'>
        <img src={logo} alt="logo" height="56" />
      </Box>
      <Container
        style={{
          height: 'calc(100% - 88px)',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <img src={maintainance} alt="maintainance image" style={{
          width: isSmall ? '100%' : 'auto',
        }} />
        <Box style={{ maxWidth: isSmall ? '100%' : '40ch' }}>
          <Box my={4}>
            <Typography
              style={{
                fontSize: '1.5rem',
                fontWeight: 600,
                color: '#1E3354'
              }}
            >
              App Maintenance
            </Typography>
            <Typography
              style={{
                fontSize: '1rem',
                color: '#7F8E9D'
              }}
            >
              Our team is working hard to resolve an issue. Please come back soon.
            </Typography>
          </Box>
          {/* this will be feature in next version so commented */}
          {/* <Button fullWidth color="primary" variant='contained'>
            <Typography
              style={{
                fontSize: '1rem',
                fontWeight: 500,
                color: '#010039'
              }}
            >
              Notify Me
            </Typography>
          </Button> */}
        </Box>
      </Container>
    </ThemeProvider>
  )
}

export default MaintenancePage