import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "./Chat.css";
import { profile } from '../assets'
import ChatController, {
  Props,
  configJSON,
} from "../Chat/ChatController";
import CreateIcon from '@material-ui/icons/Create';
import "./Chat.css";
import moment from 'moment';

const LastMessageTime = ({ last_msg_time }: { last_msg_time: string }) => {
  const [time, setTime] = useState(moment(last_msg_time).fromNow());

  useEffect(() => {
    const sub = setInterval(() => {
      setTime(moment(last_msg_time).fromNow());
    }, 1000 * 60); // in every 1 minute

    return () => {
      if (!sub) return;
      clearInterval(sub);
    }
  }, []);

  useEffect(() => {
    setTime(moment(last_msg_time).fromNow());
  }, [last_msg_time]);

  return (
    <Typography style={{ fontSize: "90%", color: "#bdbdbd", fontWeight: 500 }}>
      {time === "a few seconds ago" ? "now" : time}
    </Typography>
  )
}

export default class Conversations extends ChatController {
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <Box display="flex" alignItems="center" mb={.8}
        sx={{
          border: this.props.isSelected ? "1px solid #E0E0E0" : "1px solid #fbfbfb", p: 1, borderRadius: "0.56rem",
        }}
        style={{ cursor: "pointer" }}
        onClick={() => this.props.handleClick(this.props.conversation_sid, this.props.user_id)}
      >
        <img src={this.props.profile_img || profile} alt="user_img"
          style={{
            width: "3rem", height: "3rem", borderRadius: "50%", objectFit: "cover",
          }}
        />
        <Box pl={1.5} width="100%">
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography style={{
              fontWeight: "bold", whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}>{this.props.user_name}</Typography>

            {this.props.last_msg_time && <LastMessageTime last_msg_time={this.props.last_msg_time} />}
          </Box>
          <Box ml="auto" mt={.4} display="flex">

            {this.props.isTyping ? (
              <Box display="flex" alignItems="center">
                <CreateIcon style={{ fill: "#fed227", fontSize: "20" }} />
                <Typography style={{ fontSize: "90%", color: "#fed227", fontWeight: 500, paddingLeft: ".2rem" }}>
                  Typing....
                </Typography>
              </Box>
            ) : (
              <Typography style={{
                display: "inline-block",
                width: "210px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: "90%", fontWeight: 500
              }}>
                {this.props.last_msg}
              </Typography>
            )}

            <Box
              sx={{
                borderRadius: "50%", width: "1.2rem",
                height: "1.2rem", bgcolor: "#fed227", textAlign: "center", ml: "auto",
                visibility: this.props.unread_count && this.props.unread_count > 0 ? "visible" : "hidden"
              }}>
              <Typography style={{ fontSize: "90%", fontWeight: "bold" }}>{this.props.unread_count}</Typography>
            </Box>
          </Box>
        </Box>
      </Box >
    );
    // Customizable Area End
  }
}