import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { DraggableEvent, DraggableData } from "react-draggable";
import { imgPasswordInVisible, imgPasswordVisible } from "../assets";
// @ts-ignore
import StateContext from "../../../../web/src/globalState";
import actionCable from "actioncable";
import moment from "moment";
import calendarSync from "../CalendarSync.web";
const x = require("../../../../framework/src/config");

type DT = { date: Date; start_time: Date; end_time: Date };

export const staticTutorials = [
  {
    id: 1,
    title: "Explore Categories",
    description:
      "Click on the categories tab to find a topic you're interested in.",
    width: 300,
    height: 180,
    gap: 27,
  },
  {
    id: 2,
    title: "Register For A Room",
    description:
      "You can register for an Upcoming Room here or for a Recommended Room in the next tab.",
    width: 550,
    height: 180,
    gap: 27,
  },
  {
    id: 3,
    title: "Create A Room",
    description:
      "Create a room of your choice if you are unable to find one that matches your interest or schedule.",
    width: 550,
    height: 180,
    gap: 27,
  },
  {
    id: 4,
    title: "Messages",
    description: "Chat with your connections.",
    width: 300,
    height: 140,
    gap: 12,
  },
];
// Customizable Area End

export const configJSON = require(".././config");
// @ts-ignore
const google = window.google;
const gapi = window.gapi;

interface RoomData {
  attributes: {
    topic_name: string;
    description: string;
    start_time: string;
    end_time: string;
    share_link?: string;
  };
  id?: string;
}
export interface Props {
  navigation: any;
  id: string;
  history: any;
  location: any;
  match: any;
  onclose: (value: boolean) => {};
  // Customizable Area Start
  handleSearch?: (
    sub_category_id: number,
    topic_id: number,
    selectedCat: number
  ) => void;
  selectedCat?: number;
  handleCatId: (cat_id: number) => void;
  handleFilter: (sub_cat_id: number, topic_id?: number) => boolean;
  handleRouteChange: (newRoute: string) => void;
  handleRoomType: (type: string) => void;
  handleRoomTime: (type: string, dateTime: DT | null) => void;
  room_times: { selected_room: string; start_time: string; end_time: string };
  getCategories: (ID: number) => void;
  getCategoriesData: any;
  isCatLoading: boolean;
  loadingTopics: boolean;
  isFilterLoading: boolean;
  isRoomLoading: boolean;
  roomType: string[];
  categories: any;
  fetchCategories: () => void;
  toggleAvailable: () => void;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  sync: boolean;
  cancelModal: boolean;
  removeModal: boolean;
  penaltyModal: boolean;
  categoriesTitle: string;
  myRoomsData: any;
  isMessagePop: boolean;
  getCategoriesData: any;
  getRoomsType: any;
  myRoomTabId: any;
  removeParticpantId: any;
  url: any;
  recommendedRooms: any;
  registerModal: any; // Upcoming rooms as of now
  walletYCoins: number;
  currentRoomPrice: number;
  addResources: boolean;
  registerAnonymously: boolean;
  isRegisteredFlag: boolean;
  participantsDetails: any;
  loader: boolean;
  currentUpcomingPageCount: number;
  limitUpcomingPage: number;
  lastLoadCountUpcomingPage: number;
  notFinalLoadUpcomingPage: boolean;
  totalUpcomingPages: any;
  nextUpcomingData: any;
  topic_id: any;
  congratulationModal: {
    isOpen: boolean;
    room_name: string;
    dateTime: string;
  };
  selectedRoomId: number;
  selectedRoomDetails: RoomData;
  // controller
  selectedCat: number;
  upcomingRoomsData: any[];
  onlyCategories: any;
  panelID: number | null;
  loadingTopics: boolean;
  filters: any;
  isCatLoading: boolean;
  getHomePageSettings: any;
  getMyConnects: {
    profile_image: false | string;
    full_name: string;
    other_user_id: number;
  }[];
  isRoomTypeLoading: any;
  roomStartNotification: number;
  room_type: string[];
  room_times: { selected_room: string; start_time: string; end_time: string };
  myPastRooms: any;
  seeMoreUpcoming: boolean;
  seeMorePast: boolean;
  selectedFilters: {
    sub_cat_id: number;
    topic_id: number;
    topic_name: string;
  }[];
  isFilterLoading: boolean;
  shareMenuOpen: boolean;
  shareMenuAnchor: any;
  selectedConnects: number[];
  isRoomsFetching: boolean;
  dublicateRoom: {
    isOpen: boolean;
    data: any;
  };
  isFilterDrawerOpen: boolean;
  roomDetails: any;
  isTimePassed: boolean;
  isRouteMy: boolean;
  isRouteUpcoming: boolean;
  isRouteRecommended: boolean;
  showCustomRoom: boolean;
  dateTime: {
    date: any;
    start_time: any;
    end_time: any;
  };
  available_only: boolean;
  page: number;
  hasMore: boolean;
  noRoomList: any[];
  isMobile: boolean | null;
  totalPage: number;
  isSyncWithGoogleCalender: boolean;
  selectedFilter: "categories" | "room_times" | "room_type" | null;
  defaultPosition: { x: number; y: number };
  bulletPoints: string[];
  isShareRoomDialogOpen: boolean;
  searchKey: string;
  sharedRoomDetails: RoomData;
  payload: any;
  apiNeedToCalled: 'REGISTER' | 'CANCEL' | 'CANCEL_HOST' | '';
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RoomsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getMyRoomId: string;

  msg = "";
  type = "";

  categoriesId: string;
  getRoomTypesId: string;
  myRoomTabDeleteId: string;
  getUpcomingRoomsId: string;
  recommendedRoomsId: string;
  getWalletCoinsId: string;
  registerRoomID: any;
  removeFromRoomId: string;
  upcomingRoomsPaginationId: string;
  onlycategoriesId: string;
  getHomePageSettingsId: string;
  verifyCurrentTime: string;
  getMyConnectsId: string;
  myPastRoomsId: string;
  static contextType: any = StateContext;
  getMyUpcomingRoomsId: string;
  bulletPointsId: string;
  shareToConnectsId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      sync: false,
      cancelModal: false,
      penaltyModal: false,
      removeModal: false,
      categoriesTitle: "Relationships",
      myRoomsData: [],
      isMessagePop: false,
      getCategoriesData: [],
      getRoomsType: [],
      myRoomTabId: null,
      removeParticpantId: null,
      url: window.location.href.split("/")[3],
      recommendedRooms: [],
      registerModal: false, // Upcoming rooms as of now
      walletYCoins: 0,
      currentRoomPrice: 0,
      addResources: false,
      registerAnonymously: false,
      isRegisteredFlag: false,
      participantsDetails: [],
      loader: false,
      // Upcoming Rooms Pagination State
      nextUpcomingData: [],
      currentUpcomingPageCount: 1,
      limitUpcomingPage: 1,
      lastLoadCountUpcomingPage: 1,
      notFinalLoadUpcomingPage: false,
      totalUpcomingPages: [],
      topic_id: null,
      // Upcoming Rooms Pagination State
      congratulationModal: {
        isOpen: false,
        dateTime: "",
        room_name: "",
      },
      selectedRoomId: 0,
      selectedRoomDetails: {
        attributes: {
          topic_name: "",
          description: "",
          start_time: "",
          end_time: "",
        },
      },
      selectedCat: 1,
      upcomingRoomsData: [],
      onlyCategories: [],
      panelID: null,
      loadingTopics: false,
      filters: [],
      isCatLoading: false,
      getHomePageSettings: [],
      getMyConnects: [],
      isRoomTypeLoading: true,
      roomStartNotification: 0,
      room_type: [],
      room_times: { end_time: "", selected_room: "", start_time: "" },
      myPastRooms: [],
      seeMorePast: false,
      seeMoreUpcoming: false,
      selectedFilters: [],
      isFilterLoading: false,
      shareMenuOpen: false,
      shareMenuAnchor: null,
      selectedConnects: [],
      isRoomsFetching: false,
      dublicateRoom: {
        isOpen: false,
        data: null,
      },
      isFilterDrawerOpen: false,
      roomDetails: null,
      isTimePassed: false,
      isRouteMy: true,
      isRouteUpcoming: true,
      isRouteRecommended: true,
      showCustomRoom: false,
      dateTime: {
        date: "",
        start_time: moment().startOf("day"),
        end_time: moment().endOf("day"),
      },
      available_only: false,
      page: 1,
      hasMore: true,
      noRoomList: [],
      isMobile: null,
      totalPage: 0,
      isSyncWithGoogleCalender: false,
      selectedFilter: null,
      defaultPosition: { x: 0, y: 0 },
      bulletPoints: [],
      isShareRoomDialogOpen: false,
      searchKey: "",
      sharedRoomDetails: {
        attributes: {
          topic_name: "",
          description: "",
          start_time: "",
          end_time: "",
          share_link: "",
        },
      },
      payload: null,
      apiNeedToCalled: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.getCategories = this.getCategories.bind(this);
    // Customizable Area End
  }

  loadLibrary = () => {
    const loadClient = async () => {
      await gapi.client.init({
        apiKey: 'AIzaSyA-su31F4JN5qBYQlEbJcbVvUyVvIUxjrg',
      });
    };
    gapi.load("client", loadClient);
  };

  toggleTimePassedModal = () => {
    this.setState((prev) => ({ isTimePassed: !prev.isTimePassed }));
  };

  SyncModalOpen = async () => {
    const isAlreadySynced = await calendarSync(
      this.state.upcomingRoomsData,
      "ADD"
    );
    if (!isAlreadySynced) {
      this.handleAlertSuccess("Successfully Synced to Google Calendar");
    } else {
      this.handleAlertSuccess("Already Synced to Google Calendar");
    }
  };
  SyncModalClose = () => this.setState({ sync: false });

  handleToggle = () => {
    this.setState((prevState) => ({
      shareMenuOpen: !prevState.shareMenuOpen,
    }));
  };

  handleShareRoomDialogClose = () => {
    this.setState({ isShareRoomDialogOpen: false });
  };

  handleShareMenuClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      this.state.shareMenuAnchor &&
      this.state.shareMenuAnchor.contains(event.target as HTMLElement)
    ) {
      return;
    }
    this.setState({
      shareMenuOpen: false,
    });
  };

  handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      this.setState({
        shareMenuOpen: false,
      });
    }
  };

  toggleFilterDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      this.setState({ isFilterDrawerOpen: open });
    };

  // For Deleting the room
  CancelModalOpen = (elem: any) =>
    this.setState({
      cancelModal: true,
      myRoomTabId: elem?.id,
      currentRoomPrice: elem?.attributes?.room_price,
      selectedRoomDetails: elem,
    });
  CancelModalClose = () => this.setState({ cancelModal: false });

  PenaltyModalOpen = () => this.setState({ penaltyModal: true });
  PenaltyModalClose = () => this.setState({ penaltyModal: false });

  // For Removing as a participants
  RemoveModalOpen = (elem: any) =>
    this.setState({
      removeModal: true,
      removeParticpantId: elem?.id,
      currentRoomPrice: elem?.attributes?.room_price,
      selectedRoomDetails: elem,
    });
  RemoveModalClose = () => this.setState({ removeModal: false });

  // Register Modal
  RegisterModalOpen = (elem: any) => {
    this.setState({
      registerModal: true,
      currentRoomPrice: elem?.attributes?.room_price,
      selectedRoomId: elem?.id,
      selectedRoomDetails: elem,
    });
    this.getParticipantDetails(elem?.id);
  };
  RegisterModalClose = () => this.setState({ registerModal: false });

  // Congratulation Registering Modal
  CongratulationRegisteringModalOpen = () =>
    this.setState((prev) => ({
      congratulationModal: { ...prev.congratulationModal, isOpen: true },
    }));
  CongratulationRegisteringModalClose = () =>
    this.setState((prev) => ({
      congratulationModal: { ...prev.congratulationModal, isOpen: false },
    }));
  // Customizable Area End

  handleAlert(msg = "") {
    this.msg = msg;
    this.type = "error";
    this.setState({ isMessagePop: true });
  }

  handleAlertSuccess(msg = "") {
    this.msg = msg;
    this.type = "success";
    this.setState({ isMessagePop: true });
  }

  updateSelectedConnects(userId: number) {
    if (this.state.selectedConnects.includes(userId)) {
      this.setState((prevState) => ({
        selectedConnects: prevState.selectedConnects.filter(
          (connect) => connect !== userId
        ),
      }));
    } else {
      this.setState((prevState) => ({
        selectedConnects: [...prevState.selectedConnects, userId],
      }));
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.handleMyRoomData(apiRequestCallId, responseJson);
        this.handleCategoriesData(apiRequestCallId, responseJson);
        this.handleRoomsTypeData(apiRequestCallId, responseJson);
        this.handleUpcomingRoomsData(apiRequestCallId, responseJson);
        this.handleMyUpcomingRoomsData(apiRequestCallId, responseJson);
        this.handleRecommnedRoomsData(apiRequestCallId, responseJson);
        this.handleCategoriesIdDate(apiRequestCallId, responseJson);
        this.handleWalletCoinsData(apiRequestCallId, responseJson);
        this.handleMyRoomsTabDeleteData(apiRequestCallId, responseJson);
        this.handleCancelRoomData(apiRequestCallId, responseJson);
        this.handleRegisterRoomData(apiRequestCallId, responseJson);
        this.handleRoomsPaginationData(apiRequestCallId, responseJson);
        this.handleMyPastRoomsData(apiRequestCallId, responseJson);
        this.handleHomePageSettingsData(apiRequestCallId, responseJson);
        this.handleMyConnectsData(apiRequestCallId, responseJson);
        this.handleBulletPointsData(apiRequestCallId, responseJson);
        this.handleShareToConnects(apiRequestCallId, responseJson);
        this.handleVerifyCurrentTime(apiRequestCallId, responseJson);

        if (responseJson.errors) {
          if (responseJson.errors[0]?.token) {
            this.props.history.push("registration");
          }
        }
      }
    }

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  // handle receive functions method 
  handleMyRoomData = (apiRequestCallId: string, responseJson: any) => {
    if (this.getMyRoomId === apiRequestCallId) {
      this.setState({
        isRoomsFetching: false,
        isFilterLoading: false,
      });
      if (responseJson.data) {
        this.setState({
          upcomingRoomsData: responseJson.data,
          loader: false,
        });
      } else {
        this.setState({ upcomingRoomsData: [], loader: false });
      }
    }
  }

  handleCategoriesData = (apiRequestCallId: string, responseJson: any) => {
    if (this.categoriesId === apiRequestCallId) {
      if (responseJson.data) {
        this.setState({
          getCategoriesData: responseJson.data,
        });
      }
      this.setState({ loadingTopics: false });
    }
  }

  handleRoomsTypeData = (apiRequestCallId: string, responseJson: any) => {
    if (this.getRoomTypesId === apiRequestCallId) {
      if (responseJson.data) {
        this.setState(
          {
            getRoomsType: responseJson.data,
          },
          () =>
            sessionStorage.setItem(
              "roomTypes",
              JSON.stringify(this.state.getRoomsType)
            )
        );
      }
      this.setState({ isRoomTypeLoading: false });
    }
  }

  handleUpcomingRoomsData = (apiRequestCallId: string, responseJson: any) => {
    if (this.getUpcomingRoomsId === apiRequestCallId) {
      this.setState({
        isRoomsFetching: false,
        isFilterLoading: false,
      });
      if (responseJson && responseJson?.data) {
        let newList: any[] = [];
        if (responseJson.data.length > 0) {
          newList = this.state.noRoomList.filter((item: any) =>
            responseJson.data.every(
              (dataItem: any) =>
                dataItem.attributes.topic_id !== item.topic_id
            )
          );
        } else {
          newList = this.state.noRoomList.filter((item: any) =>
            this.state.upcomingRoomsData.every(
              (dataItem: any) =>
                dataItem.attributes.topic_id !== item.topic_id
            )
          );
        }

        this.setState((prev) => ({
          upcomingRoomsData: [
            ...prev.upcomingRoomsData,
            ...responseJson?.data,
          ],
          totalPage:
            this.state.page === 1
              ? responseJson?.meta?.total_rooms || 0
              : this.state.totalPage,
          loader: false,
          noRoomList: newList,
        }));
        return;
      } else if (responseJson?.errors) {
        this.setState({ upcomingRoomsData: [] });
      }
      this.setState({ hasMore: false, loader: false });
    }
  }

  handleMyUpcomingRoomsData = (apiRequestCallId: string, responseJson: any) => {
    if (this.getMyUpcomingRoomsId === apiRequestCallId) {
      this.setState({
        isRoomsFetching: false,
        isFilterLoading: false,
      });
      if (responseJson && responseJson?.data) {
        this.setState((prev) => ({
          upcomingRoomsData: [
            ...prev.upcomingRoomsData,
            ...responseJson?.data,
          ],
          totalPage:
            this.state.page === 1
              ? responseJson?.meta?.total_rooms || 0
              : this.state.totalPage,
          loader: false,
        }));
      } else if (responseJson?.errors) {
        this.setState({ upcomingRoomsData: [] });
      }
      this.setState({ hasMore: false, loader: false });
    }
  }

  handleRecommnedRoomsData = (apiRequestCallId: string, responseJson: any) => {
    if (this.recommendedRoomsId === apiRequestCallId) {
      this.setState({
        isRoomsFetching: false,
        isFilterLoading: false,
      });
      if (responseJson && responseJson?.data) {
        let newList: any[] = [];
        if (responseJson.data.length > 0) {
          newList = this.state.noRoomList.filter((item: any) =>
            responseJson.data.every(
              (dataItem: any) =>
                dataItem.attributes.topic_id !== item.topic_id
            )
          );
        } else {
          newList = this.state.noRoomList.filter((item: any) =>
            this.state.upcomingRoomsData.every(
              (dataItem: any) =>
                dataItem.attributes.topic_id !== item.topic_id
            )
          );
        }
        this.setState((prev) => ({
          upcomingRoomsData: [
            ...prev.upcomingRoomsData,
            ...responseJson?.data,
          ],
          totalPage:
            this.state.page === 1
              ? responseJson?.meta?.total_rooms || 0
              : this.state.totalPage,
          loader: false,
          noRoomList: newList,
        }));
      } else if (responseJson?.errors) {
        this.setState({ upcomingRoomsData: [] });
      }
      this.setState({ hasMore: false, loader: false });
    }
  }

  handleCategoriesIdDate = (apiRequestCallId: string, responseJson: any) => {
    if (this.onlycategoriesId === apiRequestCallId) {
      if (responseJson) {
        this.setState(
          {
            onlyCategories: responseJson?.data,
            isCatLoading: false,
          },
          () => {
            const { onlyCategories } = this.state;
            if (onlyCategories.length > 0) {
              this.setState(
                { selectedCat: Number(onlyCategories[0].id) },
                () => {
                  this.getCategories(this.state.selectedCat);
                }
              );
            }
          }
        );
      }
    }
  }

  handleWalletCoinsData = (apiRequestCallId: string, responseJson: any) => {
    if (this.getWalletCoinsId === apiRequestCallId) {
      if (responseJson && responseJson.data) {
        this.setState({
          walletYCoins: responseJson?.data?.attributes?.ycoins,
        });
      }
    }

  }

  handleMyRoomsTabDeleteData = async (apiRequestCallId: string, responseJson: any) => {
    if (this.myRoomTabDeleteId === apiRequestCallId) {
      if (responseJson) {
        const message =
          responseJson?.message || "Room is cancelled successfully";
        this.handleAlertSuccess(message);
        setTimeout(() => {
          this.setState(
            {
              hasMore: true,
              page: 1,
              upcomingRoomsData: [],
              loader: true,
              isMessagePop: false,
            },
            () => {
              this.handleCallRoomAPI(this.state.filters);
            }
          );
        }, 1000);
        if (localStorage.getItem("googleCalendarSync") === "true") {
          const isAlreadySynced = await calendarSync(
            [this.state.selectedRoomDetails],
            "REMOVE"
          );
          if (!isAlreadySynced) {
            this.handleAlertSuccess(
              "Successfully Synced to Google Calendar"
            );
          } else {
            this.handleAlertSuccess("Already Synced to Google Calendar");
          }
        }
      }
    }
  }

  handleCancelRoomData = async (apiRequestCallId: string, responseJson: any) => {
    if (this.removeFromRoomId === apiRequestCallId) {
      if (responseJson) {
        if (responseJson?.errors) {
          return this.handleAlert(responseJson?.errors[0]);
        }

        const message =
          responseJson?.message || "Room is cancelled successfully";
        this.handleAlertSuccess(message);
        this.context.setCoin(this.state.currentRoomPrice, true);
        setTimeout(() => {
          this.setState(
            {
              hasMore: true,
              page: 1,
              upcomingRoomsData: [],
              loader: true,
              isMessagePop: false,
            },
            () => {
              this.handleCallRoomAPI(this.state.filters);
            }
          );
        }, 1000);
        if (localStorage.getItem("googleCalendarSync") === "true") {
          const isAlreadySynced = await calendarSync(
            [this.state.selectedRoomDetails],
            "REMOVE"
          );
          if (!isAlreadySynced) {
            this.handleAlertSuccess(
              "Successfully Synced to Google Calendar"
            );
          } else {
            this.handleAlertSuccess("Already Synced to Google Calendar");
          }
        }
      }
    }

  }

  handleSyncToGoogleIfTrue = async () => {
    if (localStorage.getItem('googleCalendarSync') === 'true') {
      const isAlreadySynced = await calendarSync(
        [this.state.selectedRoomDetails],
        'ADD'
      );
      if (!isAlreadySynced) {
        this.handleAlertSuccess(
          'Successfully Synced to Google Calendar'
        );
      } else {
        this.handleAlertSuccess('Already Synced to Google Calendar');
      }
    }
  }

  handleRegisterRoomData = async (apiRequestCallId: string, responseJson: any) => {
    if (this.registerRoomID == apiRequestCallId) {
      if (responseJson?.meta) {
        this.handleAlert(responseJson?.meta?.message);
      } else if (responseJson?.errors) {
        this.handleAlert(
          responseJson?.errors[0]?.message || responseJson?.errors[0] ||
          "You have other room scheduled at same time"
        );
      } else if (responseJson) {
        this.handleSyncToGoogleIfTrue();
        this.handleAlertSuccess('Registered Successfully.');
        this.setState({
          selectedRoomId: 0,
          registerModal: false,
          selectedRoomDetails: {
            attributes: {
              topic_name: "",
              description: "",
              start_time: "",
              end_time: "",
            },
          },
        });
        this.setState(
          { hasMore: true, page: 1, upcomingRoomsData: [], loader: true },
          () => {
            this.handleCallRoomAPI(this.state.filters);
            this.CongratulationRegisteringModalOpen();
            this.context.setCoin(responseJson.room_price * -1, true);
          }
        );
        this.handleSyncToGoogleIfTrue();
      } else {
        this.handleAlert("Something went wxrong!");
      }
    }
  }

  handleRoomsPaginationData = (apiRequestCallId: string, responseJson: any) => {
    if (this.upcomingRoomsPaginationId === apiRequestCallId) {
      if (responseJson && responseJson?.data) {
        this.setState({
          upcomingRoomsData: this.state.upcomingRoomsData.concat(
            responseJson?.data
          ),
        });
      }
    }
  }

  handleMyPastRoomsData = (apiRequestCallId: string, responseJson: any) => {
    if (this.myPastRoomsId === apiRequestCallId) {
      if (responseJson && responseJson?.data) {
        this.setState({ myPastRooms: responseJson?.data });
      }
    }
  }

  handleHomePageSettingsData = (apiRequestCallId: string, responseJson: any) => {
    if (this.getHomePageSettingsId === apiRequestCallId) {
      if (responseJson) {
        this.setState({
          getHomePageSettings: responseJson,
        });
      }
    }
  }

  handleMyConnectsData = (apiRequestCallId: string, responseJson: any) => {
    if (this.getMyConnectsId === apiRequestCallId) {
      if (responseJson?.data) {
        this.setState({
          getMyConnects: responseJson.data.map(
            (connect: {
              attributes: {
                profile_image: false | string;
                full_name: string;
                other_user_id: number;
              };
            }) => connect.attributes
          ),
        });
      } else if (responseJson?.errors) {
        this.setState({
          getMyConnects: []
        })
      }
    }
  }

  handleBulletPointsData = async (apiRequestCallId: string, responseJson: any) => {
    if (this.bulletPointsId === apiRequestCallId) {
      // TODO: make this dynamic based on createRoomTextData type ("plain_text" or "html")
      const createRoomTextData = responseJson.data?.find(
        (item: any) =>
          item.attributes.content_key === "ROOM_REGISTER_CONFIRMATION"
      )?.attributes;
      if (createRoomTextData) {
        this.setState({
          bulletPoints: JSON.parse(createRoomTextData.content_value ?? "{}")
            ?.bulletPoints,
        });
      } else if (responseJson.error) {
        this.handleAlert(responseJson?.error);
      } else if (responseJson.errors) {
        this.handleAlert(responseJson?.errors?.message);
      } else {
        this.handleAlert("Something went wrong!");
      }
    }
  };

  handleShareToConnects = async (apiRequestCallId: string, responseJson: any) => {
    if (this.shareToConnectsId === apiRequestCallId) {
      if (!responseJson.error) {
        console.log(responseJson)
        this.handleAlertSuccess("Sent successfully")
        this.handleShareRoomDialogClose();
      } else if (responseJson.error) {
        this.handleAlert(responseJson?.error);
      } else if (responseJson.errors) {
        this.handleAlert(responseJson?.errors?.message);
      } else {
        this.handleAlert("Something went wrong!");
      }
    }
  };

  handleVerifyCurrentTime = (apiRequestCallId: string, responseJson: any) => {
    if (this.verifyCurrentTime === apiRequestCallId) {
      if (responseJson?.unixtime) {
        const api = this.state.apiNeedToCalled;
        if (api === 'REGISTER')
          this.registerRoom(this.state.payload, this.state.isRegisteredFlag, responseJson.unixtime);
        else if (api === 'CANCEL')
          this.removingFromRoom(this.state.removeParticpantId, responseJson.unixtime);
        else if (api === 'CANCEL_HOST')
          this.myTabDeleteRoom(this.state.myRoomTabId, responseJson.unixtime);
      } else {
        this.handleAlert("Something went wrong!");
      }
    }
  };

  bulletPoints() {
    const headers = {
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.bulletPointsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bulletPointsAPIEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  shareToConnects(msg: string) {
    const headers = {
      token: localStorage.getItem("token"),
    };

    const formData = new FormData();
    formData.append("room_id", `${this.state.sharedRoomDetails?.id}`);
    this.state.selectedConnects.map((accountId: number) => formData.append("account_id[]", `${accountId}`))
    formData.append("message", msg)

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.shareToConnectsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.shareToConnectsAPIEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethodType
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    )

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Get Categories Section
  getCategories(value: any) {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.categoriesId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubCategoriesAPiEndPoint + value
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.setState({ loadingTopics: true });
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // My Tab Delete Room Section
  myTabDeleteRoom(id: any, UTime: number | null = null) {
    const isTimeZoneWrong = JSON.parse(sessionStorage.getItem('isTimeZoneWrong') || 'false');

    if (isTimeZoneWrong && !UTime) {
      this.setState({ apiNeedToCalled: 'CANCEL_HOST' });
      this.getCurrentTime();
      return;
    }

    this.setState({ cancelModal: false });

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      TZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      UTime: UTime || Math.floor(new Date().getTime() / 1000).toString()
    };

    const requestBody = {
      room_id: id,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    // this.getRoomTypesId = getValidationsMsg.messageId;
    this.myRoomTabDeleteId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.MyTabDeleteRoomApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethodType
    );

    //pass request parametres
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  removingFromRoom(room_id: any, UTime: number | null = null) {
    const isTimeZoneWrong = JSON.parse(sessionStorage.getItem('isTimeZoneWrong') || 'false');

    if (isTimeZoneWrong && !UTime) {
      this.setState({ apiNeedToCalled: 'CANCEL' });
      this.getCurrentTime();
      return;
    }

    this.setState({ removeModal: false });
    const headers = {
      // "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      TZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      UTime: UTime || Math.floor(new Date().getTime() / 1000).toString()
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeFromRoomId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.removeFromRoomApiEndPoint}${room_id}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePut
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Get Final Filters
  getFilterURl = (URL: string, filters?: any) => {
    let finalURL = URL;

    const finalFilters: any = {};
    const {
      room_type,
      room_times: { selected_room },
      dateTime: { start_time, end_time },
      available_only,
      page,
    } = this.state;

    if (filters && filters.length) {
      const category_ids = filters.map((each: any) => each.cat_id).join(",");
      const sub_category_ids = filters
        .map((each: any) => each.sub_cat.map((sub: any) => sub.id))
        .flat()
        .join(",");
      const topic_ids = filters
        .map((each: any) => each.sub_cat.map((sub: any) => sub.topics).flat())
        .flat()
        .join(",");
      finalFilters["category_id"] = category_ids;
      finalFilters["sub_category_id"] = sub_category_ids;
      finalFilters["topic_id"] = topic_ids;
    }

    if (room_type && room_type.length) {
      const room_types = room_type.join(",");
      finalFilters["room_type_id"] = room_types;
    }

    if (selected_room) {
      if (selected_room !== "custom") {
        finalFilters["start_time"] = "";
        finalFilters["end_time"] = "";
      } else {
        finalFilters["start_time"] = moment(start_time)
          .utcOffset("+05:30")
          .format("YYYY-MM-DD HH:mm:ss");
        finalFilters["end_time"] = moment(end_time)
          .utcOffset("+05:30")
          .format("YYYY-MM-DD HH:mm:ss");
      }
      finalFilters["room_times"] = selected_room.toLowerCase();
    }

    if (available_only) finalFilters["available"] = available_only;

    finalFilters["page"] = page;

    if (this.state.isMobile) {
      finalFilters["per_page"] = 5;
    } else {
      finalFilters["per_page"] = 10;
    }

    Object.keys(finalFilters).forEach((key, indx) => {
      finalURL += `${indx === 0 ? "?" : "&"}${key}=${finalFilters[key]}`;
    });

    return finalURL;
  };

  // Upcoming Rooms Function
  getUpcomingRooms = (filters?: any) => {
    if (this.state.page === 1) {
      this.setState({ loader: true });
    }

    this.setState({ isRoomsFetching: true });
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUpcomingRoomsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.getFilterURl(configJSON.GetUpcomingRoomsApiEndPointOnly, filters)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  // Recommended Room Api Function
  getRecommendedRooms = (filters?: any) => {
    if (this.state.page === 1) {
      this.setState({ loader: true });
    }

    this.setState({ isRoomsFetching: true });
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.recommendedRoomsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.getFilterURl(configJSON.GetRecommendedRoomApiEndPoint, filters)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  // get Wallet coins function
  getWalletCoins() {
    const id = localStorage.getItem("userId");
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getWalletCoinsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getWalletCoinsApiEndPoint + id
      // `${configJSON.getWalletCoinsApiEndPoint}/${id}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // fetch unix time 
  getCurrentTime = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyCurrentTime = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/get_time`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Register Room Function
  registerRoom(payload: any, isRegisteredFlag: boolean, UTime: null | number = null) {
    const isTimeZoneWrong = JSON.parse(sessionStorage.getItem('isTimeZoneWrong') || 'false');

    this.setState({ isRegisteredFlag, payload });

    if (isTimeZoneWrong && !UTime) {
      this.setState({ apiNeedToCalled: 'REGISTER' });
      this.getCurrentTime();
      return;
    }

    const headers = {
      // "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      TZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      UTime: UTime || Math.floor(new Date().getTime() / 1000).toString()
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.registerRoomID = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.RegisterRoomApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethodType
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      payload
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getParticipantDetails(id: any) {
    const matched_id = this.state.upcomingRoomsData.filter((item: any) => {
      return item.id === id;
    });

    this.setState({ participantsDetails: matched_id });
  }

  // Category Controller
  // Categories data

  updateCatID = (cat_id: number) => {
    this.setState({ selectedCat: cat_id });
  };

  onlyCategories = () => {
    this.setState({ isCatLoading: true });
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.onlycategoriesId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoriesApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  handleSearch = (
    sub_category_id: number,
    topic_id: number,
    selectedCat: number
  ) => {
    this.setState({ isFilterLoading: true, upcomingRoomsData: [], page: 1 });
    let isEmpty = false;
    const category_id = selectedCat;

    const { filters } = this.state;
    // check if category id already present
    const catIndx = filters.findIndex(
      (each: any) => each?.cat_id === category_id
    );
    // if category not present
    if (catIndx === -1) {
      const newFilter = {
        cat_id: category_id,
        sub_cat: [{ id: sub_category_id, topics: [topic_id] }],
      };
      // setting up the state
      this.setState(
        (prev) => ({
          filters: [...prev.filters, newFilter],
          hasMore: true,
          page: 1,
          upcomingRoomsData: [],
        }),
        () => {
          this.handleCallRoomAPI(this.state.filters);
        }
      );
    } else {
      // if category already present
      const selectedCat = filters[catIndx];

      // check if sub category is already present
      const sub_cat = selectedCat.sub_cat;
      const subIndx = sub_cat.findIndex(
        (each: any) => each?.id === sub_category_id
      );

      // if sub category not present
      if (subIndx === -1) {
        sub_cat.push({ id: sub_category_id, topics: [topic_id] });
      } else {
        // if sub category present
        const selectedSubCat = sub_cat[subIndx];

        // check if topic is already present
        const topics = selectedSubCat.topics;
        const topicIndx = topics.findIndex((each: any) => each === topic_id);

        if (topicIndx === -1) {
          // topic is not present in the array
          topics.push(topic_id);
        } else {
          // if topic is present in the array, remove
          topics.splice(topicIndx, 1);

          if (topics.length === 0) {
            // no topic is present there
            sub_cat.splice(subIndx, 1);

            if (sub_cat.length === 0) {
              // if no sub category is present in the array, remove the category
              isEmpty = true;
              const tempFilters = [...filters];

              tempFilters.splice(catIndx, 1);
              this.setState(
                {
                  filters: tempFilters,
                  hasMore: true,
                  page: 1,
                  upcomingRoomsData: [],
                },
                () => {
                  this.handleCallRoomAPI(this.state.filters);
                }
              );
            }
          }
        }
      }

      // setting up the state
      if (!isEmpty) {
        const tempFilters = [...filters];
        tempFilters[catIndx] = selectedCat;

        this.setState({ filters: tempFilters }, () => {
          this.handleCallRoomAPI(this.state.filters);
        });
      }
    }
  };

  // handle panel toggle
  handlePanel = (panelID: number) => {
    if (panelID === this.state.panelID) {
      this.setState({ panelID: null });
      return;
    }

    this.setState({ panelID });
  };

  // check if Accordion need to expand or not
  hasFilter = (sub_cat_id: number, topic_id?: number) => {
    const category: any[] = this.state.filters.filter(
      (each: any) => each.cat_id === this.state.selectedCat
    );
    if (!category.length) return false;

    const subCatIndx: number = category[0].sub_cat.findIndex(
      (each: any) => each.id == sub_cat_id
    );
    if (subCatIndx === -1) return false;

    // this.state.panelID, panelID ( use if you want to open only the last Accordion)
    if (!topic_id) return true;
    const subCat = category[0].sub_cat[subCatIndx];

    return subCat.topics.includes(Number(topic_id));
  };

  handleCallRoomAPI = (filters: any) => {
    const params = new URLSearchParams(this.props.history.location.search);
    const room_type = params.get('type');

    // handle display filters
    const selectedTopics = this.state.filters
      .map((cat: any) => {
        return cat.sub_cat
          .map((sub: any) => {
            return sub.topics.map((topic: any) => {
              const indx = this.state.selectedFilters.findIndex(
                (each) => each?.sub_cat_id == sub.id && each?.topic_id == topic
              );

              if (indx === -1) {
                const sub_cat = this.state.getCategoriesData.filter(
                  (each: any) => each.id == sub.id
                );
                const topics: any[] = sub_cat[0].attributes.topics;
                let selectedTopic: any;
                if (topics.length) {
                  selectedTopic = topics.filter((each) => each.id == topic);
                }

                return {
                  cat_id: cat.cat_id,
                  sub_cat_id: sub.id,
                  topic_id: topic,
                  topic_name: selectedTopic ? selectedTopic[0].name : "",
                  topic_description: selectedTopic
                    ? selectedTopic[0].description
                    : "",
                };
              } else {
                const prevFilter = this.state.selectedFilters[indx];
                return { ...prevFilter };
              }
            });
          })
          .flat();
      })
      .flat();

    // let newList = [...selectedTopics.filter((item: any) => this.state.upcomingRoomsData.includes(item.topic_id))]
    this.setState(
      { selectedFilters: selectedTopics, noRoomList: selectedTopics },
      () => {
        if (room_type === "upcoming") {
          // Call upcoming rooms API
          this.getUpcomingRooms(filters);
        } else if (room_type === "recommended") {
          // Call recommended rooms API
          this.getRecommendedRooms(filters);
        } else if (room_type === "my") {
          // Call my rooms API
          this.getMyUpcomingRooms(filters);
          this.getMyPastRooms();
        }
      }
    );
  };

  handleDeleteShowFilter = (details: any) => {
    const { selectedFilters } = this.state;

    const indx = selectedFilters.findIndex(
      (each) =>
        each?.sub_cat_id == details.sub_cat_id &&
        each?.topic_id == details.topic_id
    );
    if (indx === -1) return;

    const tempFilters = [...selectedFilters];
    tempFilters.splice(indx, 1);
    this.setState({ selectedFilters: tempFilters }, () => {
      // deselect the filter
      this.handleSearch(details.sub_cat_id, details.topic_id, details.cat_id);
    });
  };

  handleRouteChange = (newRoute: string) => {
    this.setState({ page: 1, hasMore: true }, () => {
      const params = new URLSearchParams(this.props.history.location.search);
      const prevRoute = params.get('type');

      if (this.state.isRoomsFetching) {
        this.handleAlert(`Please wait untill ${prevRoute} rooms data is loading....`);
        return;
      }

      if (prevRoute === newRoute) return;

      this.setState({ upcomingRoomsData: [] }, () => {
        this.props.history.replace(
          `${this.props.history.location.pathname}?type=${newRoute}`
        );
      });
    });
  };

  /* Manage Room Types */

  // Get Room Types Section
  getRoomTypes() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRoomTypesId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.GetRoomTypesApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Home Page Setting Image and text function
  getHomePageSettings() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getHomePageSettingsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.GetHomePageSettingsApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Get all connections
  getMyConnects(searchKey: string = "") {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMyConnectsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.GetMyConnectsApiEndPoint}&search=${searchKey}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Handle Room Type Filters
  handleRoomType = (id: string) => {
    const tempTypes = [...this.state.room_type];
    const findIndx = this.state.room_type.findIndex((each) => each === id);

    if (findIndx === -1) tempTypes.push(id);
    else tempTypes.splice(findIndx, 1);

    this.setState(
      {
        room_type: tempTypes,
        hasMore: true,
        page: 1,
        upcomingRoomsData: [],
      },
      () => {
        this.handleCallRoomAPI(this.state.filters);
      }
    );
  };

  convertToIST = (dateUTC: Date) => {
    return `${dateUTC.getFullYear()}-${dateUTC.getMonth() + 1
      }-${dateUTC.getDate()} ${dateUTC.getHours()}:${dateUTC.getMinutes()}`;
  };

  handleRoomTime = (room_time: string, dateTime: DT | null) => {
    let selected_room = "";
    if (this.state.room_times.selected_room === room_time) {
      if (room_time === "custom" && dateTime) {
        selected_room = "custom";
      } else {
        selected_room = "";
      }
    } else {
      selected_room = room_time;
    }

    this.setState(
      (prev) => ({
        room_times: {
          ...prev.room_times,
          selected_room,
        },
        hasMore: true,
        page: 1,
        upcomingRoomsData:
          room_time === "custom" && !dateTime ? prev.upcomingRoomsData : [],
      }),
      () => {
        if (room_time == "custom" && dateTime) {
          const { date, start_time, end_time } = dateTime as DT;
          this.setState(
            {
              dateTime: {
                date: date,
                start_time: start_time,
                end_time: end_time,
              },
            },
            () => this.handleCallRoomAPI(this.state.filters)
          );
        } else if (
          room_time !== "custom" ||
          (room_time == "custom" && selected_room === "" && !dateTime)
        ) {
          this.handleCallRoomAPI(this.state.filters);
        }
      }
    );
  };

  /* Handle Cancel  */

  handleCancel = (room: any) => {
    this.setState({ currentRoomPrice: room.attributes.room_price });
    if (!room?.id) return;

    const isHost = room.attributes.is_host;
    if (isHost) {
      this.CancelModalOpen(room);
    } else {
      this.RemoveModalOpen(room);
    }
  };

  attachNotificationCable = () => {
    const cable = actionCable.createConsumer(`${x.baseURL}/cable`);
    let account_id = parseInt(localStorage.getItem("userId") || "0");
    cable.subscriptions.create(
      {
        channel: "AccountsChannel",
        account_id,
      },
      {
        connected: () => {
          // console.log('CONNECTED');
        },
        disconnected: () => {
          // console.log('DISCONNECTED');
        },
        received: (data: any) => {
          if (data?.status) return;
          // console.log('Notification for 5 min timer', data);
          this.setState({ roomStartNotification: data.room_id });
        },
      }
    );
  };

  handleLoad = () => {
    const isLoad = JSON.parse(sessionStorage.getItem("isLoad") as string);
    if (isLoad) {
      this.setState(
        { loader: true, hasMore: true, page: 1, upcomingRoomsData: [] },
        () => {
          this.handleCallRoomAPI(this.state.filters);
        }
      );

      setTimeout(() => {
        sessionStorage.setItem("isLoad", "false");
      }, 1000);
    }
  };

  updateDashboardTutorial = () => {
    const headers = {
      "Content-Type": configJSON.updateTutorialsApiContentType,
      token: localStorage.getItem("token"),
    };

    const body = {
      flags: {
        is_dashboard_training_done: true,
        is_profile_training_done: false,
      },
    };

    const msg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    msg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    msg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateTurtoialsMethod
    );

    msg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateTutorialsApiEndPoint
    );

    msg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    localStorage.setItem("is_dashboard_training_done", "true");
    runEngine.sendMessage(msg.id, msg);
  };

  handleResize = () => {
    const isMobile = window.innerWidth < 768;
    this.setState({ isMobile }, () => {
      // load tutorials if user is new & not on mobile
      if (!this.state.isMobile) {
        const is_dashboard_training_done = JSON.parse(
          localStorage.getItem("is_dashboard_training_done") || "false"
        );
        this.context.setTutorials(
          staticTutorials,
          this.updateDashboardTutorial,
          !is_dashboard_training_done
        );
      }
    });
  };

  // on mount call APIs
  async componentDidMount() {
    this.attachNotificationCable();
    const route = this.props.history.location;
    if (!route.search) {
      this.props.history.replace(
        `${this.props.history.location.pathname}?type=upcoming`
      );
    }
    this.setState({
      isRouteMy: false,
      isRouteUpcoming: false,
      isRouteRecommended: false,
    });
    // listen stroage event change
    window.addEventListener("storage", this.handleLoad, false);
    this.handleResize();
    window.addEventListener("resize", this.handleResize);

    // update user-timezone
    this.verifyUserTimeZone();
    window.addEventListener("focus", this.verifyUserTimeZone, false);
    this.bulletPoints();
  }

  async componentWillUnmount() {
    this.context.changeCreateRoomModal(false, null);
    window.removeEventListener("storage", this.handleLoad, false);
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("focus", this.verifyUserTimeZone, false);
  }

  // Past Room Function
  getMyPastRooms() {
    this.setState({ loader: true });

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.myPastRoomsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.GetMyPastRooms
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Get My Upcoming Rooms
  getMyUpcomingRooms = (filters?: any) => {
    this.setState({ loader: true });

    this.setState({ isRoomsFetching: true });
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMyUpcomingRoomsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.getFilterURl(configJSON.GetMyUpcomingRoomsApiEndPoint, filters)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  /* HANDLE DUBLICATE ROOM */
  setData = (room_data: any) => {
    this.setState({ dublicateRoom: { isOpen: true, data: room_data } });
  };

  closeUpdateModal = () => {
    this.setState({ dublicateRoom: { isOpen: false, data: null } });
  };

  getCurrentWeekSlot = () => {
    let currentDate = moment();
    let weekStart = currentDate;

    let days = [];

    for (let i = 0; i <= 6; i++) {
      let dateobj = moment(weekStart)
        .add(i, "days")
        .format("MMMM-DD-ddd")
        .split("-");
      let obj = {
        month: dateobj[0],
        date: dateobj[1],
        day: dateobj[2],
        fullDateObj: moment(weekStart).add(i, "days").format("YYYY-MM-DD"),
      };
      days.push(obj);
    }

    return days;
  };

  toggleAvailability = () => {
    this.setState(
      (prev) => ({
        available_only: !prev.available_only,
        hasMore: true,
        page: 1,
        upcomingRoomsData: [],
      }),
      () => {
        this.handleCallRoomAPI(this.state.filters);
      }
    );
  };

  handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (this.state.page === value) return;

    // fetch data with page number
    this.setState({ page: value, upcomingRoomsData: [], loader: true }, () => {
      this.handleCallRoomAPI(this.state.filters);
    });
  };

  handleChangeMobileFilter = (
    filter: "categories" | "room_times" | "room_type" | null
  ) => {
    sessionStorage.setItem("choosenFilter", filter || "");
    this.setState({ selectedFilter: filter });
  };

  handleDrag = (e: DraggableEvent, data: DraggableData) => {
    if (data.y >= 50) {
      // Change this value to control the minimum distance to close the slider.
      this.handleChangeMobileFilter(null);
    } else {
      this.setState({ defaultPosition: { x: 0, y: 0 } });
    }
  };


  updateTimeZone = (timeZone: string) => {
    const headers = {
      'Content-Type': 'application/json',
      'token': localStorage.getItem("token")
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/accounts/set_time_stamp'
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(timeZone)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  verifyUserTimeZone = () => {
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const oldTimeZone = localStorage.getItem('timeZone');

    // if user logged-in for the first time
    if (!oldTimeZone || (currentTimeZone !== oldTimeZone)) {
      localStorage.setItem('timeZone', currentTimeZone);
      this.updateTimeZone(currentTimeZone);
    }
  };
  // Customizable Area End
}
