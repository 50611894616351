// Customizable Area Start
import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";

interface Props extends RouteComponentProps { }

const DisableBackButton: React.FC<Props> = ({ history }) => {

  const disableBack = (e: PopStateEvent) => {
    const result = window.confirm("Are you sure you want to leave this call?");
    if (result) {
      history.goBack();
    } else {
      window.history.pushState(null, "", window.location.href);
    }
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", disableBack);
    return () => window.removeEventListener("popstate", disableBack);
  }, []);

  return null;
};

export default DisableBackButton;
// Customizable Area End
