// Customizable Area Start

import React from "react";
import "./createRoom.styles.css";
import CreateRoomController, { Props } from "./CreateRoomController";
import {
  Box,
  Button,
  Grid,
  Typography,
  FormControl,
  TextField,
  Switch,
  Checkbox,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from "@material-ui/core";
import { Formik, Field, FieldArray } from "formik";
import AttachmentIcon from "@material-ui/icons/Attachment";
import DropDown, {
  SaparateValuesDropDown,
} from "../../../email-account-registration/src/EmailRegister/Dropdown.web";
import CloseIcon from "@material-ui/icons/Close";
import { addIcon, clock } from "../assets";
import moment from "moment";
import AlertBlock from "../../../alert/src/Alert.web";
import RefillWallet from "../../../Payments/src/refillwallet/RefillWallet.web";
import * as Yup from "yup";
import { CreateRoomSuccessDialog } from "./CreateRoomSuccessPopup.web";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFCB00",
    },
    secondary: {
      main: "#010039",
    },
  },
});

type RemoveFunction = (index: number) => void;
type PushFunction = (value: any) => void;

// utility function : validate resource url
function isValidUrl(url: string): boolean {
  try {
    const pattern =
      /^(https?:\/\/)([A-Z0-9-]+\.)?(maps\.)?[A-Z0-9-]{2,256}\.[a-z]{2,6}([/?#].*)?$/gi;

    let result = pattern.test(
      url.includes("http") || url.includes("https") ? url : "https://" + url
    );
    return result;
  } catch (e) {
    return false;
  }
}

function getUpdatedStart(values: any) {
  let updatedStart = moment(values.roomTime);

  if (values.AM_OR_PM == "AM") {
    updatedStart =
      //@ts-ignore
      updatedStart.format("DD-MM-YY HH:mm:ss").split(" ")[1] > 12
        ? updatedStart.subtract(12, "h")
        : updatedStart;
  } else {
    updatedStart =
      //@ts-ignore
      updatedStart.format("DD-MM-YY HH:mm:ss").split(" ")[1] < 12
        ? updatedStart.add(12, "h")
        : updatedStart;
  }

  return updatedStart;
}
class CreateRoom extends CreateRoomController {
  constructor(props: any) {
    super(props);
    // @ts-ignore
    this.myref = React.createRef();
  }

  createRoomSchema = Yup.object().shape({
    categories: Yup.string().required("Please select a category"),

    subcategories: Yup.string().required("Please select a subcategory"),

    topic: Yup.string().required("Please select a topic"),

    room: Yup.string().required("Please select a room type"),

    yourTake: Yup.string()
      .required("Please enter Your Take")
      .min(50, "Your Take must be at least 50 characters"),

    roomTime: Yup.string().required("Please select room time"),
  });

  handleClose = () => {
    this.props.onclose(false);
  };

  handleCloseAlert = () => {
    this.setState({ isMessagePop: false });
  };

  async componentDidMount(): Promise<void> {
    this.bulletPoints();
    this.getCategory();
    this.getRoomType();
    await this.getCurrentWeek();

    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  async componentWillUnmount(): Promise<void> {
    window.removeEventListener("resize", this.handleResize);
  }

  getCurrentWeek = async () => {
    var currentDate = moment();

    var weekStart = currentDate;
    var weekEnd = currentDate.add(7);

    var days = [];

    for (var i = 0; i <= 6; i++) {
      let dateobj = moment(weekStart)
        .add(i, "days")
        .format("MMMM-DD-ddd")
        .split("-");
      let obj = {
        month: dateobj[0],
        date: dateobj[1],
        day: dateobj[2],
        fullDateObj: moment(weekStart).add(i, "days").format("YYYY-MM-DD"),
      };
      days.push(obj);
    }

    this.setState({
      currentWeekSlot: days,
      currentMonth: days[0].month,
      currentTime: moment().format("LT"),
    });
    let todayDay = moment().format("DD");

    this.setState({ selectedDate: todayDay });
    return days;
  };

  handleOpen = () => {
    // @ts-ignore
    this.myref.current.handleOpen();
  };

  getTopics = (subcategories: any[]) => {
    const isModerator = JSON.parse(
      localStorage.getItem("isModerator") as string
    );

    if (!subcategories.length) {
      return [];
    }

    const subs = this.state.subCategoryList?.filter(
      (item: any) => item.attributes.name === subcategories
    )[0];
    const topics = subs?.attributes?.topics;

    if (!topics.length) {
      return [];
    }

    if (isModerator) {
      return topics.map((each: any) => each.name);
    }

    return topics
      .filter((each: any) => each.moderator_only === false)
      .map((each: any) => each.name);
  };

  renderMobileOverlay() {
    return (
      <>
        {this.state.isMobile && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100dvh",
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              zIndex: 1200,
              backdropFilter: "blur(4px)",
            }}
            onClick={() => this.handleClose()}
          />
        )}
      </>
    );
  }

  renderDivider() {
    return <>{this.state.isMobile && <Divider />}</>;
  }

  handleOnSubmit = (values: any) => {
    if (values.anonymus && values.anonymouslyName == "") {
      this.handleAlert("Please enter your screen name.");
      return;
    }

    function areAllUrlsValid(resourceArray: string[]): boolean {
      return resourceArray.every(isValidUrl);
    }

    const allUrlsValid: boolean = areAllUrlsValid(values.resource);

    if (values.resourceExtended && !allUrlsValid) return;

    if (!values.roomId) {
      this.handleAlert("Please select a room type.");
      return;
    }

    if (moment(values.roomTime).isAfter(moment())) {
      let updatedStart = getUpdatedStart(values);

      const updatedStartTime = moment(updatedStart).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      const payload = new FormData();
      payload.append("room[start_time]", updatedStartTime);
      payload.append(
        "room[end_time]",
        updatedStart
          .add({ hours: 1, minutes: 59, seconds: 59 })
          .format("YYYY-MM-DD HH:mm:ss")
      );
      payload.append("room[your_take]", values.yourTake);

      const validResource = values.resource.filter((item: any) => item);
      validResource.forEach((item: any) => {
        payload.append("room[room_resources_attributes][][url]", item);
      });
      payload.append("room[is_global]", `${values.global}`);
      payload.append("room[is_anonymously]", `${values.anonymus}`);
      payload.append("room[category_id]", values.categoriesId);
      payload.append("room[sub_category_id]", values.subcategoriesId);
      payload.append("room[topic_id]", values.topicId);
      payload.append("room[room_type_id]", values.roomId);
      payload.append("room[anonymously_name]", values.anonymouslyName);

      this.createRoom(payload);
    } else {
      this.handleAlert("Please select a time in the future.");
    }
  };

  getPopupStyles(isMobile: boolean): React.CSSProperties {
    const styles: React.CSSProperties = {
      width: isMobile ? "100%" : "482px",
      margin: "auto",
      marginTop: "30px",
      position: "absolute",
      background: "#fff",
      zIndex: isMobile ? 1300 : 1,
      top: isMobile ? "5rem" : "34px",
      right: isMobile ? 0 : "-1%",
      borderRadius: isMobile ? 0 : "10px",
      paddingTop: isMobile ? 0 : "20px",
      borderTopLeftRadius: isMobile ? 20 : 0,
      borderTopRightRadius: isMobile ? 20 : 0,
    };

    return styles;
  }

  getGridStyles(isMobile: boolean): React.CSSProperties {
    const styles: React.CSSProperties = {
      borderRadius: isMobile ? 0 : "10px",
    };

    return styles;
  }

  getMainBoxStyles(isMobile: boolean): React.CSSProperties {
    const styles: React.CSSProperties = {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "20px 20px 10px 20px",
      textAlign: isMobile ? "center" : "left",
    };

    return styles;
  }

  getButtonStyles(isMobile: boolean): React.CSSProperties {
    const styles: React.CSSProperties = {
      marginRight: isMobile ? "-2rem" : "inherit",
    };

    return styles;
  }

  getHeadingStyles(isMobile: boolean): React.CSSProperties {
    const styles: React.CSSProperties = {
      fontWeight: 500,
      paddingBottom: "16px",
      textAlign: "left",
      marginTop: this.state.isMobile ? "15px" : "30px",
      color: "#1C1B48",
    };

    return styles;
  }

  getInitialValues(attributes: any) {
    return {
      categories: attributes?.category_name || "",
      categoriesId: attributes?.category_id || "",
      subcategories: attributes?.sub_category_name || "",
      subcategoriesId: attributes?.sub_category_id || "",
      topic: attributes?.topic_name || "",
      topicId: attributes?.topic_id || "",
      room: {},
      roomId: "",
      roomPrice: 0,
      dateTime: "",
      resource: [""],
      // resource1: "",
      // resource2: "",
      // resource3: "",
      join: "",
      yourTake: "",
      global: true,
      anonymus: false,
      roomTime: moment(this.state.selectedDateInTimePiker),
      timePicker: null,
      joinedAnonymously: false,
      resourceExtended: false,
      anonymouslyName: "",
      AM_OR_PM: "AM",
      hasError: [true],
    };
  }

  showDynamicHeading = () => {
    return (
      <Typography
        style={{
          fontWeight: 600,
          fontSize: "19px",
          color: "#292929",
          flex: 1,
        }}
        className="drag-create-room"
      >
        {this.props.prevData ? "Dublicate Room" : "Create a Room"}
      </Typography>
    );
  };

  renderCategory = (
    values: any,
    handleChange: any,
    setFieldValue: any,
    handleBlur: any,
    errors: any,
    touched: any
  ) => {
    return (
      <>
        {!this.props.prevData && (
          <>
            <DropDown
              name="categories"
              list={this.state.categoryList?.map(
                (item: any) => item.attributes.name
              )}
              value={values.categories}
              onChange={(e) => {
                const categoryId = this.state.categoryList?.filter((item) => {
                  return item.attributes.name === e.target.value;
                });
                handleChange(e);

                setFieldValue("categoriesId", categoryId[0].id);
                setFieldValue("subcategoriesId", "");
                setFieldValue("subcategories", "");
                setFieldValue("topic", "");
                setFieldValue("topicId", "");

                this.getSubCategory(categoryId[0].id);
              }}
              className="MuiOutlinedInput-root year-select"
              defaultText="Search for Category"
              onBlur={handleBlur}
            />
            {errors.categories && (
              <span
                style={{
                  display: "block",
                  color: "red",
                  fontSize: "12px",
                  textAlign: "start",
                }}
              >
                {errors.categories && touched.categories && errors.categories}
              </span>
            )}
          </>
        )}

        {this.props.prevData && (
          <input
            value={this?.props?.prevData?.attributes?.category_name || ""}
            className="input-box"
            disabled={true}
          />
        )}
      </>
    );
  };

  renderSubcategory = (
    values: any,
    handleChange: any,
    setFieldValue: any,
    handleBlur: any,
    errors: any,
    touched: any
  ) => {
    return (
      <>
        {!this.props.prevData && (
          <>
            <DropDown
              name="subcategories"
              list={this.state.subCategoryList?.map(
                (item: any) => item.attributes.name
              )}
              value={values.subcategories}
              onChange={(e) => {
                handleChange(e);
                const subcategoriesId = this.state.subCategoryList?.filter(
                  (item) => {
                    return item.attributes.name === e.target.value;
                  }
                );
                setFieldValue("subcategoriesId", subcategoriesId[0].id);
                setFieldValue("topic", "");
                setFieldValue("topicId", "");
              }}
              className="MuiOutlinedInput-root year-select"
              defaultText="Search for Subcategory"
              onBlur={handleBlur}
            />

            {errors.subcategories && (
              <span
                style={{
                  display: "block",
                  color: "red",
                  fontSize: "12px",
                  textAlign: "start",
                }}
              >
                {errors.subcategories &&
                  touched.subcategories &&
                  errors.subcategories}
              </span>
            )}
          </>
        )}

        {this.props.prevData && (
          <input
            value={this?.props?.prevData?.attributes?.sub_category_name || ""}
            className="input-box"
            disabled={true}
          />
        )}
      </>
    );
  };

  renderTopics = (
    values: any,
    handleChange: any,
    setFieldValue: any,
    handleBlur: any,
    errors: any,
    touched: any
  ) => {
    return (
      <>
        {!this.props.prevData && (
          <>
            <DropDown
              name="topic"
              list={this.getTopics(values.subcategories)}
              value={values.topic}
              onChange={(e) => {
                const topicId = this.state.subCategoryList
                  ?.filter(
                    (item: any) => item.attributes.name === values.subcategories
                  )[0]
                  ?.attributes.topics?.filter((item: any) => {
                    return item.name === e.target.value;
                  });
                setFieldValue("topicId", topicId[0].id);
                handleChange(e);
              }}
              className="MuiOutlinedInput-root year-select"
              defaultText="Search for a Topic"
              onBlur={handleBlur}
            />

            {errors.topic && (
              <span
                style={{
                  display: "block",
                  color: "red",
                  fontSize: "12px",
                  textAlign: "start",
                }}
              >
                {errors.topic && touched.topic && errors.topic}
              </span>
            )}
          </>
        )}

        {this.props.prevData && (
          <input
            value={this?.props?.prevData?.attributes?.topic_name || ""}
            className="input-box"
            disabled={true}
          />
        )}
      </>
    );
  };

  renderRoomsType = (
    values: any,
    handleChange: any,
    setFieldValue: any,
    handleBlur: any,
    errors: any,
    touched: any
  ) => {
    return (
      <>
        <SaparateValuesDropDown
          className="MuiOutlinedInput-root year-select"
          startContent="name"
          endContent="ycoins"
          name="room"
          contents={this.state.roomTypeList}
          onChange={(e) => {
            handleChange(e);
            setFieldValue("roomId", e.target.value.id);
            setFieldValue("roomPrice", e.target.value.attributes?.ycoins);
          }}
          list={[]}
          // @ts-ignore
          value={values.room.attributes}
          defaultText="Search for a Room Type"
        />

        {errors.room && (
          <span
            style={{
              display: "block",
              color: "red",
              fontSize: "12px",
              textAlign: "start",
            }}
          >
            {errors.room && touched.room && errors.room}
          </span>
        )}
      </>
    );
  };

  renderDataAndTime = (
    values: any,
    handleChange: any,
    setFieldValue: any,
    handleBlur: any,
    errors: any,
    touched: any
  ) => {
    return (
      <div className="room-times bgWhite" style={{ width: "100%" }}>
        <div
          className="p15"
          style={{
            padding: this.state.isMobile ? "15px 0" : "15px",
          }}
        >
          <p className="fs18 fw600 fw500 mt0 mb15">
            {moment().format("MMMM YYYY")}
          </p>
          <div className="rooms-details-calendar">
            {this.state?.currentWeekSlot?.length > 0 &&
              this.state?.currentWeekSlot?.map((value: any, index: any) => {
                return (
                  <div
                    key={index}
                    className="text-center"
                    onClick={() => {
                      this.setState({ selectedDate: value?.date }, () => {
                        let updatedDate = moment(values.roomTime);

                        updatedDate = updatedDate
                          .set("date", Number(this.state.selectedDate))
                          .set("month", value.month)
                          .set("year", moment().year());

                        setFieldValue("roomTime", updatedDate.toDate());
                      });
                    }}
                  >
                    <span className="fs12 fw500">{value?.day}</span>
                    <div
                      className={
                        this.state?.selectedDate == value?.date
                          ? "calen-div dateSelectedClass"
                          : "calen-div"
                      }
                    >
                      <span className="fs16 fw500">{value?.date}</span>
                    </div>
                  </div>
                );
              })}
          </div>
          {/* Calendar Part  */}
        </div>
        {/* Time  */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <label
            style={{
              fontWeight: 600,
              fontSize: "15px",
              color: "#101038",
              paddingLeft: this.state.isMobile ? 0 : "15px",
              marginRight: "2rem",
            }}
          >
            Time
          </label>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ThemeProvider theme={theme}>
              <KeyboardTimePicker
                keyboardIcon={<img height="16px" src={clock} />}
                inputVariant="outlined"
                onChange={(e) => {
                  if (!e) return;

                  const updatedDate = moment(values.roomTime)
                    .set("hour", e.getHours())
                    .set("minute", e.getMinutes())
                    .toDate();

                  setFieldValue("roomTime", updatedDate);
                  this.setState({ selectedDateInTimePiker: e });
                }}
                value={values.roomTime}
                minutesStep={15}
                name="roomTime"
                id="roomTime"
              />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </div>
        <p
          style={{
            fontSize: "10px",
            fontWeight: 500,
            color: "#0005",
            padding: this.state.isMobile ? "10px 0" : "10px 20px",
          }}
        >
          All calls are scheduled for 1 hour and extendable to 2 hours at no
          extra cost.
        </p>

        {sessionStorage.getItem("isTimeZoneWrong") === "true" && (
          <p
            style={{
              fontSize: "12px",
              fontWeight: 500,
              color: "#ef3427",
              padding: this.state.isMobile ? "10px 0" : "0 20px",
              marginBottom: '1rem'
            }}
          >
            Warning: Your device time may be incorrect. This room will be scheduled for {moment(values.roomTime).format('hh:mm A')} {localStorage.getItem("abbreviation") || "UTC"}.
          </p>
        )}
      </div>
    );
  };

  renderYourTake = (
    values: any,
    handleChange: any,
    setFieldValue: any,
    handleBlur: any,
    errors: any,
    touched: any
  ) => {
    return (
      <>
        <TextField
          className="text-area"
          multiline
          name="yourTake"
          variant="outlined"
          value={values.yourTake}
          onChange={(e) => {
            e.target.value = e.target.value?.trimStart();
            handleChange(e);
          }}
          margin="normal"
          inputProps={{
            maxLength: 300,
          }}
          helperText={`${300 - values.yourTake.length} Characters`}
          onBlur={handleBlur}
          placeholder="Please share what you will be bringing to this conversation - a subtopic, experience, question, perspective, some ideas, etc. You can also share any relevant resources such as YouTube links, websites, Instagram / Pinterest pages, etc. using the link below"
        />
        {errors.yourTake && touched.yourTake && (
          <span
            style={{
              display: "block",
              color: "red",
              fontSize: "12px",
              textAlign: "start",
              marginTop: "15px",
            }}
          >
            {errors.yourTake && errors.yourTake}
          </span>
        )}
      </>
    );
  };

  renderResource = (
    values: any,
    handleChange: any,
    setFieldValue: any,
    handleBlur: any,
    errors: any,
    touched: any
  ) => {
    return (
      <Accordion
        elevation={0}
        style={{
          marginTop: "30px",
          marginBottom: "10px",
          position: "relative",
        }}
        expanded={values.resourceExtended}
        onChange={() => {
          if (!values.resourceExtended) {
            console.log("on the first input", this.resouceInputRef);
            setTimeout(() => {
              this.resouceInputRef.current?.focus();
            }, 500);
          }
          setFieldValue("resourceExtended", !values.resourceExtended);
        }}
      >
        <AccordionSummary
          // expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <AttachmentIcon style={{ marginLeft: "-10px" }} />
          <label
            style={{
              fontWeight: 500,
              // paddingBottom: '16px',
              textAlign: "left",
              // marginTop: '20px',
              color: "#15143B",
              // marginLeft: '-10px'
            }}
          >
            Add Resources
          </label>
        </AccordionSummary>

        <AccordionDetails style={{ flexDirection: "column" }}>
          <FieldArray
            name="resource"
            render={({
              remove,
              push,
            }: {
              remove: RemoveFunction;
              push: PushFunction;
            }) => (
              <>
                {values.resource.map((resourceData: any, index: number) => (
                  <div
                    style={{
                      marginTop: index == 0 ? undefined : "16px",
                      position: "relative",
                    }}
                    key={index}
                  >
                    <input
                      ref={index === 0 ? this.resouceInputRef : undefined}
                      name={`resource.${index}`}
                      value={resourceData}
                      placeholder={"Enter your resource link"}
                      onChange={(e) => {
                        e.target.value = e.target.value?.trimStart();
                        handleChange(e);
                      }}
                      onBlur={(e) => {
                        const url = e.target.value.trimStart();
                        const pattern =
                          /^(https?:\/\/)([a-zA-Z0-9.-]+\.)?(maps\.)?[a-zA-Z0-9-]{2,256}\.[a-z]{2,6}([/?#].*)?$/gi;

                        let result = pattern.test(
                          url.includes("http") || url.includes("https")
                            ? url
                            : "https://" + url
                        );

                        if (
                          !result &&
                          (values.resource[index] == "" ||
                            values.resource[index] == null)
                        ) {
                          result = false;
                        }
                        const arr = [...values.hasError];
                        arr[index] = result;
                        setFieldValue(`hasError`, arr);

                        handleBlur(e);
                      }}
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        height: "45px",
                        border: "0.1px solid #DBDAE3",
                        fontSize: "16px",
                        paddingLeft: "10px",
                      }}
                      autoComplete="off"
                      autoFocus
                    />

                    {index > 0 && (
                      <span
                        style={{
                          fontSize: "23px",
                          fontWeight: "bolder",
                          color: "grey",
                          cursor: "pointer",
                          top: "4px",
                          right: "10px",
                          position: "absolute",
                        }}
                        onClick={() => {
                          remove(index);
                          const arr = [...values.hasError].splice(index, 1);
                          setFieldValue(`hasError`, arr);
                        }} // remove a friend from the list
                      >
                        -
                      </span>
                    )}
                    <span
                      style={{
                        display: "block",
                        color: "red",
                        fontSize: "12px",
                        textAlign: "start",
                        marginTop: "15px",
                      }}
                    >
                      {!values.hasError[index] &&
                        "* Invalid resource. Please check your URL and try again."}
                      {/* // {errors[`resource${index+1}`] && errors.resource} */}
                      {/* {errors.resource && touched.resource && errors.resource} */}
                    </span>
                  </div>
                ))}

                {values.resource.length < 3 && (
                  <img
                    src={addIcon}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      const arr = [...values.hasError, true];
                      setFieldValue(`hasError`, arr);
                      push("");
                    }}
                    style={{
                      width: "15px",
                      height: "15px",
                      top: "85px",
                      right: "30px",
                      position: "absolute",
                      cursor: "pointer",
                    }}
                    alt="+"
                  />
                )}
              </>
            )}
          />
        </AccordionDetails>
      </Accordion>
    );
  };

  renderJoinAnnomously = (
    values: any,
    handleChange: any,
    setFieldValue: any,
    handleBlur: any,
    errors: any,
    touched: any
  ) => {
    return (
      <>
        {(values.room as any)?.attributes?.name !== "Moderated" && (
          <FormControl fullWidth variant="outlined">
            <Box style={{ display: "flex" }}>
              <Field
                name="anonymus"
                component={Checkbox}
                onClick={() => {
                  setFieldValue("anonymus", !values.anonymus);
                  setFieldValue("joinedAnonymously", !values.joinedAnonymously);
                }}
                checked={values.anonymus}
              />
              <label
                style={{
                  fontWeight: 500,
                  textAlign: "left",
                  color: "#15143B",
                  marginTop: "10px",
                }}
              >
                JOIN ANONYMOUSLY
              </label>
            </Box>
            {values.joinedAnonymously ? (
              <div>
                <Typography
                  style={{
                    fontWeight: 500,
                    textAlign: "left",
                    color: "#1C1B48",
                  }}
                >
                  Screen Name*
                </Typography>

                <input
                  name="anonymouslyName"
                  value={values.anonymouslyName}
                  onChange={(e) => {
                    e.target.value = e.target.value?.trimStart();
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    height: "45px",
                    border: "0.1px solid #DBDAE3",
                    fontSize: "16px",
                    padding: "10px",
                  }}
                />
              </div>
            ) : null}
          </FormControl>
        )}
      </>
    );
  };

  renderCreateRoomButton = (values: any) => {
    return (
      <Box
        className="btn-box"
        pl={3}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          type="submit"
          variant="contained"
          disabled={values.roomPrice > this.context.userCoinAmount}
          style={{
            background:
              values.roomPrice > this.context.userCoinAmount
                ? "#dedede"
                : "#FFCC00",
            color:
              values.roomPrice > this.context.userCoinAmount
                ? "#fff"
                : "#010138",
            width: "80%",
            padding: "15px 0px !important",
            textTransform: "none",
            marginTop: "30px",
          }}
        >
          <Typography style={{ fontWeight: 600, fontSize: "18px" }}>
            {this.props.prevData ? "Dublicate Room" : "Create Room"}
          </Typography>
        </Button>

        <CreateRoomSuccessDialog
          values={values}
          history={this.props.history}
          routeMap={this.props.routeMap}
          selectedValue={this.state.createRoomSuccessData}
          open={this.state.openCreateSuccessPopUp}
          onSuccessClose={this.handleCreateSuccessPopupClose}
          navigation={this.props.navigation}
          onClose={this.props.onclose}
          isMobile={this.state.isMobile}
          bulletPoints={this.state.bulletPoints}
          handleSyncToGoogleRoom={this.handleSyncToGoogleRoom}
        />
      </Box>
    );
  };

  renderRefillWallet = (values: any) => {
    return (
      <>
        {values.roomPrice > this.context.userCoinAmount ? (
          <div
            className="refill-wallet"
            onClick={() => {
              this.handleOpen();
            }}
          >
            Refill Your Wallet
          </div>
        ) : null}
      </>
    );
  };

  render() {
    const attributes = this.props?.prevData?.attributes;
    const { isMobile } = this.state;
    const popupStyles = this.getPopupStyles(isMobile);
    const gridStyles = this.getGridStyles(isMobile);
    const mainBoxStyles = this.getMainBoxStyles(isMobile);
    const buttonStyles = this.getButtonStyles(isMobile);
    const headingStyles = this.getHeadingStyles(isMobile);

    return (
      <Grid>
        <AlertBlock
          data={{
            show: this.state.isMessagePop,
            close: this.handleCloseAlert,
            msg: typeof this.msg === "string" ? this.msg : (this.msg as any)?.message,
            type: this.type,
            vertical: "top",
            horizontal: "center",
          }}
        />
        {/* @ts-ignore */}
        <RefillWallet ref={this.myref} />

        {this.renderMobileOverlay()}

        <div style={popupStyles} className="create_room_popup">
          <Grid style={gridStyles}>
            <Box style={mainBoxStyles}>
              {this.showDynamicHeading()}
              <Button onClick={this.handleClose} style={buttonStyles}>
                <CloseIcon />
              </Button>
            </Box>

            {this.renderDivider}

            <Box style={{ padding: "0 20px 20px 20px" }}>
              <Formik
                //@ts-ignore
                innerRef={this.form}
                initialValues={{ ...this.getInitialValues(attributes) }}
                validationSchema={this.createRoomSchema}
                onSubmit={(values) => this.handleOnSubmit(values)}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    {/* Category dropdown */}
                    <FormControl fullWidth variant="outlined">
                      <Typography style={headingStyles}>
                        Select Category
                      </Typography>

                      {this.renderCategory(
                        values,
                        handleChange,
                        setFieldValue,
                        handleBlur,
                        errors,
                        touched
                      )}
                    </FormControl>

                    {/* SubCategory dropdown */}
                    <FormControl fullWidth variant="outlined">
                      <Typography
                        style={{
                          fontWeight: 500,
                          paddingBottom: "16px",
                          textAlign: "left",
                          marginTop: "30px",
                          color: "#1C1B48",
                        }}
                      >
                        Select Subcategory
                      </Typography>

                      {this.renderSubcategory(
                        values,
                        handleChange,
                        setFieldValue,
                        handleBlur,
                        errors,
                        touched
                      )}
                    </FormControl>

                    {/* Topics dropdown */}
                    <FormControl fullWidth variant="outlined">
                      <Typography
                        style={{
                          fontWeight: 500,
                          paddingBottom: "16px",
                          textAlign: "left",
                          marginTop: "30px",
                          color: "#1C1B48",
                        }}
                      >
                        Select Topic
                      </Typography>

                      {this.renderTopics(
                        values,
                        handleChange,
                        setFieldValue,
                        handleBlur,
                        errors,
                        touched
                      )}
                    </FormControl>

                    {/* Room type dropdown */}
                    <FormControl fullWidth variant="outlined">
                      <Typography
                        style={{
                          fontWeight: 500,
                          paddingBottom: "16px",
                          textAlign: "left",
                          marginTop: "30px",
                          color: "#1C1B48",
                        }}
                      >
                        Select Room Type
                      </Typography>

                      {this.renderRoomsType(
                        values,
                        handleChange,
                        setFieldValue,
                        handleBlur,
                        errors,
                        touched
                      )}
                    </FormControl>

                    {/* Date and time */}
                    <FormControl style={{ width: "100%" }}>
                      <Typography
                        style={{
                          fontWeight: 500,
                          paddingBottom: "16px",
                          textAlign: "left",
                          marginTop: "30px",
                          color: "#1C1B48",
                        }}
                      >
                        Date and Time
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "14px",
                          marginBottom: "10px",
                          opacity: "0.4",
                        }}
                      >
                        Select Date and Time
                      </Typography>

                      {this.renderDataAndTime(
                        values,
                        handleChange,
                        setFieldValue,
                        handleBlur,
                        errors,
                        touched
                      )}
                    </FormControl>

                    {/*Your take */}
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className="your-take"
                    >
                      <Typography
                        style={{
                          fontWeight: 500,
                          textAlign: "left",
                          marginTop: "20px",
                          color: "#1C1B48",
                        }}
                      >
                        Your Take
                      </Typography>

                      <ThemeProvider theme={theme}>
                        {this.renderYourTake(
                          values,
                          handleChange,
                          setFieldValue,
                          handleBlur,
                          errors,
                          touched
                        )}
                      </ThemeProvider>
                    </FormControl>

                    {/*Add resource */}
                    <FormControl fullWidth variant="outlined">
                      {this.renderResource(
                        values,
                        handleChange,
                        setFieldValue,
                        handleBlur,
                        errors,
                        touched
                      )}
                    </FormControl>

                    {/* Global room */}
                    <ThemeProvider theme={theme}>
                      <FormControl fullWidth variant="outlined">
                        <Box style={{ display: "flex" }}>
                          <Field
                            component={Switch}
                            onClick={() =>
                              setFieldValue("global", !values.global)
                            }
                            checked={values.global}
                            color="primary"
                          />
                          <label
                            style={{
                              fontWeight: 500,
                              paddingBottom: "16px",
                              textAlign: "left",
                              marginTop: "8px",
                              color: "#15143B",
                            }}
                          >
                            Global Room
                          </label>
                        </Box>
                      </FormControl>
                    </ThemeProvider>

                    {/* Join Anonymously */}
                    {this.renderJoinAnnomously(
                      values,
                      handleChange,
                      setFieldValue,
                      handleBlur,
                      errors,
                      touched
                    )}

                    {/* Create room button */}
                    {this.renderCreateRoomButton(values)}

                    {/* Refill wallet */}
                    {this.renderRefillWallet(values)}
                  </form>
                )}
              </Formik>
            </Box>
          </Grid>
        </div>
      </Grid>
    );
  }
}

export default CreateRoom;

// Customizable Area End
