import React, { useRef } from 'react';
// Customizable Area Start
import RoomsController from '../MyRooms/RoomsController';
import {
  calendar,
  goldenTick,
  yellowheart,
  as,
  blueTick,
  attach,
  images,
  addIcon,
  createRoomSuccess,
  GoogleCalendar,
  noRoomImg,
  cancelIcon,
} from '../assets';
import { Link, withRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  Grid,
  Container,
  Button,
  Typography,
  Modal,
  TextField,
  IconButton,
  Popover,
  Divider,
  ButtonBase,
  Dialog,
  DialogContent,
  Avatar,
  List,
  ListItem,
  Hidden,
  Drawer,
  Tooltip,
  withWidth,
  InputAdornment,
  Checkbox,
  debounce,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Categories from '../CategoriesAndRoomTypes/Categories.web';
import RoomTypes from '../CategoriesAndRoomTypes/RoomTypes.web';
import RoomTabs from '../RoomTabs/RoomTabs.web';
import CloseIcon from '@material-ui/icons/Close';
import ShareIcon from '@material-ui/icons/Share';
import GroupIcon from '@material-ui/icons/Group';
import LinkIcon from '@material-ui/icons/Link';
import SearchIcon from '@material-ui/icons/Search';
import AlertBlock from '../../../alert/src/Alert.web';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import ClipLoader from 'react-spinners/ClipLoader';
import RefillWallet from '../../../Payments/src/refillwallet/RefillWallet.web';
import { Box, Chip } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment';
import CreateRoom from '../CreateRoom/CreateRoom.web';
import RulesOfEngagement from '../RoomDetails/RulesOfEngagement';
import LateJoinScreen from '../RoomDetails/LateJoinScreen';
import FeedbackStatus from './Feedback';
import InfiniteScroll from 'react-infinite-scroll-component';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IOSSwitch from './IosSwitch.web';
import { CustomPaginationUse } from './CustomPagination.web';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import QuotesWeb from './Quotes.web';
import Draggable from 'react-draggable';
import Slide from '@material-ui/core/Slide';
import CustomAvatarGroup from './CustomAvatarGroup.web';
import { format, parseISO } from 'date-fns';
import ShareLink from '../RoomDetails/RoomDetailsComponents/ShareLink';

interface PCProps {
  full_name: string;
  is_anonymously: boolean;
  profile_image: string;
  hearts: string;
  gender: string;
  age_range: number[];
  your_take: string;
  anonymously_name: string;
  resource: any;
}

type RemoveFunction = (index: number) => void;
type PushFunction = (value: any) => void;

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFCB00",
    },
    secondary: {
      main: "#010039",
    },
    text: {
      primary: "#27272A",
    },
  },
});

const getDateTime = (dateTime: string) => {
  return `${moment(dateTime).format('Do MMMM YYYY')} at ${moment(
    dateTime
  ).format('h:mm A')}`;
};

export const checkDifference = (start_time: Date, current_time: Date) => {
  let diff = (start_time.getTime() - current_time.getTime()) / 1000;
  diff /= 60;
  return Math.round(diff);
};

const ParticipantCard: React.FC<PCProps> = ({
  age_range,
  full_name,
  gender,
  hearts,
  is_anonymously,
  profile_image,
  your_take,
  anonymously_name,
  resource,
}) => {
  let age = '';
  if (age_range) {
    age = age_range[0] + (age_range[1] ? '-' + age_range[1] : '');
  }

  return (
    <div className="part-resources mb15">
      <div className="d-flex">
        <div>
          <img
            src={is_anonymously ? images : profile_image || images}
            alt="user_avatar"
            className="radius100 w24 cover mr10"
            onError={(e) => {
              e.currentTarget.src = images;
            }}
          />
        </div>
        <Box flex={1}>
          <div className="d-flex">
            <Box width="50%">
              <h3 className="fs14 fw600 colorBlue mb0 mt0 trim-text">
                {is_anonymously ? anonymously_name : full_name}
              </h3>
            </Box>
            <span className="dFlexAlignCenter fs14 colorGrey fw500">
              <img src={yellowheart} alt="" className="w18 ml10 mr10" />
              {hearts}
            </span>
            <span className="fs13 colorGrey fw500 ml15">
              {gender === 'Male' ? 'M' : gender === 'Female' ? 'F' : 'NB'}({age}
              )
            </span>
          </div>
          <p className="fs13 colorLightGrey fw500 mb5 mt10 text-justify">
            {your_take}
          </p>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '1rem',
            }}
          >
            {resource?.map((item: any) => (
              <a
                key={item.url ? item.url : item}
                href={item.url ? `https://${item.url}` : `https://${item}`}
                target="_blank"
              >
                {item.url ? item.url : item}
              </a>
            ))}
          </div>
        </Box>
      </div>
    </div>
  );
};

let deletedRoomCount = 0;
class UpcomingRooms extends RoomsController {
  rulesRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    // @ts-ignore
    this.myRef = React.createRef();
    this.rulesRef = React.createRef();
  }

  handleRoute = () => {
    const { isRouteMy, isRouteRecommended, isRouteUpcoming } = this.state;
    const params = new URLSearchParams(this.props.history.location.search);
    const route = params.get('type');

    if (!isRouteMy && route === 'my') {
      this.setState(
        { isRouteUpcoming: false, isRouteMy: true, isRouteRecommended: false },
        () => {
          this.handleCallRoomAPI(this.state.filters);
        }
      );
    } else if (!isRouteUpcoming && route === 'upcoming') {
      this.setState(
        { isRouteMy: false, isRouteUpcoming: true, isRouteRecommended: false },
        () => {
          this.handleCallRoomAPI(this.state.filters);
        }
      );
    } else if (!isRouteRecommended && route === 'recommended') {
      this.setState(
        { isRouteMy: false, isRouteUpcoming: false, isRouteRecommended: true },
        () => {
          this.handleCallRoomAPI(this.state.filters);
        }
      );
    }
  };

  async componentDidUpdate() {
    this.handleRoute();
  }

  handleClose = () => {
    this.props.onclose(false);
  };

  handleCloseAlert = () => {
    this.setState({ isMessagePop: false });
  };

  createRoomSchema = Yup.object().shape({
    yourTake: Yup.string()
      .required('Please enter Your Take')
      .min(50, 'Your Take must be at least 50 characters'),
    addResources: Yup.boolean(),
    resource: Yup.array().when('addResources', {
      is: true,
      then: Yup.array().of(
        Yup.string()
          .url('Invalid resource. Please check your URL and try again')
          .required('Invalid resource. Please check your URL and try again')
      ),
      otherwise: Yup.array().notRequired(),
    }),
  });

  //  Refill wallet open modal
  handleRefillOpen = () => {
    // @ts-ignore
    this.myRef.current.handleOpen();
  };

  handleRulesOpen = () => {
    if (this.rulesRef && this.rulesRef.current)
      this.rulesRef.current.handleOpen();
  };

  navigateToRoom = (roomDetails: any) => {
    this.props.history.push(`/adjust-settings/${roomDetails.id}`, {
      state: {
        is_host: roomDetails?.attributes?.is_host,
        roomSID: roomDetails?.attributes?.sids?.room_sid,
        conversationSID: roomDetails?.attributes?.sids?.conversation_sid,
      },
    });
  };

  enterRoom = () => {
    const { roomDetails } = this.state;
    if (!roomDetails) return;

    this.navigateToRoom(roomDetails);
  };

  // utility functions to reduce cognitive complexity.
  getPathName = () => {
    return this.props.history.location.search.split('=')[1];
  }

  categoriesFilter = () => {
    const { getCategoriesData } = this.state;

    return (
      <Categories
        {...this.props}
        navigation={this.props.navigation}
        handleSearch={this.handleSearch}
        selectedCat={this.state.selectedCat}
        handleCatId={this.updateCatID}
        handleFilter={this.hasFilter}
        room_times={this.state.room_times}
        handleRoomTime={this.handleRoomTime}
        getCategories={this.getCategories}
        getCategoriesData={getCategoriesData}
        isCatLoading={this.state.isCatLoading}
        loadingTopics={this.state.loadingTopics}
        isFilterLoading={this.state.isFilterLoading}
        categories={this.state.onlyCategories}
        fetchCategories={this.onlyCategories}
      />
    )
  }

  roomTypeFilter = () => {
    return (
      <RoomTypes
        {...this.props}
        navigation={this.props.navigation}
        handleRoomType={this.handleRoomType}
        roomType={this.state.room_type}
        toggleAvailable={this.toggleAvailability}
      />
    )
  }

  noRoomBox = () => {
    if (this.state.noRoomList.length > 0) {
      return (
        <>
          {this.state.noRoomList.map((item: any) => {
            return (
              <Grid
                container
                style={{ marginBottom: '10px' }}
                className="CreateRoomBox"
              >
                <Grid lg={8} md={8} xs={12}>
                  <span className="label">{item.topic_name}</span>
                  <span>{item.topic_description}</span>
                </Grid>
                <Grid lg={3} md={3} xs={12} className="createRoomButtonBox">
                  <Button
                    style={{
                      width: '100%',
                      marginTop: '20px',
                      minWidth: 'max-content',
                    }}
                    className="contained-btn margin-spacing-right"
                    variant="contained"
                    onClick={() => {
                      this.context.changeCreateRoomModal(true, {
                        selectedTopic: item.topic_id,
                        selectedCategory: item.cat_id,
                        selectedSubCategory: item.sub_cat_id,
                      });
                    }}
                  >
                    Create Room
                  </Button>
                </Grid>
              </Grid>
            );
          })}
          <div className="NoRoomClassBox">
            <img src={noRoomImg}></img>
            <span>No rooms available</span>
            <span className="secondText">Create a room and start having a conversation</span>
          </div>
        </>
      );
    } else if (this.state.upcomingRoomsData.length === 0) {
      return (
        <p
          className="mt0 fs15 fw500 colorGrey"
          style={{ textAlign: this.state.isMobile ? 'center' : 'inherit' }}
        >
          No Rooms Available
        </p>
      );
    }
  };

  updateEnterRoomsID = (elem: any) => {
    const enter_rooms_ids: number[] = JSON.parse(
      localStorage.getItem('enter_rooms_ids') as string
    );
    const ROOM_ID = parseInt(elem.id, 10);

    if (enter_rooms_ids && enter_rooms_ids.includes(ROOM_ID)) {
      const newRoomsID = enter_rooms_ids.filter((each) => each !== ROOM_ID);
      localStorage.setItem('enter_rooms_ids', JSON.stringify(newRoomsID));
    }
  }

  getRoomSkeletonDivStyles = () => {
    const { isMobile } = this.state;

    const styles: React.CSSProperties = {
      paddingLeft: isMobile ? '1.2rem' : 0,
      paddingRight: isMobile ? '1.2rem' : 0,
    }

    return styles;
  }

  getRoomSkeletonGridStyles = () => {
    const { isMobile } = this.state;

    const styles: React.CSSProperties = {
      borderTop: isMobile ? '1px solid #ccc' : 'auto',
      display: isMobile ? 'flex' : 'block',
      flexWrap: 'wrap',
    }

    return styles;
  }

  renderDynamicHost = () => {
    return (
      <>
        {!this.state.isMobile && (
          <p className="fs15 colorGrey mb10">Host</p>
        )}

        {this.state.isMobile && (
          <Typography
            className="m10"
            style={{
              letterSpacing: 1,
              color: '#979797',
              fontWeight: 500,
              marginTop: '-.4rem',
            }}
          >
            HOST
          </Typography>
        )}
      </>
    )
  }

  getProfilePhoto = (elem: any) => {
    return (
      elem?.attributes?.host?.data?.attributes
        ?.profile_image === ''
        ? images
        : elem?.attributes?.host?.data?.attributes
          ?.profile_image
    )
  }

  renderVerifiedTick = (elem: any) => {
    return (
      <>
        {(elem?.attributes?.host?.data?.attributes
          ?.is_moderator ||
          elem?.attributes?.host?.data?.attributes
            ?.is_superhost) && (
            <img
              src={
                elem?.attributes?.host?.data?.attributes
                  ?.is_moderator
                  ? goldenTick
                  : blueTick
              }
              alt="verified_tick"
              className="upcoming-room_check-mark"
            />
          )}
      </>
    )
  }

  renderFullName = (elem: any) => {
    return (
      <h4 className="fs15 fw600 colorBlue mb5 mt0 text-capitalize trim-text">
        {elem?.attributes?.host?.data?.id ==
          localStorage.getItem('userId')
          ? 'You'
          : elem?.attributes?.is_anonymously
            ? elem?.attributes?.anonymously_name
            : elem?.attributes?.host?.data?.attributes?.full_name}
      </h4>
    )
  }

  renderGender = (elem: any) => {
    return (
      <>
        {elem?.attributes?.host?.data?.attributes?.gender ===
          'Male'
          ? 'M'
          : elem?.attributes?.host?.data?.attributes?.gender ===
            'Female'
            ? 'F'
            : 'NB'}
      </>
    )
  }

  renderWebParticipants = (elem: any) => {
    return (
      <Hidden smDown>
        <>
          <p className="fs15 colorGrey fw500 mb10 mt0">
            REGISTERED :{' '}
            {elem?.attributes?.participants?.data?.length}
          </p>
          {elem?.attributes?.participants?.data?.length > 0 &&
            elem?.attributes?.participants?.data?.map(
              (participants: any) => {
                if (
                  !participants?.attributes?.participant?.data
                    ?.attributes
                )
                  return;
                const { full_name, gender } =
                  participants?.attributes?.participant?.data
                    ?.attributes;
                let name;
                if (
                  participants?.attributes?.participant?.data
                    ?.id === localStorage.getItem('userId')
                ) {
                  name = 'You';
                } else if (
                  participants?.attributes?.is_anonymously
                ) {
                  name = participants?.attributes?.anonymously_name;
                } else {
                  name = full_name;
                }

                const user_gender =
                  gender === 'Male'
                    ? 'M'
                    : gender === 'Female'
                      ? 'F'
                      : 'NB';
                const profile_image =
                  participants?.attributes?.participant?.data
                    ?.attributes?.profile_image;
                const is_anonymously =
                  participants?.attributes?.is_anonymously;

                return (
                  <div
                    className="d-flex mb10"
                    key={participants.id}
                  >
                    <div className="user-card__image-container">
                      <img
                        src={
                          !profile_image || is_anonymously
                            ? images
                            : profile_image
                        }
                        alt="participant_image"
                        className="radius100 w48 cover"
                        onError={(e) => {
                          e.currentTarget.src = images;
                        }}
                      />
                      {(participants?.attributes?.participant?.data
                        ?.attributes?.is_moderator ||
                        participants?.attributes?.participant?.data
                          ?.attributes?.is_superhost) && (
                          <img
                            src={
                              participants?.attributes?.participant
                                ?.data?.attributes?.is_moderator
                                ? goldenTick
                                : blueTick
                            }
                            alt="verified_tick"
                            className="upcoming-room_check-mark"
                          />
                        )}
                    </div>

                    <div className="ml15" style={{ width: '75%' }}>
                      <h4
                        className="fs15 fw600 colorBlue mb5 mt0 text-capitalize trim-text"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          if (
                            !participants?.attributes
                              ?.is_anonymously
                          ) {
                            const currentID =
                              participants?.attributes?.participant
                                ?.data?.id;
                            const userId =
                              localStorage.getItem('userId');
                            if (currentID == userId) {
                              this.props.history.push(
                                '/UserPersonalProfile'
                              );
                              return;
                            }
                            this.props.history.push(
                              '/UserProfile',
                              {
                                userId: currentID,
                              }
                            );
                          }
                        }}
                      >
                        {name}
                      </h4>
                      <p className="dFlexAlignCenter fs14 fw500 colorGrey mt0">
                        <img
                          src={yellowheart}
                          alt=""
                          className="w20 mr10"
                        />
                        <span className="fs14 mr5">
                          {' '}
                          {participants?.attributes?.participant
                            ?.data?.attributes?.hearts || 0}
                        </span>
                        {user_gender}(
                        {
                          participants?.attributes?.participant
                            ?.data?.attributes?.age_range[0]
                        }
                        {participants?.attributes?.participant?.data
                          ?.attributes?.age_range[1] && '-'}
                        {
                          participants?.attributes?.participant
                            ?.data?.attributes?.age_range[1]
                        }
                        )
                      </p>
                    </div>
                  </div>
                );
              }
            )}
        </>
      </Hidden>
    )
  }

  renderMobileParticipants = (elem: any) => {
    return (
      <>
        {elem?.attributes?.participants?.data.length > 0 && (
          <Hidden smUp>
            <div
              className="m15"
              style={{
                marginBottom: this.state.isMobile ? '5px' : 'auto',
                marginTop: this.state.isMobile ? '10px' : 'auto',
                flex: 1,
              }}
            >
              <Typography
                className="m10"
                style={{
                  letterSpacing: 1,
                  color: '#979797',
                  fontWeight: 500,
                  textTransform: 'uppercase',
                  textAlign: 'right',
                }}
              >
                Registered
              </Typography>

              <CustomAvatarGroup
                avatars={elem?.attributes?.participants?.data.map(
                  (participant: any) => ({
                    id: participant.attributes.participant.data?.id,
                    alt: participant.attributes.participant.data
                      ?.attributes.full_name,
                    src: participant.attributes.participant.data
                      ?.attributes.profile_image,
                  })
                )}
              />
            </div>
          </Hidden>
        )}
      </>
    )
  }

  renderFeedback = (elem: any) => {
    const allActiveParticipants = [elem?.attributes?.host?.data, ...elem?.attributes?.participants?.data];

    return (
      <>
        {elem?.attributes?.is_delete && (
          <strong className="fs20 fw600 colorBlue pointer cancelledBtn bTop">
            {elem?.attributes?.feedback_received}
          </strong>
        )}

        {!elem?.attributes?.is_delete && (
          <FeedbackStatus
            feedback={elem?.attributes?.feedback_received}
            room_id={elem.id}
            reloadRoom={() => {
              this.handleCallRoomAPI(this.state.filters);
            }}
            start_time={elem.attributes.start_time}
            isModerated={elem.attributes.room_type_name === 'Moderated'}
            isHost={elem.attributes.is_host}
            isMobile={this.state.isMobile as boolean}
            allActiveParticipants={allActiveParticipants}
          />
        )}
      </>
    )
  }

  myRoomsSkeleton = (elem: any) => {
    let UpcomingNewDateFormat = new Date(elem?.attributes?.start_time)
      .toLocaleString(navigator.language, {
        hour: '2-digit',
        minute: '2-digit',
        month: '2-digit',
        year: 'numeric',
        day: '2-digit',
        hour12: true,
      })
      .replace(/,/g, ' ');

    this.updateEnterRoomsID(elem);

    return (
      <div
        key={elem.id}
        style={this.getRoomSkeletonDivStyles()}
      >
        <section className="room-details mb20 shadow bgDarkGrey">
          <Grid container>
            <Grid item xs={12} md={8} className="borderRight">
              <div className="p15 ">
                <div className="daf">
                  <h3 className="fs22 colorBlue fw500 mt0 mb0">
                    {elem?.attributes?.topic_name}
                  </h3>
                </div>
                {/* <div>
                  <img src={plle} alt="dsaf" className="w22" />
                  <img
                    src={share}
                    alt="dsaf"
                    className="w22 ml5"
                  />
                </div> */}
                <div className="mb15 ">
                  <p className="fs14 fw500 colorGrey mt0 mb0">
                    {elem?.attributes?.sub_category_name}
                  </p>
                  <span className="fs12 fw500 colorGrey">
                    {elem?.attributes?.room_type_name}
                  </span>
                </div>
                <p className="fs14 colorGrey fw500 mh75">
                  {elem?.attributes?.description}
                </p>
                <div className="seats-time">
                  <div>
                    <p className="fs14 colorBlue fw500 mt0 mb0">
                      {UpcomingNewDateFormat}
                      {/* Starts in 5 Minutes */}
                    </p>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              style={this.getRoomSkeletonGridStyles()}
            >
              <div className=" m15">
                {this.renderDynamicHost()}

                <div className="d-flex">
                  <div className="user-card__image-container">
                    <img
                      src={this.getProfilePhoto(elem)}
                      alt="user_profile_img"
                      className="radius100 w48 cover"
                      onError={(e) => {
                        e.currentTarget.src = images;
                      }}
                    />
                    {this.renderVerifiedTick(elem)}
                  </div>

                  <div className="ml15" style={{ width: '75%' }}>
                    {this.renderFullName(elem)}
                    <p className="dFlexAlignCenter mt0 fs15 fw500 colorGrey">
                      <img src={yellowheart} alt="" className="w20 mr10" />
                      <span className="fs14 mr5">
                        {' '}
                        {elem?.attributes?.host?.data?.attributes?.hearts ||
                          0}
                      </span>
                      {this.renderGender(elem)}
                      (
                      {elem?.attributes?.host?.data?.attributes?.age_range[0]}
                      {elem?.attributes?.host?.data?.attributes
                        ?.age_range[1] && '-'}
                      {elem?.attributes?.host?.data?.attributes?.age_range[1]}
                      )
                    </p>
                  </div>
                </div>
                {this.renderWebParticipants(elem)}
              </div>

              {this.renderMobileParticipants(elem)}
            </Grid>
          </Grid>

          {this.renderFeedback(elem)}
        </section>
      </div>
    );
  };

  handleDeleteCount = (elem: any) => {
    if (elem.attributes.is_delete) {
      deletedRoomCount++;
      return null;
    }
  }

  isExpireRoom = (elem: any) => {
    const pathName = this.getPathName();

    let endDate = new Date(elem?.attributes?.end_time);
    let presentDate = new Date();
    let expireRoom = endDate < presentDate;

    return (pathName === 'my' && expireRoom);
  }

  getGender = (elem: any) => {
    const gender = elem?.attributes?.host?.data?.attributes?.gender;
    let result;

    if (gender === 'Male') {
      result = 'M';
    } else if (gender === 'Female') {
      result = 'F';
    } else {
      result = 'NB';
    }

    return result;
  }

  getUserName = (user: any) => {
    const host = user?.host?.data;
    const currentUserId = localStorage.getItem('userId');
    let userName;

    if (host?.id == currentUserId) {
      userName = 'You';
    } else if (user?.is_anonymously) {
      userName = user.anonymously_name;
    } else {
      userName = host?.attributes?.full_name;
    }

    return userName;
  }

  getMainDivStyles = () => {
    const { isMobile } = this.state;

    const styles: React.CSSProperties = {
      paddingLeft: isMobile ? '1.2rem' : 0,
      paddingRight: isMobile ? '1.2rem' : 0,
    }

    return styles;
  }

  getSectionClasName = (elem: any) => {
    return this.state.selectedRoomId === elem?.id ? 'borderYellow' : '';
  }

  getGridClassName = () => {
    return !this.state.isMobile ? 'borderRight' : ''
  }

  getHostGridStyles = () => {
    const { isMobile } = this.state;

    const styles: React.CSSProperties = {
      borderTop: isMobile ? '1px solid #ccc' : 'auto',
      display: isMobile ? 'flex' : 'block',
      flexWrap: 'wrap',
    }

    return styles;
  }

  getHostDivStyles = () => {
    const { isMobile } = this.state;

    const styles: React.CSSProperties = {
      marginBottom: isMobile ? '5px' : 'auto',
      marginTop: isMobile ? '10px' : 'auto',
    }

    return styles;
  }

  renderUserImg = (elem: any) => {
    return (
      <div className="user-card__image-container">
        <img
          src={
            elem?.attributes?.host?.data?.attributes
              ?.profile_image === '' ||
              (elem?.attributes?.is_anonymously &&
                elem?.attributes?.host?.data?.id !=
                localStorage.getItem('userId'))
              ? images
              : elem?.attributes?.host?.data?.attributes
                ?.profile_image
          }
          alt="profile_image"
          className="radius100 w48 cover"
          onError={(e) => {
            e.currentTarget.src = images;
          }}
        />
        {(elem?.attributes?.host?.data?.attributes
          ?.is_moderator ||
          elem?.attributes?.host?.data?.attributes
            ?.is_superhost) && (
            <img
              src={
                elem?.attributes?.host?.data?.attributes
                  ?.is_moderator
                  ? goldenTick
                  : blueTick
              }
              alt="verified_tick"
              className="upcoming-room_check-mark"
            />
          )}
      </div>
    )
  }

  renderOtherDetails = (elem: any) => {
    const userGender = this.getGender(elem);
    const user = elem?.attributes;
    const userName = this.getUserName(user);

    return (
      <div className="ml15" style={{ overflow: 'hidden' }}>
        <h4
          className="fs15 fw600 colorBlue mb5 mt0 text-capitalize trim-text"
          style={{ cursor: user?.is_anonymously ? 'not-allowed' : 'pointer' }}
          onClick={() => {
            if (!elem?.attributes?.is_anonymously) {
              const currentID = elem?.attributes?.host?.data?.id;
              const userId = localStorage.getItem('userId');
              if (currentID == userId) {
                this.props.history.push('/UserPersonalProfile');
                return;
              }
              this.props.history.push('/UserProfile', {
                userId: currentID,
              });
            }
          }}
        >
          {userName}
        </h4>
        <p
          className="dFlexAlignCenter mt0 fs15 fw500 colorGrey"
          style={{
            fontWeight: this.state.isMobile ? 400 : 'inherit',
          }}
        >
          <img
            src={yellowheart}
            alt=""
            className={`w20 ${this.state.isMobile ? 'mr5' : 'mr10'
              }`}
          />
          <span
            className={`fs14 ${this.state.isMobile ? 'mr10' : 'mr5'
              }`}
          >
            {' '}
            {elem?.attributes?.host?.data?.attributes?.hearts ||
              0}
          </span>
          {userGender}(
          {elem?.attributes?.host?.data?.attributes?.age_range[0]}
          {elem?.attributes?.host?.data?.attributes
            ?.age_range[1] && '-'}
          {elem?.attributes?.host?.data?.attributes?.age_range[1]}
          )
        </p>
      </div>
    )
  }

  renderSkeletonWebParticipants = (elem: any) => {
    return (
      <Hidden smDown>
        <>
          {!this.state.isMobile && (
            <p className="fs15 colorGrey fw500 mb10 mt0">
              REGISTERED :{' '}
              {elem?.attributes?.participants?.data?.length}
            </p>
          )}
        </>

        {elem?.attributes?.participants?.data?.length > 0 &&
          elem?.attributes?.participants?.data?.map(
            (participants: any) => {
              if (
                !participants?.attributes?.participant?.data
                  ?.attributes
              )
                return;
              const { full_name } =
                participants?.attributes?.participant?.data
                  ?.attributes;
              let name;
              if (
                participants?.attributes?.participant?.data?.id ===
                localStorage.getItem('userId')
              ) {
                name = 'You';
              } else if (participants?.attributes?.is_anonymously) {
                name = participants?.attributes?.anonymously_name;
              } else {
                name = full_name;
              }

              const gender =
                participants?.attributes?.participant?.data
                  ?.attributes?.gender;

              return (
                <div className="d-flex mb10" key={participants.id}>
                  <div className="user-card__image-container">
                    <img
                      src={
                        participants?.attributes?.participant?.data
                          ?.attributes?.profile_image === ''
                          ? images
                          : participants?.attributes
                            ?.is_anonymously && name !== 'You'
                            ? images
                            : participants?.attributes?.participant
                              ?.data?.attributes?.profile_image
                      }
                      alt=""
                      className="radius100 w48 cover"
                      onError={(e) => {
                        e.currentTarget.src = images;
                      }}
                    />
                    {(participants?.attributes?.participant?.data
                      ?.attributes?.is_moderator ||
                      participants?.attributes?.participant?.data
                        ?.attributes?.is_superhost) && (
                        <img
                          src={
                            participants?.attributes?.participant
                              ?.data?.attributes?.is_moderator
                              ? goldenTick
                              : blueTick
                          }
                          alt="verified_tick"
                          className="upcoming-room_check-mark"
                        />
                      )}
                  </div>

                  <div
                    className="ml15"
                    style={{ overflow: 'hidden' }}
                  >
                    <h4
                      className="fs15 fw600 colorBlue mb5 mt0 text-capitalize trim-text"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (
                          !participants?.attributes?.is_anonymously
                        ) {
                          const currentID =
                            participants?.attributes?.participant
                              ?.data?.id;
                          const userId =
                            localStorage.getItem('userId');
                          if (currentID == userId) {
                            this.props.history.push(
                              '/UserPersonalProfile'
                            );
                            return;
                          }
                          this.props.history.push('/UserProfile', {
                            userId: currentID,
                          });
                        }
                      }}
                    >
                      {name}
                    </h4>
                    <p className="dFlexAlignCenter mt0 fs15 fw500 colorGrey">
                      <img
                        src={yellowheart}
                        alt=""
                        className="w20 mr10"
                      />
                      <span className="fs14 mr5">
                        {participants?.attributes?.participant?.data
                          ?.attributes?.hearts || 0}
                      </span>
                      {gender === 'Male'
                        ? 'M'
                        : gender === 'Female'
                          ? 'F'
                          : 'NB'}
                      (
                      {
                        participants?.attributes?.participant?.data
                          ?.attributes?.age_range[0]
                      }
                      {participants?.attributes?.participant?.data
                        ?.attributes?.age_range[1] && '-'}
                      {
                        participants?.attributes?.participant?.data
                          ?.attributes?.age_range[1]
                      }
                      )
                    </p>
                  </div>
                </div>
              );
            }
          )}
      </Hidden>
    )
  }

  renderSkeletonMobileParticipants = (elem: any) => {
    return (
      <>
        {elem?.attributes?.participants?.data.length > 0 && (
          <Hidden smUp>
            <div
              className="m15"
              style={{
                marginBottom: this.state.isMobile ? '5px' : 'auto',
                marginTop: this.state.isMobile ? '10px' : 'auto',
                flex: 1,
              }}
            >
              <Typography
                className="m10"
                style={{
                  letterSpacing: 1,
                  color: '#979797',
                  fontWeight: 500,
                  textTransform: 'uppercase',
                  textAlign: 'right',
                }}
              >
                Registered
              </Typography>

              <CustomAvatarGroup
                avatars={elem?.attributes?.participants?.data.map(
                  (participant: any) => ({
                    id: participant.attributes.participant.data?.id,
                    alt: participant.attributes.participant.data
                      ?.attributes.full_name,
                    src: participant.attributes.participant.data
                      ?.attributes.profile_image,
                  })
                )}
              />
            </div>
          </Hidden>
        )}
      </>
    )
  }

  renderRegisterBtn = (elem: any) => {
    return (
      <>
        {!elem?.attributes?.is_registered &&
          !elem?.attributes?.is_host &&
          elem?.attributes?.remaining_seats > 0 && (
            <strong
              className="fs20 fw600 colorBlue pointer"
              onClick={() => {
                this.setState((prev) => ({
                  congratulationModal: {
                    ...prev.congratulationModal,
                    dateTime: elem?.attributes?.start_time,
                    room_name: elem?.attributes?.sub_category_name,
                  },
                }));
                this.RegisterModalOpen(elem);
              }}
            >
              REGISTER
            </strong>
          )
        }
      </>
    )
  }

  renderFullBtn = (elem: any) => {
    const pathName = this.getPathName();

    return (
      <>
        {pathName !== 'my' &&
          !elem?.attributes?.is_registered &&
          elem?.attributes?.remaining_seats < 1 &&
          !elem?.attributes?.is_host && (
            <strong className="fs20 fw600 colorGrey">FULL</strong>
          )
        }
      </>
    )
  }

  enterComponent = (elem: any) => {
    return (
      <strong
        className="fs20 fw600 colorBlue pointer"
        onClick={() => {
          const joined_users = elem.attributes.joined_users || '';
          const user_id = localStorage.getItem('userId') as string;

          if (joined_users && joined_users.includes(user_id)) {
            this.navigateToRoom(elem);
            return;
          }

          const diff = checkDifference(
            new Date(elem?.attributes.start_time),
            new Date()
          );
          if (diff < 0 && Math.abs(diff) >= 15) {
            this.toggleTimePassedModal();
            return;
          }

          this.setState({ roomDetails: elem }, () => {
            this.handleRulesOpen();
          });
        }}
      >
        ENTER
      </strong>
    )
  }

  cancelComponent = (elem: any) => {
    return (
      <>
        <>
          <Hidden smDown>
            <Typography style={{ color: '#7f8e9d' }} variant='body1'>You can ENTER 5 min before the start time.</Typography>
          </Hidden>
          <strong
            className="fs20 fw600 colorBlue pointer"
            onClick={() => {
              if (elem?.attributes?.is_host) this.CancelModalOpen(elem);
              else this.RemoveModalOpen(elem);
            }}
          >
            {' '}
            CANCEL
          </strong>
        </>
      </>
    )
  }

  renderEnterOrCancelBtn = (elem: any) => {
    let UpcomingApiDate = elem?.attributes?.start_time; // got from the Api
    const currentTime = new Date().getTime() / 1000;
    const belowTime = new Date(UpcomingApiDate).getTime() / 1000;
    const difference = belowTime - currentTime;

    let componentToRender;

    if (elem?.attributes?.is_host || elem?.attributes?.is_registered) {
      if (difference <= 300 || this.state.roomStartNotification === parseInt(elem.id)) {
        componentToRender = this.enterComponent(elem);
      } else {
        componentToRender = this.cancelComponent(elem);
      }
    }

    return <>{componentToRender}</>;
  }

  renderSkeletonActionBtn = (elem: any) => {
    return (
      <div className="daf action-status">
        <div className="daf">
          <img src={as} alt="" className="w18 mr10" />
          <strong className="fs20 fw600 colorBlue">
            {elem?.attributes?.room_price}
          </strong>
        </div>

        {this.renderRegisterBtn(elem)}

        {this.renderFullBtn(elem)}

        {this.renderEnterOrCancelBtn(elem)}
      </div>
    )
  }

  renderRegisterModal = (elem: any) => {
    const { addResources, registerAnonymously, participantsDetails } = this.state;

    return (
      <>
        {
          this.state.registerModal === true && (
            <section className="register-room">
              <div>
                <div className="daf mb15">
                  <h2 className="fs18 colorBlue fw600 mb0 mt0">
                    Register for this Room
                  </h2>
                  <CloseIcon
                    onClick={() => {
                      this.RegisterModalClose();
                      this.setState({ selectedRoomId: 0 });
                    }}
                    className="pointer"
                  />
                </div>
                <h3 className="fs16 colorBlue fw600 mb15 mt0">
                  Participants and Resources
                </h3>

                <ParticipantCard
                  full_name={
                    participantsDetails[0]?.attributes?.host?.data?.attributes
                      ?.full_name
                  }
                  age_range={
                    participantsDetails[0]?.attributes?.host?.data?.attributes
                      ?.age_range
                  }
                  anonymously_name={
                    participantsDetails[0]?.attributes?.anonymously_name
                  }
                  gender={
                    participantsDetails[0]?.attributes?.host?.data?.attributes
                      ?.gender
                  }
                  hearts={
                    participantsDetails[0]?.attributes?.host?.data?.attributes
                      ?.hearts || 0
                  }
                  is_anonymously={
                    participantsDetails[0]?.attributes?.is_anonymously
                  }
                  profile_image={
                    participantsDetails[0]?.attributes?.host?.data?.attributes
                      ?.profile_image
                  }
                  your_take={participantsDetails[0]?.attributes?.your_take}
                  resource={participantsDetails[0]?.attributes?.room_resources}
                />

                {participantsDetails.map((elem: any) => {
                  return (
                    <div key={elem.id}>
                      {elem?.attributes?.participants?.data?.length > 0 &&
                        elem?.attributes?.participants?.data?.map(
                          (contestant: any) => {
                            const is_anonymously =
                              contestant?.attributes?.is_anonymously;
                            return (
                              <ParticipantCard
                                full_name={
                                  contestant?.attributes?.participant?.data
                                    ?.attributes?.full_name
                                }
                                age_range={
                                  contestant?.attributes?.participant?.data
                                    ?.attributes?.age_range
                                }
                                anonymously_name={
                                  contestant?.attributes?.anonymously_name
                                }
                                gender={
                                  contestant?.attributes?.participant?.data
                                    ?.attributes?.gender
                                }
                                hearts={
                                  contestant?.attributes?.participant?.data
                                    ?.attributes?.hearts || 0
                                }
                                is_anonymously={is_anonymously}
                                profile_image={
                                  contestant?.attributes?.participant?.data
                                    ?.attributes?.profile_image
                                }
                                your_take={contestant?.attributes?.your_take}
                                key={contestant.id}
                                resource={
                                  contestant?.attributes?.resources_and_likes
                                }
                              />
                            );
                          }
                        )}
                    </div>
                  );
                })}
                <section className="radius8 mb15 bgWhite">
                  <Formik
                    initialValues={{
                      yourTake: '',
                      resource: [''],
                      anonymus: false,
                      screenName: '',
                      hasError: [true],
                    }}
                    validationSchema={this.createRoomSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      function isValidURL(url: string) {
                        const pattern =
                          /^(https?:\/\/)([a-zA-Z0-9.-]+\.)?(maps\.)?[a-zA-Z0-9-]{2,256}\.[a-z]{2,6}([/?#].*)?$/gi;
                        const result = pattern.test(
                          url.includes('http') || url.includes('https')
                            ? url
                            : 'https://' + url
                        );
                        return result;
                      }

                      const isValidURLS = values.resource.every((url) => {
                        return (
                          url == '' ||
                          url == undefined ||
                          url == null ||
                          isValidURL(url)
                        );
                      });

                      if (!isValidURLS) {
                        this.handleAlert('Please enter valid URLs');
                        return;
                      }

                      if (registerAnonymously && values.screenName == '') {
                        this.handleAlert('Please enter your screen name');
                        return;
                      }

                      const payload = new FormData();
                      payload.append(
                        'registered_room[room_id]',
                        `${this.state.selectedRoomId}`
                      );
                      payload.append(
                        'registered_room[is_anonymously]',
                        `${registerAnonymously}`
                      );

                      const validResource = values.resource.filter(
                        (item) => item
                      );
                      validResource.forEach((item: any) => {
                        payload.append(
                          'registered_room[resources_attributes][][url]',
                          item
                        );
                      });

                      // payload.append("registered_room[resources_attributes][][url]", values?.resource)
                      payload.append(
                        'registered_room[anonymously_name]',
                        values?.screenName
                      );
                      payload.append(
                        'registered_room[your_take]',
                        values?.yourTake
                      );

                      for (let pair of payload.entries()) {
                        console.log('payload', pair[0] + ', ' + pair[1]);
                      }
                      this.registerRoom(
                        payload,
                        elem?.attributes?.is_registered
                      );
                      this.setState({ selectedRoomId: 0 });
                      this.setState({ registerModal: false });
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                      /* and other goodies */
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <h3 className="fs18 colorBlue fw500 mt0 mb10">
                          Your Take
                        </h3>
                        <div className="room-details-register">
                          <TextField
                            className="register-textarea"
                            multiline
                            name="yourTake"
                            variant="outlined"
                            value={values.yourTake}
                            placeholder="Please share what you will be bringing to this conversation - a subtopic, experience, question, perspective, some ideas, etc. You can also share any relevant resources such as YouTube links, websites, Instagram / Pinterest pages, etc. using the link below"
                            onChange={(e) => {
                              e.target.value = e.target.value?.trimStart();
                              handleChange(e);
                            }}
                            // margin="normal"
                            inputProps={{
                              maxLength: 300,
                            }}
                            helperText={`${300 - values.yourTake.length
                              } Characters`}
                            onBlur={handleBlur}
                          />
                          {errors.yourTake && (
                            <span
                              style={{
                                display: 'block',
                                color: 'red',
                                fontSize: '12px',
                                textAlign: 'start',
                              }}
                            >
                              {errors.yourTake &&
                                touched.yourTake &&
                                errors.yourTake}
                            </span>
                          )}
                        </div>
                        <div
                          className="dFlexAlignCenter mb10"
                          onClick={() =>
                            this.setState((prev) => ({
                              addResources: !prev.addResources,
                            }))
                          }
                        >
                          <label
                            htmlFor="resources"
                            className="fs15 fw600 colorBlue add-resources"
                            style={{ cursor: 'pointer' }}
                          >
                            <img src={attach} alt="" />
                            Add resources
                          </label>
                        </div>
                        {addResources === true ? (
                          <div className="room-details-register mb5 pr">
                            <FieldArray
                              name="resource"
                              render={({ remove, push }: { remove: RemoveFunction, push: PushFunction }) => (
                                <>
                                  {values.resource.map(
                                    (resourceData: any, index: number) => (
                                      <div
                                        style={{
                                          marginTop:
                                            index == 0 ? undefined : '16px',
                                          position: 'relative',
                                        }}
                                        key={index}
                                      >
                                        <input
                                          name={`resource.${index}`}
                                          value={resourceData}
                                          placeholder={
                                            'Enter your resource link'
                                          }
                                          onChange={(e) => {
                                            e.target.value =
                                              e.target.value?.trimStart();
                                            handleChange(e);
                                          }}
                                          onBlur={(e) => {
                                            const url = e.target.value?.trimStart();
                                            console.log('e.target.value', url);
                                            const pattern =
                                              /^(https?:\/\/)?([a-zA-Z0-9.-]+\.)?(maps\.)?[a-zA-Z0-9-]{2,256}\.[a-z]{2,6}([/?#].*)?$/gi;

                                            let result = pattern.test(url);

                                            if (
                                              !result &&
                                              values.resource[index] == ''
                                            ) {
                                              result = true;
                                            }
                                            const arr = [...values.hasError];
                                            arr[index] = result;
                                            console.log('arr', arr);
                                            setFieldValue(`hasError`, arr);

                                            handleBlur(e);
                                          }}
                                          style={{
                                            width: '100%',
                                            borderRadius: '10px',
                                            height: '45px',
                                            border: '0.1px solid #DBDAE3',
                                            fontSize: '16px',
                                            paddingLeft: '10px',
                                          }}
                                          autoComplete="off"
                                        />

                                        {index > 0 && (
                                          <span
                                            style={{
                                              fontSize: '23px',
                                              fontWeight: 'bolder',
                                              color: 'grey',
                                              cursor: 'pointer',
                                              top: '4px',
                                              right: '10px',
                                              position: 'absolute',
                                            }}
                                            onClick={() => {
                                              remove(index);
                                              const arr = [
                                                ...values.hasError,
                                              ].splice(index, 1);
                                              setFieldValue(`hasError`, arr);
                                            }} // remove a friend from the list
                                          >
                                            -
                                          </span>
                                        )}
                                        <span
                                          style={{
                                            display: 'block',
                                            color: 'red',
                                            fontSize: '12px',
                                            textAlign: 'start',
                                          }}
                                        >
                                          {!values.hasError[index] &&
                                            '* Please enter a valid url!'}
                                          {/* // {errors[`resource${index+1}`] && errors.resource} */}
                                        </span>
                                      </div>
                                    )
                                  )}

                                  {values.resource.length < 3 && (
                                    <img
                                      src={addIcon}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        const arr = [...values.hasError, true];
                                        setFieldValue(`hasError`, arr);
                                        push('');
                                      }}
                                      style={{
                                        width: '15px',
                                        height: '15px',
                                        top: '13px',
                                        right: '13px',
                                        position: 'absolute',
                                        cursor: 'pointer',
                                      }}
                                      alt="+"
                                    />
                                  )}
                                </>
                              )}
                            />
                          </div>
                        ) : null}
                        <div className="dFlexAlignCenter mb10">
                          <input
                            type="checkbox"
                            id="register"
                            checked={registerAnonymously}
                            onChange={(e) =>
                              this.setState({
                                registerAnonymously: e.target.checked,
                              })
                            }
                          />
                          <label
                            htmlFor="register"
                            className="fs15 ml10 fw600 colorBlue"
                            style={{ cursor: 'pointer' }}
                          >
                            REGISTER ANONYMOUSLY
                          </label>
                        </div>
                        {registerAnonymously === true && (
                          <div className="room-details-register">
                            <label
                              htmlFor="register"
                              className="fs15 fw600 colorBlue"
                            >
                              Screen Name*
                            </label>
                            <br />
                            <input
                              type="text"
                              className="w-100"
                              placeholder="Screen Name"
                              name="screenName"
                              value={values.screenName}
                              onChange={(e) => {
                                e.target.value = e.target.value?.trimStart();
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                            />
                          </div>
                        )}

                        <Box mt={4.5} textAlign='center'>
                          <Typography style={{ fontSize: '.8rem', color: '#00000055' }}>
                            All calls are scheduled for 1 hour <br /> and extendable to 2 hours at no extra cost.
                          </Typography>
                        </Box>

                        <Button
                          type="submit"
                          variant="contained"
                          style={{
                            background:
                              this.context.getUserYCoins() < elem.attributes.room_price
                                ? '#dedede'
                                : '#FFCC00',
                            color:
                              this.context.getUserYCoins() < elem.attributes.room_price
                                ? '#fff'
                                : '#010138',
                            width: '100%',
                            padding: '15px 0px !important',
                            textTransform: 'none',
                            marginTop: '20px',
                            marginBottom: '20px',
                          }}
                          disabled={
                            this.context.getUserYCoins() < elem.attributes.room_price
                              ? true
                              : false
                          }
                        >
                          <Typography
                            style={{ fontWeight: 600, fontSize: '18px' }}
                          >
                            Register
                          </Typography>
                        </Button>
                      </form>
                    )}
                  </Formik>
                  {this.context.getUserYCoins() < this.state.currentRoomPrice && (
                    <div
                      className="text-center"
                      onClick={this.handleRefillOpen}
                    >
                      <span className="fs14 colorBlue fw600 textUnderline">
                        Refill your wallet
                      </span>
                    </div>
                  )}
                </section>
              </div>
            </section>
          )
        }
      </>
    )
  }

  renderCongratulationsDailog = () => {
    return (
      <Dialog
        open={this.state.congratulationModal.isOpen}
        onClose={this.CongratulationRegisteringModalClose}
        PaperProps={{
          style: {
            maxWidth: '724px',
            height: this.state.isMobile ? '100vh' : 'auto',
            borderRadius: this.state.isMobile ? '1rem 1rem 0 0' : '10px',
          },
        }
        }
        className="DialogWithoZeroMargin fullHeight"
      >
        <Box
          py={4}
          px={this.state.isMobile ? '2rem' : 10}
          textAlign="center"
          display="flex"
          flexDirection="column"
          flex={this.state.isMobile ? 'none' : 1}
          height={'97vh'}
          overflow={'auto'}
        >
          {!this.state.isMobile && (
            <h3 className={`$fs24 fw500 colorBlue mb20 mt0`}>
              Congratulations on Registering for{' '}
              {this.state.congratulationModal.room_name} Room on{' '}
              {getDateTime(this.state.congratulationModal.dateTime)}
            </h3>
          )}
          <img
            src={createRoomSuccess}
            alt=""
            className={`${this.state.isMobile ? '' : 'h_300'} mb10`}
          />

          {this.state.isMobile && (
            <Box>
              <h3 className="fs18 fw500 mb10 mt0">
                Congratulations on Registering for{' '}
              </h3>

              <Box
                sx={{
                  mt: 1,
                  mb: 3,
                  border: '1px solid #E0E0E0',
                  p: 1,
                  borderRadius: 10,
                  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
                }}
              >
                <Typography
                  style={{
                    color: '#010039',
                    fontWeight: 600,
                    wordBreak: 'break-all',
                  }}
                >
                  {this.state.congratulationModal.room_name} Room {' '}
                </Typography>
                <Typography
                  style={{
                    fontSize: '.8rem',
                    color: 'gray',
                  }}
                >
                  on {getDateTime(this.state.congratulationModal.dateTime)}
                </Typography>
              </Box>
            </Box>
          )}

          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            mt={2}
            pb={3.5}
            onClick={this.SyncModalOpen}
            style={{ gap: '.5rem' }}
          >
            <img src={calendar} alt="dsaf" className="radius4" width='18px' height='18px' />
            <p className="mb0 colorGrey fw600 fs14 mt0 pointer">
              Sync to Google Calendar
            </p>
          </Box>

          <ul>
            {this.state.bulletPoints?.map((point) => (
              <li
                className={`${this.state.isMobile ? "fs16" : "fs14"} ${this.state.isMobile ? "fw400" : "fw500"
                  } colorGrey mb5`}
                style={{ listStyle: "unset", textAlign: "start" }}
                key={point}
              >
                {point}
              </li>
            ))}
          </ul>

          <Box
            mt={3}
            mb={2}
            className="cursor submit-btn-modal"
            onClick={this.CongratulationRegisteringModalClose}
          >
            Continue
          </Box>

          <Box>
            <Button onClick={() => this.props.history.push('/Setting?page=Refer')}
              variant='text'
              style={{ color: '#010039' }}
            >
              Refer a fried
            </Button>
          </Box>
        </Box>
      </Dialog>
    )
  }

  renderHostCalcellationModal = (elem: any) => {
    return (
      <Modal
        open={this.state.cancelModal}
        onClose={this.CancelModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <section className="modal p15">
          <h3 className="fs15 colorBlue fw500 mb10 mt0">
            Are you sure you want to cancel ?
          </h3>
          <p className="fs14 colorGrey fw500 mt0 mb15">
            Your YCoins will be refunded to you as per our cancellation
            policy. Visit
            <Link to="/Setting" className="colorBlue fw600 ml5 mr5">
              FAQs
            </Link>
            for more details.
          </p>
          <div className="daf">
            <button
              className="btn-transparent fs15 colorBlue fw500 width45"
              onClick={this.CancelModalClose}
            >
              No
            </button>
            <button
              className="btn-blue fs15 colorWhite fw500 width45"
              onClick={() => {
                this.context.setCoin(elem.attributes.room_price, true);
                this.myTabDeleteRoom(this.state.myRoomTabId);
              }}
            >
              Yes
            </button>
          </div>
        </section>
      </Modal>
    )
  }

  renderParticipantsCancellationModal = () => {
    return (
      <Modal
        open={this.state.removeModal}
        onClose={this.RemoveModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <section className="modal p15">
          <h3 className="fs15 colorBlue fw500 mb10 mt0">
            Are you sure you want to retract your registration request?
          </h3>
          <div className="daf">
            <button
              style={{ cursor: 'pointer' }}
              className="btn-transparent fs15 colorBlue fw500 width45"
              onClick={() => this.RemoveModalClose()}
            >
              No
            </button>
            <button
              style={{ cursor: 'pointer' }}
              className="btn-blue fs15 colorWhite fw500 width45"
              onClick={() =>
                this.removingFromRoom(this.state.removeParticpantId)
              }
            >
              Yes
            </button>
          </div>
        </section>
      </Modal>
    )
  }

  upcomingRoomsSkeleton = (elem: any) => {
    const { upcomingRoomsData, shareMenuOpen, shareMenuAnchor, sharedRoomDetails } = this.state;
    const walletYCoins = this.context.getUserYCoins();

    this.handleDeleteCount(elem);
    let url = window.location.href.split('/')[3];

    if (this.isExpireRoom(elem)) return null;

    const timeZone = 'en-US'; // Set the time zone to a specific value to ensure consistent display across platforms

    const UpcomingNewDateFormat = new Date(elem?.attributes?.start_time)
      .toLocaleString(timeZone, {
        hour: 'numeric',
        minute: '2-digit',
        month: '2-digit',
        year: 'numeric',
        day: '2-digit',
        hour12: true, // Set to true to display time in 12-hour format with AM/PM
      })
      .replace(/,/g, ' ');
    const seat_count = elem?.attributes?.max_participants - elem?.attributes?.participants_count;

    return (
      <div
        style={this.getMainDivStyles()}
        className="upcomingRoomsSkeleton"
        key={elem?.id}
      >
        <section
          className={`${this.getSectionClasName(elem)} room-details mb20 shadow`}
        >
          <Grid container className="bBottom">
            <Grid
              item
              xs={12}
              md={8}
              className={`${this.getGridClassName()}`}
            >
              <Link
                to={{
                  pathname: `RoomDetailsWeb/${elem?.id}`,
                  state: { url, upcomingRoomsData, walletYCoins },
                }}
              >
                <div className="p15 ">
                  <div className="daf">
                    <h3 className="fs22 colorBlue fw500 mt0 mb0">
                      {elem?.attributes?.topic_name}
                    </h3>
                    <div>
                      {/* <Tooltip title="Duplicate Room">
                        <img
                          src={plle}
                          alt="dsaf"
                          className="w22 d-btn"
                          onClick={() => this.setData(elem)}
                        />
                      </Tooltip>*/}
                      <ShareLink roomDetail={elem} />
                    </div>
                  </div>
                  <div className="mb15">
                    <p className="fs14 fw500 colorGrey mt0 mb0">
                      {elem?.attributes?.sub_category_name}
                    </p>
                    <span className="fs12 fw500 colorGrey">
                      {elem?.attributes?.room_type_name}
                    </span>
                  </div>
                  <p className="fs14 colorGrey fw500 mh75">
                    {elem?.attributes?.description}
                  </p>
                  <div className="seats-time">
                    <div>
                      <p className="fs14 colorGrey fw500 mb5 mt0">
                        {`${seat_count} seat${seat_count > 1 ? 's': ''} remaining`}
                      </p>
                      <p className="fs14 colorBlue fw500 mt0 mb0">
                        {UpcomingNewDateFormat}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              style={this.getHostGridStyles()}
            >
              <div
                className="m15"
                style={this.getHostDivStyles()}
              >
                {!this.state.isMobile && (
                  <p className="fs15 colorGrey mb10">Host</p>
                )}
                {this.state.isMobile && (
                  <Typography
                    className="m10"
                    style={{
                      letterSpacing: 1,
                      color: '#979797',
                      fontWeight: 500,
                    }}
                  >
                    HOST
                  </Typography>
                )}
                <div className="d-flex">
                  {this.renderUserImg(elem)}
                  {this.renderOtherDetails(elem)}
                </div>
                {this.renderSkeletonWebParticipants(elem)}
              </div>

              {this.renderSkeletonMobileParticipants(elem)}
            </Grid>
          </Grid>
          {this.renderSkeletonActionBtn(elem)}
        </section >

        {this.renderRegisterModal(elem)}

        {this.renderCongratulationsDailog()}

        {this.renderHostCalcellationModal(elem)}

        {this.renderParticipantsCancellationModal()}
      </div >
    );
  };

  seeMoreRooms = () => {
    return (
      <>
        {this.state.myPastRooms?.length > 0 && !this.state.seeMorePast && (
          <div
            className="seeMoreRoomButton"
            onClick={() => {
              this.setState({ seeMorePast: true });
            }}
          >
            See more rooms
          </div>
        )}
      </>
    );
  };

  pastRooms = () => {
    const pathName = this.getPathName();
    const { isMobile, loader, myPastRooms } = this.state;

    return (
      <>
        {pathName === 'my' &&
          (loader ? (
            <ClipLoader color="#ffcb00" size={30} className="loader" />
          ) : (
            myPastRooms.length > 0 && (
              <>
                <h3
                  className={`${isMobile ? 'fs20' : 'fs24'
                    } colorBlue fw500 mb15 mt10 ${isMobile ? 'ml15' : ''}`}
                >
                  Past Rooms
                </h3>
                <div>
                  {this.state.myPastRooms?.map((elem: any, index: number) => {
                    return this.myRoomsSkeleton(elem);
                  })}

                  <div className="mr15">{isMobile && this.seeMoreRooms()}</div>
                </div>

                {!isMobile && this.seeMoreRooms()}
              </>
            )
          ))}
      </>
    );
  };

  roomsData = () => {
    const pathName = this.getPathName();
    const { upcomingRoomsData } = this.state;

    return (
      <div>
        {upcomingRoomsData?.map((elem: any, index: number) => {
          if (pathName === 'my' && !this.state.seeMoreUpcoming && index < 2) {
            return <div key={index}>{this.upcomingRoomsSkeleton(elem)}</div>
          } else if (pathName !== 'my' || this.state.seeMoreUpcoming) {
            return <div key={index}>{this.upcomingRoomsSkeleton(elem)}</div>;
          }
        })}
      </div>
    );
  };

  otherRooms = () => {
    const isModerator = JSON.parse(localStorage.getItem('isModerator') as string);
    const { upcomingRoomsData } = this.state;
    const pathName = this.getPathName();

    return (
      <>
        {
          !upcomingRoomsData.length && <>{this.noRoomBox()}</>
        }

        {!this.state.isMobile &&
          pathName !== 'my' &&
          !!upcomingRoomsData?.length && (
            <InfiniteScroll
              dataLength={upcomingRoomsData?.length}
              next={() => {
                this.setState({ page: this.state.page + 1 }, () => {
                  this.handleCallRoomAPI(this.state.filters);
                });
              }}
              hasMore={this.state.hasMore}
              loader={<> </>}
              height={'90vh'}
              className="infinite-scroll"
            >
              {this.roomsData()}
              {this.noRoomBox()}
            </InfiniteScroll>
          )}

        {this.state.isMobile &&
          pathName !== 'my' &&
          !!upcomingRoomsData?.length && (
            <div
              style={{
                height: window.innerHeight > 700 ? '66vh' : '58vh',
                overflowY: 'auto',
              }}
              className="infinite-scroll" // add this class to remove scroll bar on mobile
            >
              {this.roomsData()}
              {this.noRoomBox()}

              {/* pagination */}
              <Box
                sx={{ pb: 5, pt: 1 }}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CustomPaginationUse
                  totalPage={Math.ceil(this.state.totalPage / 5)}
                  currentPage={this.state.page}
                  handlePageChange={this.handlePageChange}
                  isFetching={this.state.loader}
                />
              </Box>

              {/* moderator */}
              <Box>
                <Link to="/ModeratorList" style={{ color: '#27272A' }}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    style={{
                      border: '1px solid #E0E0E0',
                      padding: '0.6rem 1.2rem',
                    }}
                  >
                    <Typography style={{ fontWeight: 600 }}>
                      Moderators
                    </Typography>
                    <ArrowForwardIosIcon style={{ fontSize: 15 }} />
                  </Box>
                </Link>
                {!isModerator && (
                  <Link to="ModuleOrientation" style={{ color: '#27272A' }}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      style={{
                        padding: '0.6rem 1.2rem',
                        borderBottom: '1px solid #E0E0E0',
                      }}
                    >
                      <Typography style={{ fontWeight: 600 }}>
                        Become a Moderator
                      </Typography>
                      <ArrowForwardIosIcon style={{ fontSize: 15 }} />
                    </Box>
                  </Link>
                )}
              </Box>

              {/* random quotes */}
              {this.state.isMobile && <QuotesWeb {...this.props} />}
            </div>
          )}

        {pathName === 'my' &&
          upcomingRoomsData?.map((elem: any, index: number) => {
            if (
              pathName === 'my' &&
              !this.state.seeMoreUpcoming &&
              index < 2
            ) {
              return this.upcomingRoomsSkeleton(elem);
            } else if (pathName !== 'my' || this.state.seeMoreUpcoming) {
              return <div>{this.upcomingRoomsSkeleton(elem)}</div>;
            }
          })}

        {pathName === 'my' &&
          upcomingRoomsData?.length > 2 &&
          !this.state.seeMoreUpcoming && (
            <div
              className="seeMoreRoomButton"
              onClick={() => {
                this.setState({ seeMoreUpcoming: true });
              }}
            >
              See more rooms
            </div>
          )}
      </>
    );
  };

  renderTimePassedScreen = () => {
    return (
      <>
        {this.state.isTimePassed && (
          <LateJoinScreen
            isOpen={this.state.isTimePassed}
            handleClose={this.toggleTimePassedModal}
          />
        )}
      </>
    )
  }

  renderUpdateModal = () => {
    const { filters } = this.state;

    return (
      <>
        {this.state.dublicateRoom.isOpen && (
          <CreateRoom
            {...this.props}
            getWalletAmount={this.getWalletCoins}
            updateYCoins={this.getWalletCoins}
            history={this.props.history}
            routeMap={{}}
            onclose={this.closeUpdateModal}
            walletAmount={this.state.walletYCoins}
            getMyRooms={() => {
              this.setState(
                { page: 1, hasMore: true, upcomingRoomsData: [] },
                () => {
                  this.handleCallRoomAPI(filters);
                }
              );
            }}
            navigation={this.props.navigation}
            prevData={this.state.dublicateRoom.data}
          />
        )}
      </>
    )
  }

  getContainerStyles = () => {
    const styles: React.CSSProperties = {
      marginTop: this.state.isMobile ? '10%' : '90px',
      padding: this.state.isMobile ? 0 : 'inherit',
    }

    return styles;
  }

  renderSelectedFilters = () => {
    const { selectedFilters } = this.state;
    return (
      <>
        {selectedFilters.length > 0 && (
          <Box
            display="flex"
            alignItems="center"
            mb={3}
            className="filters-chip"
          >
            {selectedFilters.map((filter: any, index: number) => {
              return (
                <Chip
                  key={index}
                  label={filter.topic_name}
                  onDelete={(_) => {
                    this.handleDeleteShowFilter(filter);
                  }}
                  style={{
                    marginRight: '.5rem',
                    background: '#ffcb00',
                    fontWeight: 600,
                    fontSize: this.state.isMobile ? 12 : 'inherit',
                  }}
                  deleteIcon={
                    <ClearIcon
                      style={{
                        color: '#000',
                        marginLeft: this.state.isMobile ? 0 : '.5rem',
                      }}
                    />
                  }
                />
              );
            })}
          </Box>
        )}
      </>
    )
  }

  renderSyncToGooleCalender = () => {
    const pathName = this.getPathName();

    return (
      <>
        {pathName === 'my' && (
          <>
            <Hidden smDown>
              <Grid
                container
                justifyContent="space-between"
                className="mb20"
              >
                <Grid item>
                  <section
                    className="sync-calendar dFlexAlignCenter"
                    onClick={() => {
                      this.SyncModalOpen();
                    }}
                  >
                    <img src={calendar} alt="dsaf" className="radius4" />
                    <p className="ml10 mb0 colorGrey fw500 fs16 mt0 pointer">
                      Sync to Google Calendar
                    </p>
                  </section>
                </Grid>
              </Grid>
            </Hidden>

            <Hidden smUp>
              <Box
                sx={{
                  pl: 3,
                  pb: 2,
                  pr: 3,
                  pt: 2,
                  borderTop: '1px solid #E5E5E5',
                  borderBottom: '1px solid #E5E5E5',
                  mb: 2,
                }}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box display="flex" alignItems="center">
                  <GoogleCalendar />

                  <Typography style={{ fontWeight: 600, marginLeft: 15 }}>
                    Sync rooms to <br /> Google Calendar
                  </Typography>
                </Box>

                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={this.state.isSyncWithGoogleCalender}
                      onChange={() => {
                        this.setState({
                          isSyncWithGoogleCalender:
                            !this.state.isSyncWithGoogleCalender,
                        });
                        this.SyncModalOpen();
                      }}
                      name="g_calendar"
                    />
                  }
                  style={{ marginRight: 0 }}
                  label=""
                />
              </Box>
            </Hidden>
          </>
        )}
      </>
    )
  }

  renderAvailableRoomsOnlyFilter = () => {
    const pathName = this.getPathName();

    return (
      <>
        {pathName !== 'my' && (
          <Hidden smUp>
            <Box
              border="1px solid #ccc"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ pl: 2, pr: 2, pt: 0.5, pb: 0.5 }}
            >
              <Typography style={{ fontWeight: 600 }}>
                Available Rooms Only
              </Typography>

              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={this.state.available_only}
                    onChange={this.toggleAvailability}
                    name="checkedB"
                  />
                }
                style={{ marginRight: 0 }}
                label=""
              />
            </Box>
          </Hidden>
        )}
      </>
    )
  }

  renderOtherRooms = () => {
    const { loader } = this.state;

    return (
      <>
        {loader ? (
          <ClipLoader color="#ffcb00" size={30} className="loader" />
        ) : (
          this.otherRooms()
        )}
      </>
    )
  }

  renderMobileFilters = () => {
    return (
      <>
        <Hidden smUp>
          <Box
            id="bottomComponent"
            style={{
              position: 'fixed',
              bottom: 0,
              width: '100%',
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
              zIndex: 1200,
            }}
            sx={{
              bgcolor: !this.state.selectedFilter
                ? '#fff'
                : 'rgba(0, 0, 0, 0)',
              border: '1px solid #E0E0E0',
              boxShadow: !this.state.selectedFilter
                ? '0px 0px 10px rgba(0, 0, 0, 0.25)'
                : 'none',
            }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {this.state.selectedFilter === null && (
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    borderRight: '1px solid #E0E0E0',
                    padding: '1.2rem .7rem',
                  }}
                  onClick={() =>
                    this.handleChangeMobileFilter('categories')
                  }
                >
                  <Typography
                    style={{
                      color: '#27272A',
                      fontSize: '.9rem',
                      fontWeight: 500,
                    }}
                  >
                    Categories
                  </Typography>
                  <ExpandLessIcon style={{ fontSize: 15 }} />
                </Box>

                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    borderRight: '1px solid #E0E0E0',
                    padding: '1.2rem .7rem',
                  }}
                  onClick={() =>
                    this.handleChangeMobileFilter('room_times')
                  }
                >
                  <Typography
                    style={{
                      color: '#27272A',
                      fontSize: '.9rem',
                      fontWeight: 500,
                    }}
                  >
                    Room Times
                  </Typography>
                  <ExpandLessIcon style={{ fontSize: 15 }} />
                </Box>

                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ padding: '1.2rem .7rem' }}
                  onClick={() =>
                    this.handleChangeMobileFilter('room_type')
                  }
                >
                  <Typography
                    style={{
                      color: '#27272A',
                      fontSize: '.9rem',
                      fontWeight: 500,
                    }}
                  >
                    Room Types
                  </Typography>
                  <ExpandLessIcon style={{ fontSize: 15 }} />
                </Box>
              </>
            )}

            {this.state.selectedFilter && (
              <>
                <div
                  style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100dvh',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    zIndex: 1200,
                    backdropFilter: 'blur(4px)',
                  }}
                  onClick={() => this.handleChangeMobileFilter(null)}
                />
                <Draggable
                  axis="y"
                  bounds={{ top: 0 }}
                  onStop={this.handleDrag}
                  position={this.state.defaultPosition}
                  handle=".drag-handle"
                >
                  <Slide direction="up" in={!!this.state.selectedFilter}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      height={
                        this.state.selectedFilter === 'categories'
                          ? '80vh'
                          : 'auto'
                      }
                      zIndex={1300}
                      flex={1}
                      bgcolor="#fff"
                      style={{
                        borderTopLeftRadius: 20,
                        borderTopRightRadius: 20,
                      }}
                    >
                      <div style={{ height: '90%', width: '100%' }}>
                        {this.state.selectedFilter === 'categories' ||
                          this.state.selectedFilter === 'room_times' ? (
                          this.categoriesFilter()
                        ) : (
                          <RoomTypes
                            {...this.props}
                            navigation={this.props.navigation}
                            handleRoomType={this.handleRoomType}
                            roomType={this.state.room_type}
                            toggleAvailable={this.toggleAvailability}
                          />
                        )}
                      </div>
                    </Box>
                  </Slide>
                </Draggable>
              </>
            )}
          </Box>
        </Hidden>
      </>
    )
  }

  renderBackgroundForMyRoomSection = () => {
    const pathName = this.getPathName();

    return (
      <>
        {pathName === 'my' && (
          <div
            style={{
              backgroundColor: 'rgb(225 225 225 / 65%)',
              height: '2px',
            }}
          />
        )}
      </>
    )
  }

  render() {
    const { getMyConnects, selectedConnects, isFilterDrawerOpen, isShareRoomDialogOpen, searchKey, sharedRoomDetails } = this.state;
    // @ts-ignore
    const { width } = this.props;

    const isSmall = width === "sm" || width === "xs";

    let inviteMessage = "";
    if (isShareRoomDialogOpen) {
      const dt = parseISO(sharedRoomDetails?.attributes?.start_time);
      const time = format(dt, "hh:mm a");
      const date = format(dt, "do 'of' MMMM yyyy");
      inviteMessage = `Suggested conversation by ${localStorage.getItem('fullName')} - You are invited for a conversation on "${sharedRoomDetails?.attributes?.topic_name}" at ${time} on ${date}. See you in YLanes!\n${sharedRoomDetails?.attributes?.share_link}`
    }
    return (
      <>
        {/* Register Modal when user click on register button */}
        <AlertBlock
          data={{
            show: this.state.isMessagePop,
            close: this.handleCloseAlert,
            msg: this.msg,
            type: this.type,
            vertical: 'top',
            horizontal: 'center',
          }}
        />

        {/* rules of engagement screen, before joining */}
        <RulesOfEngagement
          roomDetails={this.state.roomDetails}
          ref={this.rulesRef}
          cb={this.enterRoom}
        />

        {/* if users joins 15 minutes late. */}
        {this.renderTimePassedScreen()}

        {/* Update Modal */}
        {this.renderUpdateModal()}

        <Container
          maxWidth={false}
          style={this.getContainerStyles()}
          className="text--02243"
        >
          {/* @ts-ignore */}
          <RefillWallet ref={this.myRef} />
          <Grid container spacing={2}>
            {/* Grid 1 start  */}
            <Hidden mdDown>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                className="scrollbarYNew pt0"
              >
                {this.categoriesFilter()}
              </Grid>
            </Hidden>
            {/* Grid 1 end  */}

            {/* Grid 2 start  */}
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={6}
              className="upcomingScrollbar pt0"
            >
              {this.renderSelectedFilters()}

              {/* @ts-ignore */}
              <RoomTabs
                {...this.props}
                handleRouteChange={this.handleRouteChange}
              />

              {this.renderSyncToGooleCalender()}

              <Dialog
                aria-labelledby="simple-dialog-title"
                open={false}
                fullWidth
                maxWidth="md"
                style={{ backdropFilter: 'blur(10px)' }}
              >
                <Box p={8}>
                  <Typography
                    style={{
                      fontWeight: 500,
                      fontSize: '30px',
                      color: '#27272A',
                    }}
                  >
                    Share with your connects
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    my={3}
                  >
                    <Typography
                      style={{
                        fontWeight: 500,
                        fontSize: '24px',
                        color: '#27272A',
                      }}
                    >
                      Edit Message
                    </Typography>
                    <Typography
                      component={ButtonBase}
                      style={{
                        fontWeight: 500,
                        fontSize: '24px',
                        color: '#010039',
                        padding: '8px',
                        borderRadius: '10px',
                      }}
                    >
                      SHARE {'>'}
                    </Typography>
                  </Box>
                  <TextField
                    fullWidth
                    label="Message"
                    multiline
                    minRows={6}
                    variant="outlined"
                  />
                  <Box mt={8}>
                    <Typography
                      style={{
                        fontWeight: 500,
                        fontSize: '24px',
                        color: '#27272A',
                        marginBottom: '8px',
                      }}
                    >
                      Select Connects
                    </Typography>
                    <List aria-label="main mailbox folders">
                      {getMyConnects.map((connect, index) => (
                        <ListItem
                          key={index}
                          button
                          selected={selectedConnects.includes(
                            connect.other_user_id
                          )}
                          onClick={() =>
                            this.updateSelectedConnects(connect.other_user_id)
                          }
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            gridGap="16px"
                          >
                            <Avatar
                              alt={connect.full_name}
                              src={connect?.profile_image || ''}
                            >
                              {connect.full_name.charAt(0)}
                            </Avatar>
                            <Typography
                              style={{
                                fontSize: '20px',
                                fontWeight: 500,
                                color: '#010039',
                              }}
                            >
                              {connect.full_name}
                            </Typography>
                          </Box>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Box>
              </Dialog>

              {/* Available Rooms Only Filter */}
              {this.renderAvailableRoomsOnlyFilter()}

              {this.renderOtherRooms()}

              {/* filters for mobile view */}
              {this.renderMobileFilters()}

              {this.renderBackgroundForMyRoomSection()}

              {/* render past rooms  */}
              {this.pastRooms()}

              {/* First Section  */}
            </Grid>
            {/* Grid 2 end  */}

            {/* Grid 3 start  */}

            <Hidden smDown>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={3}
                className="scrollbarYNew pt0"
              >
                <Hidden lgUp>{this.categoriesFilter()}</Hidden>
                {this.roomTypeFilter()}
              </Grid>
            </Hidden>
            {/* Grid 3 end  */}
          </Grid>
        </Container>
        <Hidden mdUp>
          <Drawer
            anchor="right"
            open={isFilterDrawerOpen}
            onClose={this.toggleFilterDrawer(false)}
            ModalProps={{ keepMounted: true }}
          >
            <Box p={1}>
              <Box
                mb={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" style={{ marginLeft: '8px' }}>
                  Filter
                </Typography>
                <IconButton
                  aria-label="close"
                  onClick={this.toggleFilterDrawer(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              {this.categoriesFilter()}
              {this.state.isMobile === false && this.roomTypeFilter()}
            </Box>
          </Drawer>
        </Hidden>
        <Dialog
          aria-labelledby="share-room-dialog"
          open={isShareRoomDialogOpen}
          onClose={this.handleShareRoomDialogClose}
          maxWidth="sm"
          fullWidth
          fullScreen={isSmall}
          PaperProps={
            isSmall
              ? {}
              : {
                style: {
                  borderRadius: '24px',
                },
              }
          }
          style={isSmall ? {} : { backdropFilter: 'blur(15px)' }}
        >
          <DialogContent style={{ textAlign: 'center', paddingBlock: '40px' }}>
            <Box position="absolute" top="0" right="0">
              <img
                className="cancel-icon"
                onClick={this.handleShareRoomDialogClose}
                src={cancelIcon}
                alt="close dialog"
              />
            </Box>
            <Typography style={{ fontWeight: 600, fontSize: '24px', color: '#1E3354' }}>
              Share with your Connects
            </Typography>
            <Typography style={{ fontWeight: 400, fontSize: '16px', color: '#7F8E9D' }}>
              Select connects to share this room with.
            </Typography>
            <TextField
              style={{ marginBlock: '16px' }}
              id="outlined-multiline-flexible"
              label="Invitation Message"
              multiline
              disabled
              fullWidth
              variant='outlined'
              value={inviteMessage}
              maxRows={4}
            />
            <Box textAlign="left" marginTop="8px">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography style={{ fontWeight: 500, fontSize: '16px', color: '#010039' }}>
                  Select Connects
                </Typography>
                <ThemeProvider theme={theme}>
                  <Chip size="small" color='primary' label={selectedConnects.length} />
                </ThemeProvider>
              </Box>
              <TextField
                placeholder="Search"
                variant="outlined"
                fullWidth
                value={searchKey}
                onChange={(e) => {
                  // TODO: Add debounce
                  e.persist();
                  this.setState({
                    searchKey: e.target.value
                  });
                  this.getMyConnects(e.target.value);
                }}
                InputProps={{
                  style: { borderRadius: '50px' },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <List style={{ maxHeight: '350px', marginBlock: '16px', overflowY: "auto" }} aria-label="main mailbox folders">
                {getMyConnects.map((connect, index) => (
                  <ListItem
                    key={index}
                    button
                    style={{ justifyContent: 'space-between' }}
                    selected={selectedConnects.includes(
                      connect.other_user_id
                    )}
                    onClick={() =>
                      this.updateSelectedConnects(connect.other_user_id)
                    }
                  >
                    <Box display="flex" gridGap="8px" alignItems="center" >
                      <Avatar
                        alt={connect.full_name}
                        src={connect?.profile_image || ''}
                      >
                        {connect.full_name.charAt(0)}
                      </Avatar>
                      <Typography
                        style={{
                          fontSize: '20px',
                          fontWeight: 500,
                          color: '#010039',
                        }}
                      >
                        {connect.full_name}
                      </Typography>
                    </Box>
                    <Checkbox
                      checked={selectedConnects.includes(
                        connect.other_user_id
                      )}
                    />
                  </ListItem>
                ))}
              </List>
              <ThemeProvider theme={theme}>
                <Button
                  fullWidth
                  color='primary'
                  variant='contained'
                  disabled={!selectedConnects?.length}
                  onClick={() => this.shareToConnects(inviteMessage)}
                >
                  Share
                </Button>
              </ThemeProvider>
            </Box>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default withRouter(withWidth()(UpcomingRooms));
// Customizable Area End
