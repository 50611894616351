import React, { useState, useEffect } from 'react'
import AlertBlock from '../../../../alert/src/Alert.web';
import {
   Popover,
   Box,
   ButtonBase,
   Typography,
   Divider,
   Tooltip,
   IconButton,
   Dialog,
   TextField,
   ListItem,
   List,
   Checkbox,
   Avatar,
   DialogContent,
   Button,
   InputAdornment,
   Chip
 } from '@material-ui/core';
 import {
  cancelIcon,
} from '../../assets';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import GroupIcon from '@material-ui/icons/Group';
import ShareIcon from '@material-ui/icons/Share';
import LinkIcon from '@material-ui/icons/Link';
import SearchIcon from '@material-ui/icons/Search';
import { format, parseISO } from 'date-fns';
const url = require('../../../../../framework/src/config.js')

const ShareLink = ({roomDetail}: any) => {
  const [toggleMenu, setToggleMenu] = useState<boolean>(false)
  const [anchor, setAnchor] = useState()
  const [selectedConnects, setSelectedConnects] = useState<number[]>([])
  const [toggleFrineds, setToggleFriends] = useState<boolean>(false)
  const [myConnects, setMyConnects] = useState<any[]>([])
  const [inviteMessage, setInviteMessage] = useState<string>("")
  const [searchKey, setSearchKey] = useState<string>("")
  const [msg, setMsg] = useState<string>("")
  const [type, setType] = useState<string>("")
  const [isMessagePop, setIsMessagePop] = useState<boolean>(false)

  const theme = createTheme({
    palette: {
      primary: {
        main: "#FFCB00",
      },
      secondary: {
        main: "#010039",
      },
      text: {
        primary: "#27272A",
      },
    },
  });

  const handleAlert = (msg = "") =>  {
    setMsg(msg);
    setType("error")
    setIsMessagePop(true)
  }

  const handleAlertSuccess = (msg = "") => {
    setMsg(msg);
    setType("success")
    setIsMessagePop(true)
  }

  const inviteConnects = (msg: string) => {

      const token = localStorage.getItem("token")
      const formData = new FormData();
      const apiEndpoint = url.baseURL + "/bx_block_different_rooms/rooms/share_room"
      const header = new Headers();
      formData.append("room_id", `${roomDetail?.id}`);
      selectedConnects.forEach((accountId: number) => formData.append("account_id[]", `${accountId}`))
      formData.append("message", msg)
      //@ts-ignore
      header.append("token", token);
  
      fetch(apiEndpoint, {
        method: 'POST',
        headers: header,
        body: formData
      }).then(response => response.json())
      .then((response: any) => {
          if(!response.error) {
            handleAlertSuccess("Sent successfully")
            setToggleFriends(false)
            setToggleMenu(false)
            setSelectedConnects([])
          } else if (response.error) {
            handleAlert(response?.error);
          } else if (response.errors) {
            handleAlert(response?.errors?.message);
          } else {
            handleAlert("Something went wrong!");
          }
      })
    }

  const fetchConnects = (searchKey: string = '') => {
    const apiEndpoint = url.baseURL + "/bx_block_request_management/requests/friends?per_page=100&page=1" + `&search=${searchKey}`
    const header = new Headers();
    const token = localStorage.getItem("token")
    //@ts-ignore
    header.append("token", token);
    header.append("Content-Type", "application/json");

    fetch(apiEndpoint, {
      method: 'GET',
      headers: header,
    }).then(response => response.json())
    .then((response: any) => {
      if (response?.data ) {
        let res = response.data.map(
          (connect: {
            attributes: {
              profile_image: false | string;
              full_name: string;
              other_user_id: number;
            };
          }) => connect.attributes
        )
        setMyConnects(res)
      }
    })
  }

  const openCloseMenu = (condition: boolean) => {
    setToggleMenu(condition)
  }

  const updateConnets = (connectId:number) => {
      if (selectedConnects.includes(connectId)) {
        setSelectedConnects((prevState) => (
          prevState.filter((connect) => connect !== connectId)
        ));
      } else {
        setSelectedConnects((prevState) => (
          [...prevState, connectId]
        ));
      }
  }

  const openCloseFriends = (condition: boolean) => {
    fetchConnects('')
    setToggleFriends(condition)
  }

  useEffect(() => {
      if (roomDetail?.attributes?.start_time) {
      const dt = parseISO(roomDetail?.attributes?.start_time);
      const time = format(dt, "hh:mm a");
      const date = format(dt, "do 'of' MMMM yyyy");
      setInviteMessage(`Suggested conversation by ${localStorage.getItem('fullName')} - You are invited for a conversation on "${roomDetail?.attributes?.topic_name}" at ${time} on ${date}. See you in YLanes!\n${roomDetail?.attributes?.share_link}`)
    }
  }, [roomDetail])

  //------------------------------------------------RENDER FUNCTIONS------------------------------------------------

  const renderPopover = () => {
    return (
      <Popover
        open={toggleMenu}
        anchorEl={anchor}
        onClose={() => {openCloseMenu(false)}}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        elevation={2}
        >
        <Box
          component={ButtonBase}
          p={2}
          width="100%"
          display="flex"
          justifyContent="space-between"
          style={{
            width: '100%',
            display: 'flex',
            padding: '16px',
            justifyContent: 'space-between',
          }}
          onClick={(ele) => {
            ele.stopPropagation();
            openCloseFriends(true)
          }}
          >
          <div style={{ display: "flex", alignItems: "center" }}>
            <GroupIcon />
            <Typography
              style={{
                fontSize: "14px",
                color: "#010039",
                marginLeft: '8px',
              }}
              >
              Share with your Connects
            </Typography>
          </div>
          <ChevronRightIcon />
        </Box>
        <Divider />
        <Box
          component={ButtonBase}
          p={2}
          width="100%"
          display="flex"
          justifyContent="space-between"
          style={{
            width: '100%',
            display: 'flex',
            padding: '16px',
            justifyContent: 'space-between',
          }}
          onClick={(ele) => {
            ele.stopPropagation();
            const dt = parseISO(roomDetail?.attributes?.start_time);
            const time = format(dt, 'hh:mma');
            const date = format(dt, 'dd/MM/yyyy');
            const shareMessage = `You are invited for a conversation on "${roomDetail?.attributes?.topic_name}" at ${time} on ${date}. Click here to register: ${roomDetail?.attributes?.share_link}. See you in YLanes!\nReferral code: ${localStorage.getItem('referral_code')}`
            navigator.clipboard.writeText(shareMessage);
            openCloseMenu(false);
          }}
          >
          <div style={{ display: "flex", alignItems: "center" }}>
            <LinkIcon />
            <Typography style={{ fontSize: "14px", color: "#010039", marginLeft: '8px' }}>
              Copy link
            </Typography>
          </div>
          <ChevronRightIcon />
        </Box>
      </Popover>
    )
  }

  const renderShareFriendsDialog = () => {
    return (
      <Dialog
      aria-labelledby="share-room-dialog"
      open={toggleFrineds}
      onClose={() => {openCloseFriends(false)}}
      maxWidth="sm"
      fullWidth
    >
      <DialogContent onClick={(e) => e.stopPropagation()} style={{ textAlign: 'center', paddingBlock: '40px' }}>
        <Box position="absolute" top="0" right="0">
          <img
            className="cancel-icon"
            onClick={() => {openCloseFriends(false)}}
            src={cancelIcon}
            alt="close dialog"
        />
        </Box>
        <Typography style={{ fontWeight: 600, fontSize: '24px', color: '#1E3354' }}>
          Share with your Connects
        </Typography>
        <Typography style={{ fontWeight: 400, fontSize: '16px', color: '#7F8E9D' }}>
          Select connects to share this room with.
        </Typography>
        <TextField
          style={{ marginBlock: '16px' }}
          id="outlined-multiline-flexible"
          label="Invitation Message"
          multiline
          disabled
          fullWidth
          variant='outlined'
          value={inviteMessage}
          maxRows={4}
        />
        <Box textAlign="left" marginTop="8px">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography style={{ fontWeight: 500, fontSize: '16px', color: '#010039' }}>
              Select Connects
            </Typography>
            <ThemeProvider theme={theme}>
              <Chip size="small" color='primary' label={selectedConnects.length} />
            </ThemeProvider>
          </Box>
          <TextField 
            placeholder="Search" 
            variant="outlined"
            fullWidth
            value={searchKey}
            onChange={(e) => {
              e.persist();
              setSearchKey(e.target.value)
            }}
            InputProps={{
              style: { borderRadius: '50px' },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <List style={{ maxHeight: '350px', marginBlock: '16px', overflowY: "auto" }} aria-label="main mailbox folders">
            {myConnects?.map((connect:any, index:number) => (
              <ListItem
                key={index}
                button
                style={{ justifyContent: 'space-between' }}
                selected={selectedConnects.includes(
                  connect.other_user_id
                )}
                onClick={() => {
                  updateConnets(connect.other_user_id)
                  // this.updateSelectedConnects(connect.other_user_id)
                }}
              >
                <Box display="flex" gridGap="8px" alignItems="center" >
                  <Avatar
                    alt={connect.full_name}
                    src={connect?.profile_image || ''}
                  >
                    {connect.full_name.charAt(0)}
                  </Avatar>
                  <Typography
                    style={{
                      fontSize: '20px',
                      fontWeight: 500,
                      color: '#010039',
                    }}
                  >
                    {connect.full_name}
                  </Typography>
                </Box>
                <Checkbox
                  checked={selectedConnects.includes(
                    connect.other_user_id
                  )}
                />
              </ListItem>
            ))}
          </List>
          <ThemeProvider theme={theme}>
            <Button 
              fullWidth 
              color='primary' 
              variant='contained' 
              disabled={!myConnects?.length}
              onClick={() => inviteConnects(inviteMessage)}
            >
              Share
            </Button>
          </ThemeProvider>
        </Box>
      </DialogContent>
    </Dialog>

    )
  }


  const renderShareIcon = () => {
    return (
      <Tooltip title="Share Room">
        <IconButton
          aria-label="share"
          size="small"
          className="d-btn"
          onClick={(e) => {
            e.preventDefault();
            openCloseMenu(!toggleMenu)
            //@ts-ignore
            setAnchor(e.currentTarget)
          }}
        >
          <ShareIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
    )
  }

   return (
    <>
    <AlertBlock
        data={{
          show: isMessagePop,
          close: () => {setIsMessagePop(false)},
          msg: msg,
          type: type,
          vertical: 'top',
          horizontal: 'center',
        }}
      />
    {renderShareIcon()}
    {renderPopover()}
    {toggleFrineds && renderShareFriendsDialog()}
    </>
   )
}

export default ShareLink