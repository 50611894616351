import React, { useEffect, useState } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import BottomSheet from '../Modals/BottomSheet.web';
import { PeopleGroup } from '../../assets';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

export const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" style={{ color: '#1E3354' }}>{children}</Typography>
        </MuiDialogTitle>
    );
});

export const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export const Timer = ({ sec, afterTimeEnd }: { sec: number, afterTimeEnd: () => void }) => {
    const [count, setCount] = useState(sec);

    useEffect(() => {
        let timer: number | undefined = undefined;

        if (count > 0) {
            timer = window.setInterval(() => {
                setCount(count => count - 1);
            }, 1000);
        } else {
            afterTimeEnd();
            window.clearInterval(timer);
        }

        return () => window.clearInterval(timer);
    }, [count]);

    return <span>{count}</span>
}

const CONSTRAINS = {
    1: {
        title: 'You seem to be alone here!',
        description: "Sorry, looks like others couldn't make it. You may leave with a full refund.",
        btn1Text: 'Leave (with full refund)',
        btn2Text: ''
    },
    2: {
        title: 'You seem to be alone here!',
        description: "Sorry, looks like others have joined and left. Would you like to wait 10 minutes for them to rejoin or leave with a full refund?",
        btn1Text: 'Wait 10 more minutes',
        btn2Text: 'Leave (with full refund)',
    },
    3: {
        title: 'Do you wish to continue?',
        description: 'It seems this was scheduled for a group. You may leave with a full refund.',
        btn1Text: 'Continue',
        btn2Text: 'Leave (with full refund)',
    },
    4: {
        title: 'Your host is missing!',
        description: 'Sorry, looks like your host has joined and left. Would you like to wait 10 minutes for them to rejoin or leave with a full refund?',
        btn1Text: 'Wait 10 more minutes',
        btn2Text: 'Leave (with full refund)',
    },
    5: {
        title: 'Your host cannot join.',
        description: "Sorry, looks like your host couldn't rejoin. You may leave with a full refund.",
        btn1Text: 'Leave (with full refund)',
        btn2Text: '',
    }
}

interface Props {
    isMobile: boolean;
    isOpen: boolean;
    handleClose: () => void;
    leaveCallForBothUser: () => void;
    leaveCall: () => void;
    handlRedirect: () => void;
    joined_users: (string | number)[];
    on_call_users: (string | number)[];
    is_host: boolean;
    host_id: string | number;
    room_type: string;
}

const LeaveRoomModal: React.FC<Props> = ({
    isOpen, isMobile, handleClose,
    leaveCall, handlRedirect, joined_users, on_call_users,
    is_host, room_type, leaveCallForBothUser, host_id
}) => {
    const total_joined_users = joined_users.length;
    const total_on_call_users = on_call_users.length;
    const isForcedLeave = sessionStorage.getItem('isForcedLeave') === 'true';

    let KEY: keyof typeof CONSTRAINS | null = null;

    if (isForcedLeave) {
        KEY = is_host ? 1 : 5;
    } else if (total_joined_users === 1) {
        KEY = 1;
    } else if (total_on_call_users === 1) {
        if (room_type !== 'Standard' && !is_host && !on_call_users.includes(host_id.toString())) {
            KEY = 4;
        } else {
            KEY = 2;
        }
    } else if (total_on_call_users === 2 && (room_type === 'Standard' || is_host)) {
        KEY = 3;
    }

    if (!KEY) return null;

    const onClose = (btnNo: 1 | 2 | null = null) => {
        if (KEY) {
            switch (KEY) {
                case 1:
                case 5:
                    leaveCall();
                    break;
                case 2:
                case 3:
                case 4:
                    if (btnNo === 1) {
                        handleClose();
                    } else if (KEY === 2) {
                        leaveCall();
                    } else {
                        leaveCallForBothUser();
                    }
                    break;
            }
        }

        console.log("btnNo", btnNo);
        sessionStorage.removeItem('isForcedLeave');
    }


    if (isOpen && isMobile) {
        const { title, description, btn1Text, btn2Text } = CONSTRAINS[KEY];

        return (
            <BottomSheet addBackDrop handleClose={onClose}>
                <Box display='flex' textAlign='center' justifyContent='center' flexDirection='column' p={2.5}>
                    <Box textAlign='center'>
                        <PeopleGroup />
                    </Box>

                    <Box textAlign='center' mt={1.5} mb={2.5}>
                        <Typography gutterBottom variant="h6" style={{ color: '#1E3354' }}>{title}</Typography>
                        <Typography style={{ color: '#7F8E9D' }}>
                            {description}
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Box onClick={() => onClose(1)} style={{
                            background: "#FFCB00", color: "#010039", borderRadius: '2rem',
                            padding: '1rem', cursor: 'pointer', fontWeight: 600
                        }}>
                            {btn1Text}
                        </Box>

                        {btn2Text &&
                            <Box mt={2} onClick={() => onClose(2)}>
                                <Typography style={{ color: '#121173', fontSize: '.9rem' }}> {btn2Text}</Typography>
                            </Box>
                        }
                    </Box>
                </Box>
            </BottomSheet>
        );
    }

    const { title, description, btn1Text, btn2Text } = CONSTRAINS[KEY];

    return (
        <Dialog onClose={() => onClose(null)} aria-labelledby="customized-dialog-title" open={isOpen}>
            <DialogTitle id='leave-room-modal-id' onClose={onClose}>
                {title}
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom style={{ color: '#7F8E9D' }}>
                    {description}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(1)} style={{ background: "#FFCB00", color: "#010039", fontWeight: 600 }}>
                    {btn1Text}
                </Button>

                {btn2Text &&
                    <Button onClick={() => onClose(2)} style={{ fontWeight: 600, color: '#121173', fontSize: '.9rem' }} variant='text'>
                        {btn2Text}
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
}

export default React.memo(LeaveRoomModal);
