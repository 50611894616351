// Customizable Area Start
import React, { FC, useEffect, useMemo, useState } from "react";
import {
  Button, Snackbar, List, ListItem,
  ListItemAvatar, ListItemText, Dialog,
  TextField, Typography, Hidden, Box, ListItemIcon
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import './RoomFeedback.css'
import {
  Circle, NoProfileImg, UserWarningIcon, Heart,
  FullCircle, FlowerImage, YlanesLogo, PeopleGroupIcon,
  Connected, SendRequest, PendingRequest
} from '../src/assets';
import FeedbackController, { Props } from "./FeedbackController";
import DropDown from "../../email-account-registration/src/EmailRegister/Dropdown.web";
import UserInput, { UserHearts } from "./UserInput.web";
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import Slide from '@material-ui/core/Slide';
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined';
import { Divider } from "react-native-elements";
import BottomSheet from "../../ThirdPartyAudioAndVideoIntergration/src/room/Modals/BottomSheet.web";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import MoreOptions, { MobileOption } from "./MoreOption.web";
import ClearIcon from '@material-ui/icons/Clear';

const VerifiedTick = ({ extraInfo, userId }: { extraInfo: any, userId: number }) => {
  return (
    <>
      {extraInfo && (extraInfo[userId].isSuperhost ||
        extraInfo[userId].isModerator) && (
          <div
            style={{
              backgroundColor: "#fff",
              borderRadius: "50%",
              height: "18px", // slightly larger than icon to ensure the background fully covers
              width: "18px",
              display: "flex",
              justifyContent: "center", // center the icon horizontally
              alignItems: "center", // center the icon vertically
              position: 'absolute',
              bottom: -4, // adjust these values to position the wrapper div as needed
              right: 6,
            }}
          >
            <CheckCircleIcon
              style={{
                height: "17px",
                width: "17px",
                color: `${extraInfo[userId].isSuperhost ? "#22bafc" : "#ffcb01"}`,
              }}
            />
          </div>
        )}
    </>

  )
}

type QSProps = {
  sendConnectionRequest: () => void;
  disconnectUser: () => void;
  revertConnectionRequest: () => void;
  handleUnBlockUser: () => void;
  status: any;
  userDetails: any;
  handleRequest: (choice: "Rejected" | "Accepted") => void;
  isOpen: boolean;
}

const QuickSendFriendRequst: React.FC<QSProps> = React.memo(({
  sendConnectionRequest, disconnectUser, revertConnectionRequest,
  status, userDetails, handleRequest, isOpen, handleUnBlockUser
}) => {
  const [show, setShow] = useState(isOpen);
  const [isMobile, setIsMobile] = useState(false);

  const handleUserClick = () => {
    if (status === "connected") disconnectUser();
    else if (status === "connect") sendConnectionRequest();
    else if (status === "pending" && !show) revertConnectionRequest();
    else if (status === "blocked") handleUnBlockUser();
  }

  useEffect(() => {
    if (window.innerWidth < 768) setIsMobile(true);
    else setIsMobile(false);
  }, [window.innerHeight]);

  useEffect(() => {
    setShow(isOpen);
  }, [isOpen]);

  const closeRequest = (choice: "Rejected" | "Accepted" | null) => {
    if (choice) {
      handleRequest(choice)
    }
    setShow(false);
  }

  let Icon: JSX.Element | null = null;

  switch (status) {
    case "connected":
      Icon = <Connected />;
      break;
    case "connect":
      Icon = <SendRequest />;
      break;
    case "pending":
      Icon = <PendingRequest />;
      break;
    case "blocked":
      Icon = <UserWarningIcon style={{ width: '1.5rem', height: '1.5rem' }} />
  }

  return (
    <>
      {show && (
        <div className="mobile-snackbar-styles" style={{ width: isMobile ? '90vw' : '25vw', borderRadius: '1.5rem' }}>
          <Box>
            <Box display='flex' alignItems='flex-start' justifyContent='space-between'>
              <Box display='flex' alignItems='center' style={{ gap: '.5rem' }}>
                <img src={userDetails.profileImage} alt=""
                  style={{ width: '2.5rem', height: '2.5rem', borderRadius: '50%', border: '1px solid #ccc' }}
                />
                <Typography style={{ textAlign: 'left', fontWeight: 500 }}>
                  New connection request from {userDetails.full_name}
                </Typography>
              </Box>

              <Box onClick={() => closeRequest(null)}>
                <CloseOutlinedIcon className="cursor" />
              </Box>
            </Box>

            <Box display='flex' alignItems='center' justifyContent='space-around' mt={1.5} style={{ gap: '.5rem' }}>
              <Box flex={1} style={{
                borderRadius: '2rem',
                background: '#FFCB00',
                color: '#010039',
                fontWeight: 600,
                padding: '.5rem 1rem'
              }} className="cursor"
                onClick={() => closeRequest('Accepted')}
              >
                Accept
              </Box>
              <Box flex={1} style={{
                borderRadius: '2rem',
                background: '#DD5E4A',
                color: '#fff',
                fontWeight: 600,
                padding: '.5rem 1rem'
              }} className="cursor"
                onClick={() => closeRequest('Rejected')}
              >
                Deny
              </Box>
            </Box>
          </Box>
        </div>
      )}

      <span className="cursor" onClick={handleUserClick}>
        {Icon}
      </span>
    </>
  )
});

class RoomFeedback extends FeedbackController {
  constructor(props: Props) {
    super(props);

  }

  StyledRating = withStyles({
    iconFilled: {
      color: '#ff6d75',
    },
    iconHover: {
      color: '#ff3d47',
    },
  })(Rating);

  handleEmoji = (newEmoji: any) => {
    this.setState({ emoji: newEmoji })
  }

  handleClickOpen = () => {
    this.setState({ openFeedBackpopup: true })
  }

  handleCloseReport = (value: string) => {
    if (!value) {
      this.setState({ openReportPopup: false });
      return;
    }
    this.setState({ reportValue: value }, () => {
      this.reportUser();
    })
  }
  handleCancel = () => {

    this.setState(prev => ({
      openSmileyPopup:
        !prev.openSmileyPopup, sorryPopUp: false,
      emoji: ''
    }))
  }
  handleCloseSmiley = (value: string) => {
    this.setState(prev => ({ openSmileyPopup: !prev.openSmileyPopup, smileyValue: value }))
  }

  handleCloseSorry = (value: string) => {
    this.setState({ sorryPopUp: false, sorryValue: value })
  }

  handleCloseRefund = (value: string) => {
    this.setState({ refundPopUp: false, refundValue: value });
    if (this.props.reloadRoom) this.props.reloadRoom();
  }

  getConnectionStatus = (userId: number) => {
    let connection_status = '';

    const status: any = this.props.allParticipants.find(
      (participant: any) => participant.account_id === userId)?.connection_status;

    if (!status) return { status: 'connect', id: null };

    if (typeof status === 'string') {
      if (status.toLowerCase() === 'reject') connection_status = 'connect';
      else connection_status = status.toLowerCase()
    }
    else {
      connection_status = status?.status;
      if (connection_status === null) {
        return { status: 'pending', id: status?.id };
      }

      connection_status = connection_status?.toLowerCase();
      if (connection_status === 'pending' &&
        String(status?.sender_id) !== localStorage.getItem("userId")) {
        return { status: connection_status, id: status?.id };
      }
    }

    return { status: connection_status, id: null };
  }

  render() {
    let feedbackArray;

    if (this.props.feedbackData instanceof Map) {
      feedbackArray = Array.from(this.props.feedbackData.values());
    } else {
      feedbackArray = this.props.feedbackData;
    }

    return (
      <>
        {/* Alert for web view */}
        <Snackbar
          open={this.state.reportAlert}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          autoHideDuration={5000}
          onClose={() => this.setState({ reportAlert: false })}
        >
          <Alert severity={this.state.alertType} color={this.state.alertType} variant="filled">
            {this.state.reportAlertMsg}
          </Alert>
        </Snackbar>

        <SmileyPopup
          selectedValue={this.state.smileyValue}
          openSmiley={this.state.openSmileyPopup}
          onClose={() => {
            this.props.history.push('/Rooms?type=my');
          }}
          openSorry={() => this.setState({ sorryPopUp: true, openSmileyPopup: false })}
          roomFeedback={this.state.roomFeedback}
          handleRoomFeedback={this.handleRoomFeedback}
          sendRoomFeedback={this.sendRoomFeedback}
          history={this.props.history}
          handleEmoji={this.handleEmoji}
          emoji={this.state.emoji}
          prevReaction={this.state.prevRoomReaction}
          closeSmiley={() => {
            const wantSelfLeave = JSON.parse(sessionStorage.getItem('self_leave') as string);
            if (!wantSelfLeave) return;

            if (new Date < new Date(this.props.endTime)) {
              this.setState({ openSmileyPopup: false });
              this.props.handleLeave(false);
            }
          }}
          isFeedbackSending={this.state.isFeedbackSending}
          isMobile={this.props.isMobile}
        />

        <SorryPopup
          selectedValue={this.state.sorryValue}
          openSorry={this.state.sorryPopUp}
          onClose={this.handleCloseSorry}
          openRefund={() => this.setState({ refundPopUp: true, sorryPopUp: false })}
          sadFeedback={this.state.sadFeedback}
          handleSadFeedback={this.handleSadFeedback}
          wantRefund={this.state.needRefund}
          handleWantRefund={this.handleWantRefund}
          sendRoomFeedback={this.sendRoomFeedback}
          handleCancel={this.handleCancel}
          history={this.props.history}
          isModeratorRoom={this.props.isModeratorRoom}
          isHost={this.props.isHost}
          showSnackbar={this.showSnackbar}
          isMobile={this.props.isMobile}
        />

        {this.state.refundPopUp && (
          <RefundPopup
            selectedValue={this.state.refundValue}
            openRefund={this.state.refundPopUp}
            onClose={this.handleCloseRefund}
            history={this.props.history}
            needRefund={this.state.needRefund}
            isMobile={this.props.isMobile}
            bulletPoints={this.state.bulletPoints}
          />
        )}

        {!this.props.isMobile && (
          <Dialog
            onClose={this.handleRoomEndModalClose}
            style={{ backdropFilter: "blur(4px)", borderRadius: "16px", }}
            aria-labelledby="simple-dialog-title"
            open={this.state.openFeedBackpopup}
            PaperProps={{
              style: { borderRadius: 16, maxWidth: '450px' },
            }}
          >
            <Box style={{ padding: "1rem 2rem", minWidth: '25vw' }}>

              {/* Heading & Close button */}
              <Box position='relative'>
                <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column' pt={2}>
                  <Typography style={{ fontWeight: 600, fontSize: '1.5rem', color: '#1E3354' }}>Room Ended</Typography>
                  <Typography style={{ color: '#7F8E9D' }}>Gift hearts to your roommates</Typography>
                </Box>

                <Box py={1.5} px={2} position='absolute' right={5} top={5}>
                  <ClearIcon onClick={this.handleRoomEndModalClose} style={{ color: '#27272A', cursor: 'pointer' }} />
                </Box>
              </Box>

              {/* Users Lists  */}
              <List style={{ marginTop: '1rem' }} className="particiapnt-list">
                {this.props.feedbackData &&
                  feedbackArray?.map((user: any) => {
                    const { hearts, feedback } = this.getPastHearts(user.userId);
                    const { status, id } = this.getConnectionStatus(user.userId);

                    return (
                      <Box key={user.userId}>
                        <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          {/* User Details  */}
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* User Profile Pictyre */}
                            <ListItemAvatar style={{ minWidth: "45px", position: 'relative' }}>
                              <>
                                <img
                                  src={(user.profileImage && !user.is_anonymously) ? user.profileImage : NoProfileImg}
                                  alt="user_img"
                                  style={{
                                    height: "35px",
                                    width: "35px",
                                    objectFit: 'cover',
                                    borderRadius: '50%',
                                    border: '1px solid #ccc'
                                  }}
                                />

                                {/* User verified Tick Information */}
                                <VerifiedTick extraInfo={this.state.extraInfo} userId={user.userId} />
                              </>
                            </ListItemAvatar>


                            <Box>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                {/* User Name */}
                                <p style={{
                                  color: '#262556', fontWeight: 500, textTransform: 'capitalize'
                                }}> {user.is_anonymously ? user.anonymously_name : user.full_name}</p>

                                {/* Heart Count */}
                                <img src={Heart} style={{ width: '15px', margin: '0 .5rem' }} />
                                <span style={{ fontSize: '15px' }}>{this.state.extraInfo ? this.state.extraInfo[user.userId].hearts : "..."} </span>

                                {/* Host Badge */}
                                {user.isHost &&
                                  <span className="feedback-participant-host"
                                    style={{
                                      fontSize: "12px",
                                      marginLeft: "10px",
                                      fontWeight: 600,
                                      background: '#ffcb00',
                                      padding: '.1rem .3rem',
                                      borderRadius: '.5rem'
                                    }}
                                  >
                                    HOST
                                  </span>
                                }
                              </div>

                              {/* Hearts */}
                              <UserHearts
                                defaultHeartValue={hearts || parseInt(user?.hearts || 0)}
                                handleHeartInput={this.handleHeartInput}
                                id={user.userId}
                                key={user.userId}
                              />
                            </Box>
                          </div>

                          <Box display='flex' alignItems='center' justifyContent='center' style={{ gap: '1rem' }}>
                            {/* quick send friend request option  */}
                            <QuickSendFriendRequst
                              sendConnectionRequest={() => {
                                this.setState({ selectedUserID: user.userId, isSelectedUserAnonymous: user.is_anonymously });
                                this.props.sendConnectionRequest(user.userId);
                              }}
                              disconnectUser={() => {
                                this.setState({ selectedUserID: user.userId, isSelectedUserAnonymous: user.is_anonymously });
                                this.toggleDisconnectModal();
                              }}
                              revertConnectionRequest={() => {
                                this.setState({ selectedUserID: user.userId, isSelectedUserAnonymous: user.is_anonymously });
                                this.props.revertConnectionRequest(user.userId);
                              }}
                              handleUnBlockUser={() => {
                                this.setState({ selectedUserID: user.userId, isSelectedUserAnonymous: user.is_anonymously });
                                this.toggleUnBlockModal();
                              }}
                              status={status}
                              userDetails={user}
                              handleRequest={(choice) => this.handleConnectRequest(choice, id)}
                              isOpen={!!id}
                            />

                            {/* More Options */}
                            <MoreOptions
                              openReportModal={() => this.setState({
                                reportId: user.userId, openReportPopup: true, selectedUserID: user.userId, isSelectedUserAnonymous: user.is_anonymously
                              })}
                              sendFriendRequest={() => {
                                this.setState({ selectedUserID: user.userId, isSelectedUserAnonymous: user.is_anonymously });
                                this.props.sendConnectionRequest(user.userId);
                              }}
                              openBlockModal={() => {
                                this.setState({ selectedUserID: user.userId, isSelectedUserAnonymous: user.is_anonymously }, () => {
                                  this.toggleBlockModal();
                                });
                              }}
                              openUnBlockModal={() => {
                                this.setState({ selectedUserID: user.userId, isSelectedUserAnonymous: user.is_anonymously }, () => {
                                  this.toggleBlockModal();
                                });
                              }}
                              is_anonymously={user.is_anonymously || this.props.currentUser?.is_anonymously}
                              is_already_connected={status?.toLowerCase() === "connected"}
                              status={status}
                            />
                          </Box>
                        </div>

                        <Box pt={1}>
                          <UserInput
                            user={user}
                            prevFeedback={feedback}
                            handleFeedbackInput={this.handleFeedbackInput}
                            isMobile={this.props.isMobile}
                          />
                        </Box>
                      </Box>
                    )
                  })}
              </List>

              {/* Submit button */}
              <Box mb={1.6} mt={4}>

                <Button
                  onClick={this.handleRoomEndSubmit}
                  style={{
                    width: "100%",
                    background: '#FFCB00',
                    color: "#101038",
                    borderRadius: "10px",
                    padding: "10px"
                  }}
                >
                  SUBMIT
                </Button>

              </Box>
            </Box>
          </Dialog >
        )
        }

        {
          (this.props.isMobile && this.state.openFeedBackpopup) && (
            <Hidden smUp>
              <BottomSheet addBackDrop handleClose={this.handleRoomEndModalClose}>
                <Slide direction="up" in={true}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    maxHeight={'65vh'}
                    zIndex={1300}
                    flex={1}
                    bgcolor="#fff"
                    style={{
                      borderTopLeftRadius: 20,
                      borderTopRightRadius: 20,
                    }}
                  >
                    <div style={{ height: '90%', width: '100%' }}>
                      <Box style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        right: 10,
                        top: 15,
                      }}
                        onClick={this.handleRoomEndModalClose}
                      >
                        <CloseIcon />
                      </Box>

                      <Box textAlign='center' sx={{ pb: 1 }}>
                        <Typography style={{ fontWeight: 500, fontSize: '1.5rem' }}>Room Ended</Typography>

                        <span className="feedback-span" style={{
                          color: "#c1c1c1",
                          fontWeight: 500,
                          fontSize: "18px",
                          marginTop: this.props.isMobile ? '.5rem' : 0,
                        }}>
                          Gift hearts to your roommate
                        </span>
                      </Box>

                      <List style={{ height: '86%', overflow: 'auto' }} className="particiapnt-list">
                        {this.props.feedbackData &&
                          feedbackArray?.map((user: any) => {
                            const { hearts, feedback } = this.getPastHearts(user.userId);
                            const { status, id } = this.getConnectionStatus(user.userId);

                            return (
                              <Box key={user.userId}>
                                <ListItem>
                                  <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    {/* User Details  */}
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      {/* User Profile Pictyre */}
                                      <ListItemAvatar style={{ minWidth: "45px", position: 'relative' }}>
                                        <>
                                          <img
                                            src={(user.profileImage && !user.is_anonymously) ? user.profileImage : NoProfileImg}
                                            alt="user_img"
                                            style={{
                                              height: "35px",
                                              width: "35px",
                                              objectFit: 'cover',
                                              borderRadius: '50%',
                                              border: '1px solid #ccc'
                                            }}
                                          />

                                          {/* User verified Tick Information */}
                                          <VerifiedTick extraInfo={this.state.extraInfo} userId={user.userId} />
                                        </>
                                      </ListItemAvatar>

                                      <Box>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                          {/* User Name */}
                                          <p style={{
                                            color: '#262556', fontWeight: 500, textTransform: 'capitalize'
                                          }}> {user.is_anonymously ? user.anonymously_name : user.full_name}</p>

                                          {/* Heart Count */}
                                          <img src={Heart} style={{ width: '15px', margin: '0 .5rem' }} />
                                          <span style={{ fontSize: '15px' }}>{this.state.extraInfo ? this.state.extraInfo[user.userId].hearts : "..."} </span>

                                          {/* Host Badge */}
                                          {user.isHost &&
                                            <span className="feedback-participant-host"
                                              style={{
                                                fontSize: "10px",
                                                marginLeft: "10px",
                                                fontWeight: 600,
                                                background: '#ffcb00',
                                                padding: '.1rem .3rem',
                                                borderRadius: '.5rem'
                                              }}
                                            >
                                              HOST
                                            </span>
                                          }
                                        </div>

                                        {/* Hearts */}
                                        <UserHearts
                                          defaultHeartValue={hearts || parseInt(user.hearts)}
                                          handleHeartInput={this.handleHeartInput}
                                          id={user.userId}
                                          key={user.userId}
                                        />
                                      </Box>

                                    </div>

                                    <Box display='flex' alignItems='center' justifyContent='center' style={{ gap: '1rem' }}>
                                      {/* quick send friend request option  */}
                                      <QuickSendFriendRequst
                                        sendConnectionRequest={() => {
                                          this.setState({ selectedUserID: user.userId, isSelectedUserAnonymous: user.is_anonymously });
                                          this.props.sendConnectionRequest(user.userId);
                                        }}
                                        disconnectUser={() => {
                                          this.setState({ selectedUserID: user.userId, isSelectedUserAnonymous: user.is_anonymously });
                                          this.toggleDisconnectModal();
                                        }}
                                        handleUnBlockUser={() => {
                                          this.setState({ selectedUserID: user.userId, isSelectedUserAnonymous: user.is_anonymously });
                                          this.toggleUnBlockModal();
                                        }}
                                        revertConnectionRequest={() => {
                                          this.setState({ selectedUserID: user.userId, isSelectedUserAnonymous: user.is_anonymously });
                                          this.props.revertConnectionRequest(user.userId);
                                        }}
                                        status={status}
                                        userDetails={user}
                                        handleRequest={(choice) => this.handleConnectRequest(choice, id)}
                                        isOpen={!!id}
                                      />

                                      {/* More Options */}
                                      <div className="cursor" onClick={() => {
                                        this.setState({
                                          selectedUserID: user.userId,
                                          isSelectedUserAnonymous: user.is_anonymously,
                                          status,
                                        }, () => {
                                          this.toggleMoreOptions();
                                        });
                                      }}>
                                        <MoreVertIcon style={{ fill: '#7f7f7f' }} />
                                      </div>
                                    </Box>

                                  </div>
                                </ListItem>

                                <Box px={2} py={1}>
                                  <UserInput
                                    user={user}
                                    prevFeedback={feedback}
                                    handleFeedbackInput={this.handleFeedbackInput}
                                    isMobile={this.props.isMobile}
                                  />
                                </Box>
                              </Box>
                            )
                          })}
                      </List>

                      <Box textAlign='center'>
                        <Divider />

                        <div
                          style={{
                            background: '#FFCB00',
                            color: "#101038",
                            padding: '1rem',
                            margin: '2rem 1rem 2.5rem',
                            borderRadius: ' 0.5rem',
                          }}
                          onClick={this.handleRoomEndSubmit}
                        >
                          SUBMIT
                        </div>
                      </Box>
                    </div>
                  </Box>
                </Slide>
              </BottomSheet>
            </Hidden >
          )
        }

        {/* More Options Modal */}
        {
          (this.props.isMobile && this.state.isMoreOptionsOpen) && (
            <BottomSheet addBackDrop handleClose={this.toggleMoreOptions}>
              <Box pt={1.5} pl={4} pr={4}>
                {/* {(this.state.status !== 'connected' &&
                  (!this.state.isSelectedUserAnonymous || this.props.currentUser?.is_anonymously)) && (
                    <MobileOption
                      handleClick={() => this.props.sendConnectionRequest(this.state.selectedUserID as number)}
                      title="Send connection request"
                      children={<PersonAddOutlinedIcon style={{ color: '#010039', marginRight: '.7rem' }} />}
                      type="info"
                      key={'send-friend-request'}
                    />
                  )} */}

                <MobileOption
                  handleClick={() => this.setState({ reportId: this.state.selectedUserID as number, openReportPopup: true })}
                  title="Report User"
                  children={<ReportOutlinedIcon style={{ color: '#DD5E4A', marginRight: '.7rem' }} />}
                  type="alert"
                  showForwardIcon
                  key={'report-user'}
                />

                {this.state.status !== 'blockedby' &&
                  <MobileOption
                    handleClick={() => this.setState({
                      reportId: this.state.selectedUserID as number,
                      openBlockModal: this.state.status !== 'blocked' ? true : false,
                      openUnBlockModal: this.state.status === 'blocked' ? true : false
                    })}
                    title={this.state.status === 'blocked' ? 'Unblock user' : "Block User"}
                    children={<UserWarningIcon style={{ color: '#DD5E4A', marginRight: '.7rem', width: '1.3rem', height: '1.3rem' }} />}
                    type="alert"
                    showForwardIcon
                    key={'block-user'}
                  />}

              </Box>
            </BottomSheet>
          )
        }

        <ReportPopup
          selectedValue={this.state.reportValue}
          open={this.state.openReportPopup}
          onClose={this.handleCloseReport}
          history={this.props.history}
          isMobile={this.props.isMobile}
          openBlockModal={this.toggleBlockModal}
          openUnBlockModal={this.toggleUnBlockModal}
          status={this.state.status}
        />

        <ThankYourForFeedback
          onClose={this.toggleThankYouModal}
          open={this.state.openThankYouFeedbackModal.isOpen}
          openBlockModal={this.toggleBlockModal}
          openUnBlockModal={this.toggleUnBlockModal}
          status={this.state.status}
          message={this.state.openThankYouFeedbackModal.text}
          isMobile={this.props.isMobile}
        />

        <BlockUserModal
          onClose={this.toggleBlockModal}
          open={this.state.openBlockModal}
          onBlock={this.handleblockUnBlockUser}
          userDetails={Array.from(this.props.feedbackData?.values()).filter((each: any) => each.userId === this.state.selectedUserID)[0]}
          extraInfo={this.state.extraInfo}
          isMobile={this.props.isMobile}
        />

        <UnBlockUserModal
          onClose={this.toggleUnBlockModal}
          open={this.state.openUnBlockModal}
          onBlock={this.handleblockUnBlockUser}
          userDetails={Array.from(this.props.feedbackData?.values()).filter((each: any) => each.userId === this.state.selectedUserID)[0]}
          extraInfo={this.state.extraInfo}
          isMobile={this.props.isMobile}
        />

        <DisconnectUserModal
          onClose={this.toggleDisconnectModal}
          open={this.state.openDisconnectModal}
          onBlock={this.handleDisconnect} // onBlock is used for disconnecting user
          userDetails={Array.from(this.props.feedbackData?.values()).filter((each: any) => each.userId === this.state.selectedUserID)[0]}
          extraInfo={this.state.extraInfo}
          isMobile={this.props.isMobile}
        />
      </>
    );
  }
}

const report_types = [
  "Offensive language or gesture",
  "Arguing/ debating/ ridiculing",
  "Harassing/ bullying",
  "Curious onlooker with no relevance to the topic"
];

const ReportMainContent = (props: any) => {
  const { onClose, openBlockModal, isMobile, status, openUnBlockModal } = props;

  const handleListItemClick = (value: string) => {
    onClose(value);
  };

  return (
    <>
      {/* Heading & Close button */}
      <Box position='relative' style={{ padding: '0 2rem' }}>
        <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column' pt={2} textAlign='center'>
          <Typography style={{ fontWeight: 600, fontSize: '1.5rem', color: '#1E3354' }}>Report</Typography>
          <Typography style={{ color: '#7F8E9D', width: isMobile ? '70%' : '100%' }}>Describe why you want to report this user</Typography>
        </Box>

        {!isMobile && (
          <Box py={1.5} px={2} position='absolute' right={5} top={5}>
            <ClearIcon onClick={() => onClose(null)} style={{ color: '#27272A', cursor: 'pointer' }} />
          </Box>
        )}
      </Box>

      {/* Reports Type */}
      <Box mt={3}>
        <List>
          {report_types.map((email) => (
            <ListItem
              style={{
                padding: '1rem 2rem',
              }}
              onClick={() => handleListItemClick(email)}
              key={email}
              className="report-list-item"
            >
              <ListItemText
                primary={email}
                primaryTypographyProps={{
                  style: {
                    color: '#010039',
                    fontWeight: 500,
                    fontSize: '1rem'
                  }
                }}
              />
              <ListItemIcon style={{ minWidth: '2rem' }}>
                <ChevronRightOutlinedIcon style={{ color: '#27272A' }} />
              </ListItemIcon>
            </ListItem>
          ))}
        </List>

        {/* block user option */}
        {status !== 'blockedby' && (
          <ListItem
            style={{
              padding: '0 2rem 1rem 2rem',
            }}
            onClick={() => {
              if (status === 'blocked') openUnBlockModal();
              else openBlockModal();
            }}
            className="report-list-item"
          >
            <ListItemIcon style={{ minWidth: '2rem' }}>
              <UserWarningIcon style={{ color: '#DD5E4A', marginRight: '.7rem', width: '1.5rem', height: '1.5rem' }} />
            </ListItemIcon>
            <ListItemText
              primary={status === 'blocked' ? 'Unblock user' : "Block User"}
              primaryTypographyProps={{
                style: {
                  color: '#DD5E4A',
                  fontWeight: 500,
                  fontSize: '1rem'
                }
              }}
              secondary={'You and this user will no longer be able to join the same rooms, connect, or chat'}
              secondaryTypographyProps={{
                style: {
                  color: '#7F8E9D',
                  fontSize: '.7rem'
                }
              }}
            />
            <ListItemIcon style={{ minWidth: '2rem' }}>
              <ChevronRightOutlinedIcon style={{ color: '#DD5E4A' }} />
            </ListItemIcon>
          </ListItem>
        )}
      </Box>
    </>
  )
}

export function ReportPopup(props: any) {
  const { onClose, open, isMobile } = props;


  if (open && isMobile) {
    return (
      <BottomSheet addBackDrop handleClose={() => onClose(null)}>
        <Box>
          <ReportMainContent {...props} />
        </Box>
      </BottomSheet>
    )
  }

  return (
    <Dialog
      style={{ backdropFilter: "blur(4px)", paddingBottom: "0px" }}
      open={open}
      onClose={() => onClose(null)}
      PaperProps={{
        style: { borderRadius: 16, maxWidth: '450px' },
      }}
    >
      <Box style={{ padding: "1rem 0", maxWidth: '25vw' }}>
        <ReportMainContent {...props} />
      </Box>
    </Dialog >
  )
}

interface ModalProps {
  onClose: () => void;
  open: boolean;
  isMobile: boolean;
}

interface ThankYouProps extends ModalProps {
  openBlockModal: () => void;
  openUnBlockModal: () => void;
  status: string;
  message: string;
}

const ThankYouMainContent = React.memo((props: ThankYouProps) => {
  const { onClose, openBlockModal, message, isMobile, openUnBlockModal, status } = props;

  return (
    <>
      <Box position='relative' style={{ padding: '0 2rem' }}>
        <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column' pt={2}>
          <PeopleGroupIcon />
        </Box>

        {!isMobile && (
          <Box py={1.5} px={2} position='absolute' right={5} top={5}>
            <ClearIcon onClick={onClose} style={{ color: '#27272A', cursor: 'pointer' }} />
          </Box>
        )}
      </Box>

      <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column' textAlign='center' my={1}>
        <Box mb={1}>
          <Typography style={{ color: '#1E3354', fontWeight: 600, fontSize: '1.5rem' }}>Thank you for your feedback</Typography>
        </Box>
        <Typography style={{ color: '#7F8E9D', fontWeight: 500 }}>
          {message}
        </Typography>
      </Box>

      <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column' mb={2}>
        <Box style={{
          width: '100%',
          color: '#010039',
          background: '#FFCB00',
          borderRadius: isMobile ? '3rem' : '.5rem',
          margin: '1rem 0',
          cursor: 'pointer',
          height: '3rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontWeight: 500
        }}
          onClick={onClose}
        >
          Continue
        </Box>
        {status !== 'blockedby' && (
          <Box style={{
            width: '100%',
            color: '#fff',
            background: '#DD5E4A',
            borderRadius: isMobile ? '3rem' : '.5rem',
            cursor: 'pointer',
            height: '3rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 500
          }}
            onClick={() => {
              if (status === 'blocked') openUnBlockModal();
              else openBlockModal();
            }}
          >
            {status === 'blocked' ? 'Unblock User' : 'Block User'}
          </Box>
        )}
      </Box>
    </>
  )
});

const ThankYourForFeedback = (props: ThankYouProps) => {
  const { onClose, open, isMobile } = props;

  if (isMobile && open) {
    return (
      <BottomSheet handleClose={onClose} addBackDrop>
        <Box style={{ padding: "1rem 2rem" }}>
          <ThankYouMainContent {...props} />
        </Box>
      </BottomSheet>
    )
  }

  return (
    <Dialog
      style={{ backdropFilter: "blur(4px)", paddingBottom: "0px" }}
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { borderRadius: 16, maxWidth: '450px' },
      }}
    >
      <Box style={{ padding: "1rem 2rem", maxWidth: '25vw' }}>
        <ThankYouMainContent {...props} />
      </Box>
    </Dialog>
  )
}

const BlockMainContent = React.memo((props: BlockModalProps) => {
  const { onClose, onBlock, userDetails, extraInfo, isMobile } = props;
  const { is_anonymously, full_name, anonymously_name, userId, profileImage } = userDetails;
  const user = extraInfo[userId];

  if (!user) return null;

  return (
    (
      <>
        <Box position='relative' style={{ padding: '0 2rem' }} mb={1.5}>
          <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column' textAlign='center'>
            <Box mb={1}>
              <Typography style={{ color: '#1E3354', fontWeight: 600, fontSize: '1.5rem' }}>Are you sure you want to block this user?</Typography>
            </Box>
            <Typography style={{ color: '#7F8E9D', fontWeight: 500 }}>
              You and this user will no longer be able to join the same rooms, connect, or chat
            </Typography>
          </Box>

          {!isMobile && (
            <Box pb={1.5} position='absolute' right={0} top={0}>
              <ClearIcon onClick={onClose} style={{ color: '#27272A', cursor: 'pointer' }} />
            </Box>
          )}
        </Box>

        <Box display='flex' justifyContent='center'>
          <Box display='flex' alignItems='center' justifyContent='center' p={1.6} my={1} minWidth={isMobile ? '75%' : '60%'}
            style={{
              border: '1px solid #E0E0E0',
              boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
              borderRadius: '1.2rem'
            }}>
            <Box>
              <img
                src={(is_anonymously || !profileImage) ? NoProfileImg : profileImage}
                alt="user_img"
                style={{ borderRadius: '50%', objectFit: 'cover', width: '48px', height: '48px' }}
              />

              {/* user verified tick  */}
            </Box>
            <Box ml={1.5}>
              <Typography style={{ color: '#010039', fontWeight: 600 }}>{is_anonymously ? anonymously_name : full_name}</Typography>
              <Box display='flex' alignItems='center' justifyContent='center' style={{ gap: '.5rem' }}>
                <FavoriteOutlinedIcon style={{ color: '#FFCB00', width: '1.2rem', height: '1.2rem' }} />
                <Typography style={{ color: '#979797' }}>{user?.hearts || 0}</Typography>
                <Typography style={{ color: '#979797' }}>
                  {user?.gender} ({user?.age_range[0]}-{user?.age_range[1]} yrs)
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column' my={2} mx={isMobile ? 2 : 0}>
          <Box style={{
            width: '100%',
            color: '#fff',
            background: '#DD5E4A',
            borderRadius: isMobile ? '3rem' : '.5rem',
            cursor: 'pointer',
            height: '3rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 500
          }} mb={1.5} onClick={() => onBlock(userId)}>
            Block User
          </Box>

          <Box onClick={onClose} className="po">
            <Typography style={{ color: '#010039', textDecoration: isMobile ? 'none' : 'underline', cursor: 'pointer' }}>Cancel</Typography>
          </Box>
        </Box>
      </>
    )
  )
});

const UnBlockMainContent = React.memo((props: BlockModalProps) => {
  const { onClose, onBlock, userDetails, extraInfo, isMobile } = props;
  const { is_anonymously, full_name, anonymously_name, userId, profileImage } = userDetails;
  const user = extraInfo[userId];

  if (!user) return null;

  return (
    (
      <>
        <Box position='relative' style={{ padding: '0 2rem' }} mb={1.5}>
          <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column' textAlign='center'>
            <Box mb={1}>
              <Typography style={{ color: '#1E3354', fontWeight: 600, fontSize: '1.5rem' }}>Are you sure you want to unblock this user?</Typography>
            </Box>
            <Typography style={{ color: '#7F8E9D', fontWeight: 500 }}>
              You and this user will able to be join the same rooms, connect, or chat.
            </Typography>
          </Box>

          {!isMobile && (
            <Box pb={1.5} position='absolute' right={0} top={0}>
              <ClearIcon onClick={onClose} style={{ color: '#27272A', cursor: 'pointer' }} />
            </Box>
          )}
        </Box>

        <Box display='flex' justifyContent='center'>
          <Box display='flex' alignItems='center' justifyContent='center' p={1.6} my={1} minWidth={isMobile ? '75%' : '60%'}
            style={{
              border: '1px solid #E0E0E0',
              boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
              borderRadius: '1.2rem'
            }}>
            <Box>
              <img
                src={(is_anonymously || !profileImage) ? NoProfileImg : profileImage}
                alt="user_img"
                style={{ borderRadius: '50%', objectFit: 'cover', width: '48px', height: '48px' }}
              />

              {/* user verified tick  */}
            </Box>
            <Box ml={1.5}>
              <Typography style={{ color: '#010039', fontWeight: 600 }}>{is_anonymously ? anonymously_name : full_name}</Typography>
              <Box display='flex' alignItems='center' justifyContent='center' style={{ gap: '.5rem' }}>
                <FavoriteOutlinedIcon style={{ color: '#FFCB00', width: '1.2rem', height: '1.2rem' }} />
                <Typography style={{ color: '#979797' }}>{user?.hearts || 0}</Typography>
                <Typography style={{ color: '#979797' }}>
                  {user?.gender} ({user?.age_range[0]}-{user?.age_range[1]} yrs)
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column' my={2} mx={isMobile ? 2 : 0}>
          <Box style={{
            width: '100%',
            color: '#fff',
            background: '#DD5E4A',
            borderRadius: isMobile ? '3rem' : '.5rem',
            cursor: 'pointer',
            height: '3rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 500
          }} mb={1.5} onClick={() => onBlock(userId)}>
            Unblock User
          </Box>

          <Box onClick={onClose} className="po">
            <Typography style={{ color: '#010039', textDecoration: isMobile ? 'none' : 'underline', cursor: 'pointer' }}>Cancel</Typography>
          </Box>
        </Box>
      </>
    )
  )
});

interface BlockModalProps extends ModalProps {
  userDetails: any;
  extraInfo: any;
  onBlock: (userId: number) => void;
}

const BlockUserModal = (props: BlockModalProps) => {
  const { onClose, open, isMobile } = props;

  if (!open) return null;


  if (isMobile && open) {
    return (
      <BottomSheet handleClose={onClose} addBackDrop>
        <Box mt={1} mb={2}>
          <BlockMainContent {...props} />
        </Box>
      </BottomSheet>
    )
  }

  return (
    <Dialog
      style={{ backdropFilter: "blur(4px)", paddingBottom: "0px" }}
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { borderRadius: 16, maxWidth: '450px' },
      }}
    >
      <Box style={{ padding: "1rem 2rem", maxWidth: '30vw' }}>
        <BlockMainContent {...props} />
      </Box>
    </Dialog>
  )
}

const UnBlockUserModal = (props: BlockModalProps) => {
  const { onClose, open, isMobile } = props;

  if (!open) return null;


  if (isMobile && open) {
    return (
      <BottomSheet handleClose={onClose} addBackDrop>
        <Box mt={1} mb={2}>
          <UnBlockMainContent {...props} />
        </Box>
      </BottomSheet>
    )
  }

  return (
    <Dialog
      style={{ backdropFilter: "blur(4px)", paddingBottom: "0px" }}
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { borderRadius: 16, maxWidth: '450px' },
      }}
    >
      <Box style={{ padding: "1rem 2rem", maxWidth: '30vw' }}>
        <UnBlockMainContent {...props} />
      </Box>
    </Dialog>
  )
}
interface MEProps {
  emoji: string;
  onClick: () => void;
  isActive: boolean;
}

const MobileEmoji: FC<MEProps> = ({ emoji, onClick, isActive }) => {
  return (
    <Box
      onClick={onClick}
      style={{
        fontSize: '2rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        border: `2px solid ${isActive ? 'rgba(255, 203, 0, 1)' : '#ccc'}`,
        padding: '1rem',
        width: '3rem',
        height: '3rem',
        background: isActive ? 'rgba(255, 203, 0, .3)' : '',
        cursor: 'pointer'
      }}>
      {emoji}
    </Box>
  )
}

const DisconnectMainContent = React.memo((props: BlockModalProps) => {
  const { onClose, onBlock, userDetails, extraInfo, isMobile } = props;
  const { is_anonymously, full_name, anonymously_name, userId, profileImage } = userDetails;
  const user = extraInfo[userId];

  if (!user) return null;

  return (
    (
      <>
        <Box position='relative' style={{ padding: '0 2rem' }} mb={1.5}>
          <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column' textAlign='center'>
            <Box mb={1}>
              <Typography style={{ color: '#1E3354', fontWeight: 600, fontSize: '1.5rem' }}>Are you sure you want to disconnect with this user?</Typography>
            </Box>
            <Typography style={{ color: '#7F8E9D', fontWeight: 500 }}>
              You will no longer be able to chat or see rooms they will attend, unless you are also registered for that room.
            </Typography>
          </Box>

          {!isMobile && (
            <Box pb={1.5} position='absolute' right={0} top={0}>
              <ClearIcon onClick={onClose} style={{ color: '#27272A', cursor: 'pointer' }} />
            </Box>
          )}
        </Box>

        <Box display='flex' justifyContent='center'>
          <Box display='flex' alignItems='center' justifyContent='center' p={1.6} my={1} minWidth={isMobile ? '75%' : '60%'}
            style={{
              border: '1px solid #E0E0E0',
              boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
              borderRadius: '1.2rem'
            }}>
            <Box>
              <img
                src={(is_anonymously || !profileImage) ? NoProfileImg : profileImage}
                alt="user_img"
                style={{ borderRadius: '50%', objectFit: 'cover', width: '48px', height: '48px' }}
              />

              {/* user verified tick  */}
            </Box>
            <Box ml={1.5}>
              <Typography style={{ color: '#010039', fontWeight: 600 }}>{is_anonymously ? anonymously_name : full_name}</Typography>
              <Box display='flex' alignItems='center' justifyContent='center' style={{ gap: '.5rem' }}>
                <FavoriteOutlinedIcon style={{ color: '#FFCB00', width: '1.2rem', height: '1.2rem' }} />
                <Typography style={{ color: '#979797' }}>{user?.hearts || 0}</Typography>
                <Typography style={{ color: '#979797' }}>
                  {user?.gender} ({user?.age_range[0]}-{user?.age_range[1]} yrs)
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column' my={2} mx={isMobile ? 2 : 0}>
          <Box style={{
            width: '100%',
            color: '#fff',
            background: '#DD5E4A',
            borderRadius: isMobile ? '3rem' : '.5rem',
            cursor: 'pointer',
            height: '3rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 500
          }} mb={1.5} onClick={() => onBlock(userId)}>
            Disconnect
          </Box>

          <Box onClick={onClose} className="po">
            <Typography style={{ color: '#010039', textDecoration: isMobile ? 'none' : 'underline', cursor: 'pointer' }}>Cancel</Typography>
          </Box>
        </Box>
      </>
    )
  )
});

const DisconnectUserModal = (props: BlockModalProps) => {
  const { onClose, open, isMobile } = props;

  if (!open) return null;


  if (isMobile && open) {
    return (
      <BottomSheet handleClose={onClose} addBackDrop>
        <Box mt={1} mb={2}>
          <DisconnectMainContent {...props} />
        </Box>
      </BottomSheet>
    )
  }

  return (
    <Dialog
      style={{ backdropFilter: "blur(4px)", paddingBottom: "0px" }}
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { borderRadius: 16, maxWidth: '450px' },
      }}
    >
      <Box style={{ padding: "1rem 2rem", maxWidth: '30vw' }}>
        <DisconnectMainContent {...props} />
      </Box>
    </Dialog>
  )
}

const SmileyPopUpMainContent = (props: any) => {
  const { openSorry, closeSmiley, handleRoomFeedback,
    roomFeedback, sendRoomFeedback, handleEmoji,
    emoji, prevReaction, isFeedbackSending
  } = props;
  const [selectedEmoji, setSelectedEmoji] = useState("");
  const [_emoji, setEmoji] = useState(emoji);

  const handleLocalEmoji = (emoji: string) => {
    setSelectedEmoji(emoji);
    handleEmoji(emoji);
    setEmoji(emoji);
  }

  useEffect(() => {
    setEmoji(prevReaction);
  }, [prevReaction]);

  useEffect(() => {
    if (!emoji) {
      handleLocalEmoji('');
    }
  }, [emoji]);

  const remaining_chars = 250 - roomFeedback.length;

  return (
    <>
      <Box display='flex' alignItems='center' style={{ cursor: 'pointer' }}
        onClick={closeSmiley}
      >
        <Box flex={1} className="drag-handle" height='2rem' width='100%' />
        <Box sx={{ pt: 2, pr: 2 }}>
          <CloseIcon />
        </Box>
      </Box>

      <Box>
        {/* Headings */}
        <Box textAlign='center'
          // padding='0 3rem 1rem 3rem'
          padding='0 4rem 1rem'
        >
          <Typography style={{ fontWeight: 600, fontSize: '1.3rem', marginBottom: '.5rem' }}>
            Thank you for your feedback
          </Typography>
          <span style={{ color: '#7F8E9D', padding: '10px' }}>How would you like to rate the conversation?</span>
        </Box>

        {/* Emojis */}
        <Box display='flex' alignItems='center' justifyContent='center' style={{ gap: '1.5rem' }}>
          <MobileEmoji
            emoji="😔"
            isActive={_emoji === "sad"}
            onClick={() => {
              handleLocalEmoji("sad");
              openSorry();
            }}
          />
          <MobileEmoji
            emoji="🙂"
            isActive={_emoji === "ok"}
            onClick={() => {
              handleLocalEmoji("ok");
            }}
          />
          <MobileEmoji
            emoji="😊"
            isActive={_emoji === "happy"}
            onClick={() => {
              handleLocalEmoji("happy");
            }}
          />
        </Box>

        {/* Feedback InputBox */}
        <Box className="rooom-feedback-wrapper">
          <p>Feedback</p>
          <TextField
            style={{ width: "100%" }}
            placeholder="Please share your feedback"
            minRows={8}
            maxRows={10}
            variant="outlined"
            multiline
            onChange={(e: any) => handleRoomFeedback(e.target.value)}
            value={roomFeedback}
          />
          <Box mt={1}>
            <Typography style={{ color: '#7F8E9D', fontWeight: 400, fontSize: '.8rem' }}>
              {remaining_chars} character{remaining_chars <= 1 ? '' : 's'} remaining
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Submit Button */}
      <Box textAlign='center' mb={2.5}>
        <button
          style={{
            background: '#FFCB00',
            padding: '1rem',
            color: '#010039',
            marginTop: '2rem',
            borderRadius: ' 0.5rem',
            border: 'none',
            outline: 'none',
            width: '100%',
            fontSize: '1rem',
            fontWeight: 500
          }}
          onClick={() => {
            sendRoomFeedback(selectedEmoji);
          }}
          disabled={isFeedbackSending}
        >
          SUBMIT
        </button>

        <Box pt={1.5} onClick={closeSmiley}>
          <Typography style={{ color: '#010039', fontWeight: 500, textDecoration: 'underline', cursor: 'pointer' }}>
            Cancel
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export function SmileyPopup(props: any) {
  const { openSmiley, closeSmiley, isMobile } = props;
  const [defaultPosition, setDefaultPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e: DraggableEvent, data: DraggableData) => {
    if (data.y >= 50) {
      closeSmiley();
    } else {
      setDefaultPosition({ x: 0, y: 0 });
    }
  };

  if (isMobile && openSmiley) {
    return (
      <Box
        id="bottomComponent"
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          zIndex: 1200
        }}
        sx={{
          bgcolor: 'rgba(0, 0, 0, 0)',
          border: '1px solid #E0E0E0',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)'
        }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >

        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            zIndex: 1100,  // Adjusted zIndex to be lower than the draggable box
            backdropFilter: 'blur(4px)',
          }}
          onClick={closeSmiley}
        />

        <Draggable
          axis="y"
          bounds={{ top: 0 }}
          onStop={handleDrag}
          position={defaultPosition}
          handle=".drag-handle"
        >
          <Slide direction="up" in={true}>
            <Box
              display="flex"
              flexDirection="column"
              zIndex={1300}
              flex={1}
              bgcolor="#fff"
              style={{
                borderTopLeftRadius: 20,
                borderTopRightRadius: 20,
              }}
            >
              <div style={{ height: '90%', width: '100%', padding: '0 1rem' }}>
                <SmileyPopUpMainContent {...props} />
              </div>
            </Box>
          </Slide>
        </Draggable >
      </Box>
    )
  }

  return (
    <Dialog
      style={{ backdropFilter: "blur(4px)" }}
      open={openSmiley}
      PaperProps={{
        style: { borderRadius: 16, maxWidth: '450px' },
      }}
    >
      <Box sx={{ py: 2, px: 3.5 }}>
        <SmileyPopUpMainContent {...props} />
      </Box>
    </Dialog>
  );
}

const badFeedbackOptions = [
  "Fellow Participants",
  "Topic",
  "Technology",
  "Others"
]

const SorryPopupMainContent = (props: any) => {
  const { openRefund, sadFeedback, handleSadFeedback,
    wantRefund, handleWantRefund, sendRoomFeedback, handleCancel,
    isModeratorRoom, isHost, showSnackbar, isMobile } = props;

  const isYes = wantRefund === "yes";
  const isNo = wantRefund === "no";

  const handleSubmit = () => {
    if (!sadFeedback.trim().length) {
      if (showSnackbar) return showSnackbar("Please select an option from the dropdown.", "error");
    };

    if (isModeratorRoom && isHost) {
      sendRoomFeedback("sad");
      handleWantRefund("no");
      openRefund();
    } else if (!wantRefund) {
      if (showSnackbar) return showSnackbar("Please select an option from Yes or No.", "error");
    }

    sendRoomFeedback("sad");
    openRefund();
  }

  return (
    <>
      <Box display='flex' alignItems='center' style={{ cursor: 'pointer' }}
        onClick={handleCancel}
      >
        <Box flex={1} className="drag-handle" height='2rem' width='100%' />
        <Box sx={{ pt: 2, pr: 2 }}>
          <CloseIcon />
        </Box>
      </Box>

      <Box>
        {/* Headings */}
        <Box
          textAlign='center'
          mt={-2}
          padding='0 3rem .5rem'
        >
          <Typography style={{ fontWeight: 600, fontSize: '1.3rem', marginBottom: '.5rem' }}>
            Sorry to hear that
          </Typography>
          <span style={{ color: '#7F8E9D', padding: '10px' }}>Can you tell us what went wrong?</span>
        </Box>

        <Box display='flex' flexDirection='column'>
          <Box>
            <Typography style={{ color: '#010039', padding: '.5rem 0' }}>Feedback reason</Typography>
          </Box>
          <div>
            <div className="drop-down">
              <DropDown
                style={{
                  width: "100%"
                }}
                list={badFeedbackOptions.map((item) => item)}
                value={sadFeedback}
                name="feedbackOption"
                // onChange={handleChange}
                className='MuiOutlinedInput-root'
                defaultText="Select options"
                // onBlur={handleBlur}
                onChange={(e) => handleSadFeedback(e.target.value)}
              />
            </div>
          </div>

          {(isModeratorRoom && isHost) ?
            <div className="feedback-sorry-p-outter" style={{ marginBottom: '4rem' }} /> : (
              <Box my={2}>
                <Box>
                  <Typography style={{ color: '#010039', padding: '.5rem 0' }}>Would you like to have a refund?</Typography>
                </Box>
                <Box display='flex' alignItems='center' justifyContent='center' className="refund-options">
                  <Box onClick={() => handleWantRefund("no")} className={isNo ? 'active' : ''}>
                    {!isNo ? <Circle /> : <FullCircle />}
                    <Typography>No</Typography>
                  </Box>
                  <Box onClick={() => handleWantRefund("yes")} className={isYes ? 'active' : ''}>
                    {!isYes ? <Circle /> : <FullCircle />}
                    <Typography>Yes</Typography>
                  </Box>
                </Box>
              </Box>
            )}

          <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column' mb={2}>
            <Box style={{
              width: '100%',
              color: '#010039',
              background: '#FFCB00',
              borderRadius: isMobile ? '3rem' : '.5rem',
              margin: '1rem 0',
              cursor: 'pointer',
              height: '3rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: 500
            }}
              onClick={handleSubmit}
            >
              Continue
            </Box>

            <Box pt={1} onClick={handleCancel}>
              <Typography style={{ color: '#010039', fontWeight: 500, textDecoration: 'underline', cursor: 'pointer' }}>
                Cancel
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export function SorryPopup(props: any) {
  const { openSorry, handleCancel, isMobile } = props;
  const [defaultPosition, setDefaultPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e: DraggableEvent, data: DraggableData) => {
    if (data.y >= 50) {
      handleCancel();
    } else {
      setDefaultPosition({ x: 0, y: 0 });
    }
  };

  if (isMobile && openSorry) {
    return (
      <Hidden smUp>
        <Box
          id="bottomComponent"
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            zIndex: 1200,
            maxHeight: '65vh'
          }}
          sx={{
            bgcolor: 'rgba(0, 0, 0, 0)',
            border: '1px solid #E0E0E0',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)'
          }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >

          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100vh',
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              zIndex: 1100,  // Adjusted zIndex to be lower than the draggable box
              backdropFilter: 'blur(4px)',
            }}
            onClick={handleCancel}
          />

          <Draggable
            axis="y"
            bounds={{ top: 0 }}
            onStop={handleDrag}
            position={defaultPosition}
            handle=".drag-handle"
          >
            <Slide direction="up" in={true}>
              <Box
                display="flex"
                flexDirection="column"
                maxHeight={'65vh'}
                zIndex={1300}
                flex={1}
                bgcolor="#fff"
                style={{
                  borderTopLeftRadius: 20,
                  borderTopRightRadius: 20,
                }}
              >
                <div style={{ height: '90%', width: '100%', padding: '0 1rem' }}>
                  <SorryPopupMainContent {...props} />
                </div>
              </Box>
            </Slide>
          </Draggable >
        </Box >
      </Hidden >
    )
  }

  return (
    <Dialog
      style={{ backdropFilter: "blur(4px)" }}
      open={openSorry}
      PaperProps={{
        style: { borderRadius: 16, maxWidth: '450px' },
      }}
    >
      <Box sx={{ py: 2, px: 4 }}>
        <SorryPopupMainContent {...props} />
      </Box>
    </Dialog>
  )
}

const RefundPopupMainContent = (props: any) => {
  const { needRefund, bulletPoints, onClose, handleOnClick, isMobile } = props;

  return (
    <>
      <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column' mb={2}>

        <Box style={{
          position: "absolute",
          zIndex: 2,
          top: 15,
          right: 15,
        }}>
          <img src={YlanesLogo} alt='Ylanes_logo' style={{ width: '1.5rem' }} />
        </Box>

        <Box className="image-container" >
          <img src={FlowerImage} alt="flower_img" />
        </Box>

        <Box px={3}>
          <Box p={4} textAlign='center'>
            <Typography variant="h6" style={{ fontWeight: 600, color: '#262556' }}>Thank You!</Typography>

            {needRefund === 'yes' && (
              <>
                <Typography style={{ color: '#6c6c6c', fontWeight: 500, marginTop: 10 }}>
                  {bulletPoints.refundText}
                </Typography>
              </>
            )}
            {needRefund === 'no' && (
              <>
                <Typography>
                  {bulletPoints.thankYouText}
                </Typography>
              </>
            )}
          </Box>

          <Box style={{
            width: '100%',
            color: '#010039',
            background: '#FFCB00',
            borderRadius: isMobile ? '3rem' : '.5rem',
            margin: '1rem 0',
            cursor: 'pointer',
            height: '3rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 500
          }}
            onClick={handleOnClick}
          >
            Continue
          </Box>
        </Box>
      </Box>
    </>
  )

}

export function RefundPopup(props: any) {
  const { needRefund, openRefund, history, onClose, isMobile } = props;

  const handleOnClick = () => {
    if (window.location.pathname === '/Rooms') {
      onClose();
    } else {
      history.push('/Rooms?type=my')
    }
  }
  if (!needRefund) return null;

  if (isMobile && openRefund) {
    return (
      <BottomSheet maxWidth={90} addBackDrop handleClose={handleOnClick} showSlider={false}>
        <RefundPopupMainContent {...props} handleOnClick={handleOnClick} />
      </BottomSheet >
    )
  }

  return (
    <Dialog
      style={{ backdropFilter: "blur(4px)" }}
      open={openRefund}
      PaperProps={{
        style: { borderRadius: 16, maxWidth: '450px' },
      }}
    >
      <Box>
        <RefundPopupMainContent {...props} handleOnClick={handleOnClick} />
      </Box>
    </Dialog>
  );
}

export default RoomFeedback;
// Customizable Area End
