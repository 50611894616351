//Customizable Area Start
import React, { Component, SVGProps } from 'react';
import { MenuItem, Divider } from '@material-ui/core';
import './VideoChatScreen.css';

interface MyProps {
	color: string;
	value: string;
	icon: any;
	handlerFunction: (s: string) => void;
	param: string | null;
	lastOne?: boolean;
}

export default class MenuList extends Component<MyProps> {
	render() {
		return (
			<>
				<MenuItem
					style={{ color: `${this.props.color}` }}
					onClick={() => this.props.handlerFunction(this.props.param || '')}
				>
					<span className='menulist-icon'>{this.props.icon}</span>
					<span style={{ whiteSpace: "initial", fontWeight: 500 }}>{this.props.value}</span>
				</MenuItem>
				{this.props.lastOne ? null : <Divider />}
			</>
		);
	}
}
// Customizable Area End
