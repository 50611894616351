// Customizable Area Start
import { IBlock } from '../../../../framework/src/IBlock';
import { Message } from '../../../../framework/src/Message';
import { BlockComponent } from '../../../../framework/src/BlockComponent';
import MessageEnum, {
   getName,
} from '../../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../../framework/src/RunEngine';

import React from 'react';

import { Grid, Box, Typography } from '@material-ui/core';
import { closeIcon } from '../assets';

import './RulesOfEngagement.scss';
const configJSON = require('../config.js');

interface Props {
   ref: React.RefObject<any>;
   cb: () => void;
   roomDetails: any
}

interface State {
   modalOpen: boolean,
   check: boolean,
   showError: boolean,
   moderatorRules: any[],
   userRules: any[],
   isFetching: boolean,
}

interface SS {
   id: string;
}

export default class RulesOfEngagement extends BlockComponent<Props, State, SS> {
   fetchRoomsApiId: string;

   constructor(props: any) {
      super(props);

      this.subScribedMessages = [
         getName(MessageEnum.AccoutLoginSuccess),
         getName(MessageEnum.RestAPIResponceMessage),
      ];

      this.state = {
         modalOpen: false,
         check: false,
         showError: false,
         isFetching: false,
         moderatorRules: [],
         userRules: [],
      }

      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
   }

   async receive(from: string, message: Message) {
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
         const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
         );

         const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
         );

         const errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
         );

         if (apiRequestCallId === this.fetchRoomsApiId) {
            if (responseJson?.data) {
               const content = JSON.parse(responseJson.data?.constant_value);
               this.setState({ moderatorRules: content.Moderator, userRules: content.User });
            }

            this.setState({ isFetching: false });
         }
      }
   }

   fetchRules() {
      const token = localStorage.getItem('token') as string;

      if (!token) return;

      this.setState({ isFetching: true });
      const headers = {
         'Content-Type': configJSON.rulesContentType,
         token,
      }

      const requesMessage = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
      );

      requesMessage.addData(
         getName(MessageEnum.RestAPIResponceEndPointMessage),
         `${configJSON.rulesApiEndPoint}${configJSON.constant_key}`
      );

      requesMessage.addData(
         getName(MessageEnum.RestAPIRequestMethodMessage),
         configJSON.rulesMethod
      );

      requesMessage.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         headers
      );

      this.fetchRoomsApiId = requesMessage.messageId;

      runEngine.sendMessage(requesMessage.id, requesMessage);
   }

   async componentDidMount() {
      this.fetchRules();
   }

   handleOpen() {
      this.setState({ modalOpen: !this.state.modalOpen });
   }

   handleGo() {
      this.props.cb();
   }

   render() {
      const ruleList = localStorage.getItem('isModerator') === 'true' &&
         this.props.roomDetails?.attributes?.is_host == true &&
         this.props.roomDetails.attributes.room_type_name == "Moderated"
         ? this.state.moderatorRules : this.state.userRules;

      return (
         <>
            <Box className={this.state.modalOpen ? 'rulesOfEngagement active' : 'rulesOfEngagement'} onClick={(e) => this.handleOpen()}>

               {this.state.isFetching && (
                  <Box display='flex' alignItems='center' justifyContent='center' height='100%'>
                     <Typography variant="h6" className="title">Loading Rules...</Typography>
                  </Box>
               )}

               {!this.state.isFetching && (
                  <Grid className="rulesOfEngagementContent backgroundImage" container onClick={(e) => { e.stopPropagation() }}>
                     <div className="label">Enter Room</div>
                     <div className="closeButton" onClick={() => this.handleOpen()}><img src={closeIcon} /></div>
                     <div className="smallLabel">Rules of Engagement</div>
                     <div className="list">
                        {ruleList.map((item: any, index: number) => {
                           return (
                              <div className="col" key={index}>
                                 <span className="number">{index + 1 + '.'}</span>
                                 <span className="blackText">{item.blackText}</span>
                                 {item.whiteText && item.whiteText2 ?
                                    <ol className="textList">
                                       <li className="whiteText list">{item.whiteText}</li>
                                       <li className="whiteText list">{item.whiteText2}</li>
                                       <li className="whiteText list">{item.whiteText3}</li>
                                    </ol>
                                    :
                                    <span className="whiteText">{item.whiteText}</span>
                                 }
                              </div>
                           )
                        })}
                     </div>
                     <div className="checkboxBox">
                        <input type="checkbox" checked={this.state.check}
                           onChange={() => { this.setState({ check: !this.state.check }) }} />
                        <span onClick={() => { this.setState({ check: !this.state.check }); }}>I agree</span>
                     </div>
                     <div className="errorBox"> {this.state.showError && 'Please agree to the rules of engagement.'}</div>
                     <button className="enterButton" onClick={() => { this.state.check ? this.handleGo() : this.setState({ showError: true }) }}>Enter</button>
                  </Grid>
               )}
            </Box>
         </>
      )
   }

}

// Customizable Area End