// Customizable Area Start
import React, { Component } from 'react';
import RoomExtensionModal from '../RoomExtension/RoomExtensionModal';
import './VideoChatScreen.css';
import StateContext, { Props as ContextProps } from '../../../../../web/src/globalState';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import moment from 'moment';

interface Props extends RouteComponentProps {
	startTime: string;
	endTime: string;
	handleLeave: any;
	roomPrice: number;
	myWalletBalance: number;
	sendRoomExtensionRequest: () => void;
	getRoomDetails: any;
	joined_users: string[];
	on_call_users: string[];
	openModal: () => void;
	isRoomOwner: boolean;
	isModeratorRoom: boolean;
	earnedYCoins: () => void;
	isMobile: boolean;
}

interface State {
	open: boolean,
	timer: boolean,
	wantFreeExtension: boolean;
	earnedApiCalled: boolean;

	// timer
	hour: number;
	min: number;
	sec: number;
}

const updatedHour = (hour: number, min: number, isFreeExtension: boolean) => {
	if (min < 6 && isFreeExtension) return hour;
	const newHour = hour - 1;
	return newHour < 1 ? 0 : hour > 1 ? 1 : newHour;
};

class Timer extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			open: false,
			timer: true,
			wantFreeExtension: false,
			earnedApiCalled: false,

			hour: 0,
			min: 0,
			sec: 0,
		};
	}
	static contextType?: React.Context<ContextProps> = StateContext;

	handleOpen = () => {
		this.setState({ open: true });
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	handle1stExtension = (res: boolean) => {
		this.setState({ wantFreeExtension: res }, () => {
			sessionStorage.setItem('wantFreeExtension', res.toString());
		});
	};

	calculateDiff = () => {
		if (this.props.endTime !== '') {
			let s = moment();
			let e = moment(this.props.endTime);
			let timedifference = e.diff(s);
			let temp = moment.duration(timedifference);
			this.setState({
				hour: temp.hours(), min: temp.minutes(), sec: temp.seconds()
			});
		}
	}

	timer: NodeJS.Timer;
	componentDidMount() {
		this.timer = setInterval(() => {
			if (this.state.timer) {
				this.calculateDiff();
			}
		}, 1000);

		// check if the user wants to extend the room for free
		const wantFreeExtension = sessionStorage.getItem('wantFreeExtension');
		if (wantFreeExtension) {
			this.setState({ wantFreeExtension: wantFreeExtension === 'true' });
		}
	}

	// check free extension modal open
	checkFreeExtensionModal = (hour: number, min: number, sec: number) => {
		if (hour === 1 && min === 5 && sec === 0) {
			this.handleOpen();
		}
	}

	// Check extension status 
	checkExtensionStatus = (hour: number, min: number, sec: number) => {
		if (hour === 1 && min === 0 && sec === 0) {
			if (!this.state.wantFreeExtension) {
				sessionStorage.setItem("self_leave", "false");
				document.getElementById('leave-button')?.click();
				this.handleClose();
				return;
			}

			// reset the extension flag after 5 seconds
			setTimeout(() => {
				this.setState({ wantFreeExtension: false });
			}, 5000);
			sessionStorage.setItem('wantFreeExtension', 'false');
		}
	}

	// handle paid extension
	handlePaidExtension = (hour: number, min: number, sec: number) => {
		if (hour === 0 && min === 5 && sec === 0) {
			if (!this.state.earnedApiCalled && this.props.isModeratorRoom && this.props.isRoomOwner) {
				this.props.earnedYCoins();
				this.setState({ earnedApiCalled: true });
			}
			this.handleOpen();
		}
	}

	// check if room time ends
	handleRoomTimeEnd = (hour: number, min: number, sec: number) => {
		if (hour <= 0 && min <= 0 && sec <= 0 && this.state.timer) {
			sessionStorage.setItem("self_leave", "false");
			document.getElementById('leave-button')?.click();
			this.handleClose();
		}
	}

	// handle call without enough people
	handleWithoutEnoughPeople = (hour: number, min: number, sec: number) => {
		if (hour === 1 && min === 45 && sec === 0) {
			if (this.props?.joined_users?.length <= 2) {
				// call leave API and leave the room.
				this.props.openModal();
			}
		}
	}

	// handle call only one person left
	handleLastWarning = (hour: number, min: number, sec: number) => {
		if (hour === 1 && min === 35 && sec === 0) {
			if (this.props?.on_call_users.length === 1) {
				sessionStorage.setItem("isForcedLeave", "true");
				setTimeout(() => {
					// call leave API and leave the room.
					this.props.openModal();
				}, 1000);
			}
		}
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
		if (this.state.hour !== prevState.hour || this.state.min !== prevState.min || this.state.sec !== prevState.sec) {
			const { hour, min, sec } = this.state;

			this.checkFreeExtensionModal(hour, min, sec);
			this.checkExtensionStatus(hour, min, sec);
			this.handlePaidExtension(hour, min, sec);
			this.handleRoomTimeEnd(hour, min, sec);
			this.handleWithoutEnoughPeople(hour, min, sec);
			this.handleLastWarning(hour, min, sec);
		}
	}

	componentWillUnmount() {
		clearInterval(this.timer);
	}

	render() {
		const { hour, min, sec } = this.state;

		return (
			<>
				<div style={{ display: 'flex' }}>
					<span>
						{`${updatedHour(hour, min, this.state.wantFreeExtension)}:${min < 10 ? '0' : ''}${min}:${sec < 10 ? '0' : ''}${sec}`}
					</span>
				</div>

				<RoomExtensionModal
					open={this.state.open}
					handleClose={this.handleClose}
					handleOpen={this.handleOpen}
					roomPrice={this.props.roomPrice}
					myWalletBalance={this.context.userCoinAmount}
					sendRoomExtensionRequest={this.props.sendRoomExtensionRequest}
					getRoomDetails={this.props.getRoomDetails}
					isFreeExtension={hour === 1}
					handle1stExtension={this.handle1stExtension}
					isMobile={this.props.isMobile}
				/>
			</>
		);
	}
}

export default withRouter(Timer);
// Customizable Area End
