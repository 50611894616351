// Customizable Area Start
import React, { useState, useRef, useEffect } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { blueTick, goldenTick, NoResult} from "../assets";

const searchFilter = (searchValue: any, list: any, searchBy = 'name') => {
  let lowerCaseQuery = searchValue.toLowerCase();
  let filteredList = searchValue
    ? list.filter((x: any) => x[searchBy].toLowerCase().includes(lowerCaseQuery))
    : list;
  return filteredList;
};


const CustomSearchField = (props: any) => {
  const categoryArray = props?.data?.categories;
  const profileArray = props?.data?.accounts;
  const subCategoriesArray = props?.data?.sub_categories;
  const topicsArray = props?.data?.topics;

  const [visible, setVisible] = useState(false);
  // const [searchValue, setSearchValue] = useState("");
  const searchValue = useRef('');

  const [selectedItem, setSelectedItem] = useState(null);
  const dropdownRef: any = useRef(null);
  const [keyword, setKeyword] = useState('');

  const clearInput = () => {
    setKeyword('');
    searchValue.current = '';
  }

  // click away listener
  useEffect(() => {
    document.addEventListener("mousedown", handleClick, false);
    return () => document.removeEventListener("mousedown", handleClick, false);
  }, []);

  useEffect(() => {
    if (!props.handleFocus) return;
    props.handleFocus(visible);
  }, [visible]);

  const handleClick = (e: any) => {
    if (dropdownRef.current.contains(e.target)) {
      return;
    }
    setVisible(false);
    clearInput();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
    searchValue.current = e.target.value;
    props.searchCustomData(searchValue.current)
    if (!visible) {
      setVisible(true);
    }
  };

  useEffect(() => {
    clearInput();
  }, [window.location.href]);

  return (
    <div className="search-outter" >
      {/* @ts-ignore */}
      <div tabIndex="0" className="input_container">
        <input
          className="input"
          type="text"
          placeholder="Search"
          onChange={handleChange}
          onFocus={() => {
            setVisible(true);
          }}
          onClick={props.searchApiCall}
          value={keyword}
        />
      </div>
      <div
        style={{ width: "100%" }}
        ref={dropdownRef}
        className={`dropdown ${visible ? "v" : ""}`}
      >
        {visible && (
          <div className="accordion-list">
            {
              props?.data?.categories?.length > 0 || props?.data?.accounts?.length > 0 || props?.data?.sub_categories?.length > 0 || props?.data?.topics?.length > 0 ?
                <>
                  {/* Accordion for categoires */}
                  <Accordion
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      borderRadius: "10px"
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ArrowForwardIosRoundedIcon style={{ color: "#000", fontSize: "16px" }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{ margin: "0px" }}
                    >
                      <p style={{ margin: "0px 0px", fontWeight: 600 }}>Category</p>
                    </AccordionSummary>

                    {categoryArray?.length ?
                      categoryArray?.map((category: any, index: any) => (
                        <div
                          key={index}
                        >
                          <Button
                            style={{
                              width: "100%",
                              justifyContent: "start",
                              textTransform: "capitalize",
                              paddingLeft: "20px"
                            }}
                            onClick={() => {
                              setVisible(false);
                              clearInput();
                              props.props.history.push("/CategoriesWeb")
                            }
                            }
                          >
                            {category?.name}
                          </Button>
                        </div>
                      ))
                      : <div>
                          <Button
                            style={{
                              width: "100%",
                              justifyContent: "start",
                              textTransform: "capitalize",
                              paddingLeft: "20px"
                            }}
                          >
                            <img src={NoResult} />
                            No data found
                          </Button>
                        </div>
                    }
                  </Accordion>

                  {/* Accordion for sub-categoires */}
                  <Accordion
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      borderRadius: "10px"
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ArrowForwardIosRoundedIcon style={{ color: "#000", fontSize: "16px" }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p style={{ margin: "0px 0px", fontWeight: 600 }}>
                        Subcategories
                      </p>
                    </AccordionSummary>
                    {subCategoriesArray?.length ?
                      subCategoriesArray.map((subCat: any, index: any) => (
                        <div
                          key={index}
                        >
                          <Button
                            style={{
                              width: "100%",
                              justifyContent: "start",
                              textTransform: "capitalize",
                              paddingLeft: "20px"
                            }}
                            onClick={() => {
                              setVisible(false);
                              clearInput();
                              console.log(props, 'PROPS HERE')
                              props.navigate(props.props.routeMap, props.props.match, props.props.history, "SubcategoriesTopic", {
                                id: subCat.parent_id, sub_id: subCat.id
                              })
                            }
                            }
                          >
                            {subCat?.name}
                          </Button>
                        </div>
                      ))
                      :
                      <div>
                        <Button
                          style={{
                            width: "100%",
                            justifyContent: "start",
                            textTransform: "capitalize",
                            paddingLeft: "20px"
                          }}
                        >
                          No data found
                        </Button>
                      </div>
                    }
                  </Accordion>

                  {/* Accordion for topics */}
                  <Accordion
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      borderRadius: "10px"
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ArrowForwardIosRoundedIcon style={{ color: "#000", fontSize: "16px" }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography style={{ margin: "0px 0px", fontWeight: 600 }}>
                        Topics
                      </Typography>
                    </AccordionSummary>
                    {topicsArray?.length ?
                      topicsArray.map((topic: any, index: any) => (
                        <div
                          key={index}
                        >
                          <Button
                            style={{
                              width: "100%",
                              justifyContent: "start",
                              textTransform: "capitalize",
                              paddingLeft: "20px"
                            }}
                            onClick={() => {
                              props.navigate(props.props.routeMap, props.props.match, props.props.history, "TopicRooms", {
                                id: topic.attributes.category_id, sub_id: topic.attributes.sub_category_id, topic_id: topic.id
                              })
                              setVisible(false)
                            }}
                          >
                            {topic?.attributes.name}
                          </Button>
                        </div>
                      ))
                      :
                      <div>
                        <Button
                          style={{
                            width: "100%",
                            justifyContent: "start",
                            textTransform: "capitalize",
                            paddingLeft: "20px"
                          }}
                        >
                          No data found
                        </Button>
                      </div>
                    }
                  </Accordion>

                  {/* Accordion for profile */}
                  <Accordion
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      borderRadius: "10px",
                      overflowWrap: "break-word"
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ArrowForwardIosRoundedIcon style={{ color: "#000", fontSize: "16px" }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography style={{ margin: "0px 0px", fontWeight: 600 }}>
                        Profiles
                      </Typography>
                    </AccordionSummary>

                    {profileArray?.length ?
                      profileArray.map((profileData: any, index: any) => (
                        <List
                          // className={classes.root}
                          key={index}
                        >
                          <ListItem
                            button
                            className="user-list-outter"
                            onClick={() => {
                              setVisible(false);
                              props.props.history.push('/UserProfile', { userId: profileData.id });
                              clearInput();
                            }}
                          >
                            <div className="user-card__image-container">
                              <ListItemAvatar>
                                {
                                  profileData?.attributes?.profile_image &&
                                  <Avatar src={profileData?.attributes?.profile_image} /> ||
                                  <Avatar>
                                    <ImageIcon />
                                  </Avatar>
                                }
                              </ListItemAvatar>
                              {(profileData?.attributes?.is_moderator || profileData?.attributes?.is_superhost) &&
                                <img
                                  src={profileData?.attributes?.is_moderator ? goldenTick : blueTick}
                                  alt="verified_tick"
                                  className="search-bar_check-mark"
                                />
                              }
                            </div>
                            <ListItemText
                              className="list-name"
                              primary={profileData?.attributes?.full_name || "full_name"}
                              secondary={`@${profileData?.attributes?.user_name}` || "user_name"}
                            />

                            {
                              profileData.attributes?.is_connected &&
                              <Button disabled className='btn-connected'>
                                Connected
                              </Button>
                            }
                          </ListItem>
                        </List>
                      ))
                      :
                      <div>
                        <Button
                          style={{
                            width: "100%",
                            justifyContent: "start",
                            textTransform: "capitalize",
                            paddingLeft: "20px"
                          }}
                        >
                          No data found
                        </Button>
                      </div>
                    }
                  </Accordion>
                </>
                :
                <div>
                  <div className="noDataFoundSearch">
                    <img src={NoResult} />
                    <span>No results found. <br />Try using different keywords.</span>
                  </div>
                </div>
            }

          </div>

        )}
      </div>
    </div >
  );
};

export default CustomSearchField;



  // Customizable Area End