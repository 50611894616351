// Customizable Area Start
import React, { useEffect, useState, useRef } from 'react'
import {
  Container,
  Grid,
  Button,
  TextField,
  Box,
  Menu
} from "@material-ui/core";
import {
  goldenTick,
  blueTick,
  yellowheart,
  thumb,
  images
} from "../../assets";
 import CreateIcon from "@material-ui/icons/Create";
 import ClearIcon from "@material-ui/icons/Clear";
 import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import './HostTake.scss';
import UserLike from './UserLike'
import EditTake from './EditTake';

const UserTake = ({updateNewResource, roomDetails, userDetails, updateRequest,callUpdateYourTakeAPI,history, handleLikeCount, handleReportClick}:any) => {
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const didMount = useRef(false);
  const [hasError, setError] = useState("");
  const [isChanged, setIsChanged] = useState<boolean>(false)

  const getProfilePicture = (room_details: any) => {
    if(room_details?.attributes?.participant?.data?.attributes?.profile_image ) return room_details?.attributes?.participant?.data?.attributes?.profile_image;
    else if (room_details?.attributes?.is_anonymously) return images
    return images
  };
  
  let res: any;
  let room_details: any;
  
  const checkIfCurrentUser = (elem: any) => {
    return (
      parseInt(elem?.attributes?.account_id, 10) ===
      parseInt(localStorage.getItem("userId") as string, 10)
    );
  };
  
  const getUserName = (
    isLoggedInUser: boolean,
    is_anonymously: boolean,
    elem: any
  ) => {
    const getUsernameValue = () => {
      if (isLoggedInUser) {
        return "You";
      } else if (is_anonymously) {
        return elem?.attributes?.anonymously_name;
      } else {
        return elem?.attributes?.participant?.data?.attributes?.full_name;
      }
    };
  
    return getUsernameValue();
  };
  
  const getGender = (elem: any) => {
    return (
      <>
        {elem?.attributes?.participant?.data?.attributes?.gender === "Male"
          ? "M" : (elem?.attributes?.participant?.data?.attributes?.gender ==="Female" ? "F" :
            "NB")
          }
        ({elem?.attributes?.participant?.data?.attributes?.age_range?.[0]}-
        {elem?.attributes?.participant?.data?.attributes?.age_range[1]} yrs)
      </>
    )
  };
  
  const getTick = (elem: any) => {
    return elem?.attributes?.participant?.data?.attributes?.is_moderator
      ? goldenTick
      : blueTick;
  };

  const UserResources = (isLoggedIn?: any) => {
    return (
       <>
         {userDetails?.attributes?.resources_and_likes?.map(
           (
             resource: {
               id: number;
               likes: number;
               url: string;
             },
             i: number
           ) => {
             const { id, likes, url } = resource;
             const fullUrl = url?.includes("//") ? url : `//${url}`;
             return (
                <div className="hostResources">
                   <div className="resource">
                      <div className="url" onClick={() => {}}>
                         {fullUrl}
                      </div>
                      <div className="buttons">
                        <UserLike
                          current_likes={likes}
                          updateLike={() => handleLikeCount(id)}
                        />
                        {!isLoggedIn && 
                          <span
                              className="fs12 colorLightGrey fw500"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => handleReportClick(e, id)}
                            >
                              <PriorityHighIcon className="reportIcon"/>
                        </span>
                        }
                      </div>
                   </div>
                 </div>
             )
           })}
       </>
    )
  }

  const CheckUserType = (elem: any) => {
       if (parseInt(localStorage.getItem("userId") as string, 10) === parseInt(room_details?.attributes?.host?.data?.id, 10) ) {
          history.push("/UserPersonalProfile");
       } else if (roomDetails?.attributes?.is_anonymously) return
       else {
          history.push("/UserProfile", {
             userId: elem?.attributes?.account_id
          });
       }
  };

  const dynamicUserName = (userDetail: any) => {
    if ( userDetail?.attributes?.participant?.data?.id == localStorage.getItem("userId")) {
      return "You";
    } else {
      if (userDetail?.attributes?.is_anonymously) {
        return userDetail?.attributes?.anonymously_name;
      } else {
        return userDetail?.attributes?.participant?.data?.attributes?.full_name;
      }
    }
 }

const renderUserAcceptReject = (elem:any) => {
  if ((roomDetails?.attributes?.room_type_name === "Premium" ||
      roomDetails?.attributes?.room_type_name === "One-to-one") &&
      roomDetails?.attributes?.is_host) {
      return (
        <div className="userAcceptReject">
          <>
            <p
              className="fs15 fw600 colorBlue pointer"
              onClick={() =>  {
                updateRequest(elem.id, "is_accepted")
                setIsChanged(true)
              }}
            >
              ACCEPT
            </p>
            <p
              className="fs15 fw600 colorGrey pointer"
              onClick={() => 
                {
                  updateRequest(elem.id, "is_rejected")
                  setIsChanged(true)
                }
              }
            >
              REJECT
            </p>
          </>
        </div>
      )
    }
}

  const userTakeWrapper = (elem: any) => {
    const is_anonymously = elem?.attributes?.is_anonymously;
    const isLoggedInUser = checkIfCurrentUser(elem);
    const userName = getUserName(isLoggedInUser, is_anonymously, elem);
    const tick = getTick(elem);
    return (
      <>
        {isEdit ? 
          <EditTake roomDetails={roomDetails} setIsEdit={setIsEdit} userDetails={userDetails} updateNewResource={updateNewResource}/>
        :
        <>
          {isLoggedInUser &&
            <Grid xs={12} className="hostTakeBoxTexts" container>
              <Grid xs={6}>Your Take</Grid>
              <Grid xs={6} className="editTakeBox" style={{textAlign: 'right'}} onClick={() => {setIsEdit(!isEdit)}}>Edit Take</Grid>
          </Grid>
          }
       <Grid xs={12} className="hostOutside">
          <div className={"hostTakeBox " + (isLoggedInUser ? "notHost" : "")}>
            <Grid xs={12} md={(isLoggedInUser || elem?.attributes?.resources_and_likes.length === 0) ? 12 : 8}>
                <Grid xs={12} onClick={() => {CheckUserType(elem)}} className="hostTakeTopBox">
                  <div className="profileImage">
                      <img src={getProfilePicture(roomDetails)} />
                  </div>
                  <div className="hostName">
                      <span>{dynamicUserName(elem)}</span>
                      <img src={yellowheart} alt="" />
                        {elem?.attributes?.participant?.data?.attributes?.hearts || 0}
                  </div>
                  <div className="genderAndAge">
                      {getGender(elem)}
                  </div>
                </Grid>
                <Grid xs={12} className="middleBox">
                {elem?.attributes?.your_take}
                </Grid>
              </Grid>
              {elem?.attributes?.resources_and_likes.length > 0 &&
                <Grid xs={12} md={isLoggedInUser ? 12 : 4} className={"resourcesBox " + (isLoggedInUser ? '' : 'notHost')}>
                  {UserResources(isLoggedInUser)}
                </Grid>
              }
          </div>
            {!isChanged && elem?.attributes?.is_pending && renderUserAcceptReject(elem)}
      </Grid>
      </>
    }
  </>
    )
  }

  return (
    <>
      {userTakeWrapper(userDetails)}
    </>
  )

}

export default UserTake;