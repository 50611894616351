// Customize Area Start
import React, { FC } from 'react';
import { Modal, Typography, Box, Button, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// Customize Area End

// Customize Area Start
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backdropFilter: "blur(3px)",
        backgroundColor: 'rgba(0,0,10,0.7)',
    },
    paper: {
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 400,
        borderRadius: ".5rem",
        textAlign: "center"
    },
    message: {
        color: "#222153",
        fontWeight: 600
    },
    buttons: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 30,

        "& button": {
            height: 45,
            borderRadius: ".3rem !important",
            width: 150,

            "&:first-child": {
                border: "1px solid #222153",
                fontSize: 18
            },
            "&:last-child": {
                background: "#010039",
                fontSize: 18,
                color: theme.palette.common.white
            }
        }
    }
}));
// Customize Area End

// Customize Area Start
interface Props {
    // Customize Area Start
    isOpen: boolean;
    handleClose: () => void;
    userChoice: (choice: boolean) => void;
    // Customize Area End
}
// Customize Area End

// Customize Area Start
const ConfirmationModal: FC<Props> = ({ isOpen, handleClose, userChoice }) => {

    // Customize Area Start
    const classes = useStyles();
    // Customize Area End

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            className={classes.modal}
        >
            <Fade in={isOpen} >
                <Box className={classes.paper}>
                    <Typography className={classes.message}>Are you sure you want to Disconnect?</Typography>

                    <Box className={classes.buttons}>
                        <Button onClick={() => userChoice(false)}>No</Button>
                        <Button onClick={() => userChoice(true)}>Yes</Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}
// Customize Area End

// Customize Area Start
export default ConfirmationModal;
// Customize Area End