export const imgPasswordVisible = require('../assets/ic_password_visible.png');
export const imgPasswordInVisible = require('../assets/ic_password_invisible.png');
// export const profile = require("../assets/images.jpeg");
export const logo = require('../assets/logo.png');
export const profile =
  'https://i.ibb.co/y5qXH12/main-qimg-cf89e8e6daa9dabc8174c303e4d53d3a.webp';
export { ReactComponent as EditIcon } from '../assets/edit_icon.svg';
export { ReactComponent as MorOptionIcon } from '../assets/more_option.svg';
export { ReactComponent as SendMessageIcon } from '../assets/send_icon.svg';
export { ReactComponent as MessageBubbles } from '../assets/msg_bubble.svg';
export { ReactComponent as SearchIcon } from '../assets/search_icon.svg';
export { ReactComponent as TimeIcon } from '../assets/time.svg';
export { ReactComponent as FacebookIcon } from '../assets/facebook.svg';
export { ReactComponent as InstaIcon } from '../assets/instagram.svg';
export { ReactComponent as TwitterIcon } from '../assets/twitter.svg';
export { ReactComponent as HomeIcon } from '../assets/home-stoke.svg';
export { ReactComponent as MailIcon } from '../assets/mail.svg';
export const heroImage = require('../assets/hero_image.png');
export const bgPartical = require('../assets/bg_partical.png');
export const highlightedUnderline = require('../assets/highlighted_underline.png');

export const datingStories = require('../assets/dating_stories.svg');
export const heart = require('../assets/heart.svg');
export const task = require('../assets/task.svg');
export const search = require('../assets/search.svg');
export const howItWorks = require('../assets/how_it_works.png');
export const roomType1 = require('../assets/roomType1.svg');
export const roomType2 = require('../assets/roomType2.svg');
export const roomType3 = require('../assets/roomType3.svg');
export const roomType4 = require('../assets/roomType4.svg');
export const store = require('../assets/store.png');
export const market = require('../assets/market.png');
export const exploreWeb = require('../assets/explore_web.png');
export const logoLight = require('../assets/logo_light.png');
export const mobileMarkup = require('../assets/mobile_markup.png');
export const ceo = require('../assets/ceo.png');
export const coo = require('../assets/coo.png');
export const circle = require('../assets/circle.svg');
export const dottedLine = require('../assets/dotted_line.png');
export const linkedin = require('../assets/linkedin.svg');
export const maintainance = require('../assets/maintainance.svg');
export const cancelLogo = require('../assets/cancel_logo.png');
