import React, { Fragment, useEffect, useRef } from "react";
import { Box, Theme, Typography, withStyles } from "@material-ui/core";
// import { LinkPreview } from '@dhaiwat10/react-link-preview';
// import ShimmerLoading from "../../../components/src/ShimmerLoading";
import { MessageBubbles, profile } from '../assets';
// import styles from "./Styles.module.scss";

interface SingleMessageProps {
    author: string;
    body: string;
    conversation_sid: string;
    created_at: string;
    delivery: boolean | null;
    index: number;
    message_sid: string;
    participant_sid: string;
    profile_image: string;
}

interface MessagesProps {
    messages: SingleMessageProps[];
    isLoading: boolean;
    selected_user: any;
}

interface FormatMsgType {
    date: string;
    messages: SingleMessageProps[];
}

export const formatTime = (date: Date) => {
    let hours = date.getHours();
    let minutes: string | number = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + minutes + " " + ampm;
};

const formatDate = (date: Date) => {
    if (date.toLocaleDateString() === new Date().toLocaleDateString()) return "Today";

    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
};

const formatMsg = (allMessages: MessagesProps["messages"]) => {
    const newFormatMsgData: FormatMsgType[] = [];

    if (allMessages.length) {
        allMessages.forEach((msg) => {
            const date = formatDate(new Date(msg.created_at));

            const res = newFormatMsgData.find((msg) => msg.date === date);
            const formatNewMsg = {
                ...msg
            };

            if (res) {
                res.messages.push(formatNewMsg);
            } else {
                newFormatMsgData.push({
                    date,
                    messages: [formatNewMsg]
                });
            }
        });
    }
    return newFormatMsgData;
}

const isValidHttpUrl = (message: string) => {
    let url;
    try {
        url = new URL(message);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
}

function renderMessages(rawMsg: FormatMsgType,
    isMobile: boolean,
    profile_pic: any,
    selected_user: any,
    lastMesgRef: React.RefObject<HTMLDivElement>
    ) {
    return (
        <Fragment key={rawMsg.date}>
            <Box textAlign="center">
                <Typography style={{ color: "#acaba9", margin: ".3rem 0" }}>{rawMsg.date}</Typography>
            </Box>
            {rawMsg.messages.map((message: SingleMessageProps, indx: number) => {
                const isAuthor = message.author === localStorage.getItem("userName");
                const isURL = isValidHttpUrl(message.body);
                const imgMarginLeft = isAuthor ? ".5rem" : 0;
                const imgMarginRight = !isAuthor ? ".5rem" : 0;
                const messageStyle = {
                    marginBottom: "1rem",
                    border: '1px solid #ccc',
                    padding: ".5rem 1rem",
                    background: isAuthor ? "#eaf1ff" : "#fff7d2",
                    marginLeft: isAuthor ? "auto" : 0,
                    width: isMobile ? 'auto' : "35%",
                    borderRadius: ".5rem",
                    maxWidth: isMobile ? "80%" : 'auto',
                    borderTopRightRadius: isAuthor ? 0 : "0.5rem",
                    borderTopLeftRadius: !isAuthor ? 0 : "0.5rem",
                    marginTop: ".5rem"
                };

                return (
                    <Box key={indx} display="flex" flexDirection={isAuthor ? "row-reverse" : "row"}>
                        <img
                            src={isAuthor ? profile_pic === "false" ? profile : (profile_pic as string) : selected_user.profile_image ? selected_user.profile_image : profile}
                            alt="user_profile_picture"
                            style={{
                                width: "2.3rem", height: "2.3rem", borderRadius: "50%", objectFit: "cover",
                                marginLeft: imgMarginLeft,
                                marginRight: imgMarginRight
                            }}
                        />
                        <Box style={messageStyle}>
                            <Box style={{ width: "100%", wordBreak: "break-word" }}>
                                {isURL ?
                                    <a href={message.body} target="_blank">{message.body}</a> :
                                    <Typography>{message.body}</Typography>
                                }
                            </Box>
                            <Box style={{ textAlign: isAuthor ? "left" : "right" }}>
                                <Typography style={{ fontSize: "80%", marginTop: ".2rem", color: "#a0a0a0" }}>
                                    {new Date(message.created_at).toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' })}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                );
            })}
            {/* scroll to here */}
            <div ref={lastMesgRef} />
        </Fragment>
    );
}

const Messages: React.FC<MessagesProps> = (props) => {
    let lastMesgRef = useRef<HTMLDivElement>();
    // const observer = useRef<RefObject>(); // Will be used when we load few messages an once.
    const { messages, isLoading, selected_user } = props;
    const [isMobile, setIsMobile] = React.useState(false);

    const executeScroll = () => {
        if (lastMesgRef && lastMesgRef.current) {
            lastMesgRef.current.scrollIntoView({ behavior: "auto", block: "end", inline: "nearest" });
        }
    }

    const handleResize = () => {
        const isMobile = window.innerWidth <= 768;
        setIsMobile(isMobile);
    }

    useEffect(() => {
        executeScroll();
    });

    // check if the user is on mobile or desktop
    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const profile_pic = localStorage.getItem("profile_image");
    const formatMessages = formatMsg(props.messages);

    return (
        <>
            {isLoading && !messages.length && (
                <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                    <Typography style={{ fontSize: 20, fontWeight: 600 }}>
                        Loading Chats...
                    </Typography>
                </Box>
            )}

            {!isLoading && !messages.length && (
                <Box height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <MessageBubbles />
                    <Typography style={{
                        fontSize: "1.5rem", fontWeight: 500, color: "#0F0F0F", lineHeight: "2.25rem"
                    }}
                    >
                        No messages to view
                    </Typography>
                    <Typography style={{
                        color: "#CCCCCC", fontSize: "1rem", fontWeight: 500, lineHeight: "1.5rem", marginTop: ".3rem"
                    }}>
                        Start a new chat with {selected_user.user_name}
                    </Typography>
                </Box>
            )}

            {formatMessages.map((rawMsg) => renderMessages(rawMsg, isMobile, profile_pic, selected_user, lastMesgRef as React.RefObject<HTMLDivElement>))}
        </>
    );
}

// @ts-ignore
export default (Messages);

