// Customizable Area Start

import React, { Fragment, useEffect, useState } from "react";
import Settings5Controller from "../Settings5Controller";
import {
  alpha, Theme, createStyles, withStyles, makeStyles
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import {
  Button, Box, Typography, Modal,
  Avatar, Divider, Popover, Tooltip, Hidden
} from "@material-ui/core/";
import Tutorials from "../../../DifferentRooms/src/MyRooms/Tutorials.web";
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import MoreIcon from "@material-ui/icons/MoreVert";
import moment from "moment";
import AlertBlock from "../../../alert/src/Alert.web";
import {
  logo, wallet, peopeIcon, searchIcon,
  Notification_dot, Bell, Message, Circle,
  NotificationIcon, GroupsIcon, SettingIcon, MessageIcon,
  NoProfilePic, LogoutIcon
} from "../assets";
import MenuIcon from '@material-ui/icons/Menu';
import "../settings/header.css";
import CustomSearchField from "./searchHeader.web";
import CreateRoom from "../../../DifferentRooms/src/CreateRoom/CreateRoom.web";
import { withRouter, RouteComponentProps } from "react-router-dom";
import ProfileDropdown from "./ProfileDropdown";
import ClearIcon from '@material-ui/icons/Clear';
import Slide from '@material-ui/core/Slide';
import Draggable from "react-draggable";
import SwipeableViews from 'react-swipeable-views';
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = (theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
      zIndex: 1500
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    search: {
      position: "relative",
      // border: "solid #aaaaaa50 1px",
      // borderRadius: "8px",
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: "400px",
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "400px",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    sectionDesktop: {
      display: "none",
      position: "relative",
      [theme.breakpoints.up("md")]: {
        display: "flex",
        alignItems: "center",
      },
    },
    Notification_dot: {
      position: "absolute",
      right: "12px",
      top: "15px",
      zIndex: 1

    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    appBar: {
      background: "#fff",
      color: "#000",
    },
    creditButton: {
      border: "solid #aaaaaa50 1px",
      borderRadius: "7px",
      padding: "8px",
    },
    mobileDropdownIcon: {
      fontSize: '1.7rem',
      marginRight: '1rem',
      color: '#27272A',
    },
    mobileIcons: {
      '& svg': {
        color: '#7F8E9D',
        height: '1.6rem',
        width: '1.6rem',
      }
    }
  });

const modalStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalContent: {
      outline: 'none',
      backgroundColor: theme.palette.background.paper,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '100%',
        animation: `$slide 0.3s ${theme.transitions.easing.easeInOut}`,
      },
    },
    '@keyframes slide': {
      '0%': {
        transform: 'translateY(100%)',
        opacity: 0,
      },
      '100%': {
        transform: 'translateY(0)',
        opacity: 1,
      },
    },
  })
);

const notificationStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainDiv: {
      width: '400',

      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      }
    },
  })
);

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[3],
    fontSize: 11,
    maxWidth: 600,
    padding: 0
  },
  arrow: {
    color: theme.palette.common.white, // Arrow color is now white
    fontSize: 35, // Increase the arrow size
    "&::before": {
      boxShadow: theme.shadows[1]
    },
    "&::after": {
      // Create a shadow-like effect for the arrow
      filter: 'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.3))',
    },
  },

}))(Tooltip);

const mobileMenu = [
  {
    name: "Notifications",
    icon: <NotificationIcon />,
    link: '#'
  },
  {
    name: "Messages",
    icon: <MessageIcon />,
    link: 'Chat'
  },
  {
    name: "Connections",
    icon: <GroupsIcon />,
    link: '#'
  },
  {
    name: "Settings",
    icon: <SettingIcon style={{ width: '1.8rem', height: '1.8rem' }} />,
    link: 'Setting'
  },
]

interface NLProps {
  notifications: { data: any[], totalPage: number, currentPage: number, isLastPage: boolean, isLoading: boolean };
  fetchNextNotifications: () => void;
  refetchNotificatons: () => void;
  handleClick: (id: number) => void;
  handleDelete: (id: number) => void;
  handleMarkAllAsRead: () => void;
  unreadCount: number;
  history: RouteComponentProps['history'];
  isMobile: boolean
}

const NotificationList: React.FC<NLProps> = ({
  notifications, refetchNotificatons, handleClick, fetchNextNotifications,
  handleDelete, handleMarkAllAsRead, unreadCount, history, isMobile
}) => {
  const [hoverId, setHoverID] = useState<null | number>(null);
  const styles = notificationStyles();

  useEffect(() => {
    // refetch notifications if updated.
    refetchNotificatons();
  }, []);

  const setID = (id: number) => setHoverID(id);
  const removeID = () => setHoverID(null);
  const handleSwipe = (id: number) => handleDelete(id);
  const handleRedirect = (url: string) => {
    // if already contains /
    if (url[0] === '/') {
      history.push(url);
      return;
    }
    history.push(`/${url}`);
  }

  const { isLastPage, data } = notifications;

  const RenderMsg = ({ message }: { message: string }) => {
    return (
      <Box display='flex' alignItems='center' justifyContent='center' mt={3}>
        <Typography style={{ color: '#7F8E9D' }}>{message}</Typography>
      </Box>
    );
  };

  return (
    <Box className={styles.mainDiv}>
      <Box display='flex' alignItems='center' justifyContent='space-between'
        sx={{
          p: 1.5
        }}
      >
        <Typography style={{ fontWeight: 600, color: '#1e3354' }}>Notifications</Typography>
        {unreadCount > 0 && (
          <Box onClick={handleMarkAllAsRead}>
            <Typography
              style={{ fontSize: '.8rem', color: 'blue', cursor: 'pointer' }}
            >
              Mark all as read
            </Typography>
          </Box>
        )}
      </Box>

      <Divider />

      <InfiniteScroll
        dataLength={data?.length}
        next={fetchNextNotifications}
        hasMore={!isLastPage}
        loader={<RenderMsg message="Fetching..." />}
        className="infinite-scroll"
        endMessage={!data.length ?
          <RenderMsg message="No notifications found." /> :
          data.length > 10 ?
            <RenderMsg message="You've reached at the end" /> : null
        }
        style={{ maxHeight: isMobile ? 'calc(100dvh - 80px)' : '50vh', minHeight: isMobile ? 'calc(100dvh - 80px)' : '10vh' }}
      >
        {data.map((each) => {
          return (
            <SwipeableViews
              key={`${each.id}`}
              onChangeIndex={() => handleSwipe(each.id)}
            >
              <Box
                key={`${each.id}`}
                sx={{ p: 1.5 }}
                display='flex'
                justifyContent='space-between'
                style={{ cursor: 'pointer', position: 'relative' }}
                className="notification-card"
                onClick={() => {
                  if (!each.attributes.is_read) {
                    handleClick(each.id);
                  }
                  const redirect_url = each.attributes?.app_url;
                  if (redirect_url)
                    handleRedirect(redirect_url);
                }}
                onMouseEnter={(e) => setID(each.id)}
                onMouseLeave={removeID}
              >
                <Box display='flex' alignItems='center' flex={1}>
                  <img
                    src={each.attributes?.icon_url as string}
                    alt=""
                    width={50}
                    height={50}
                    style={{ borderRadius: '50%', objectFit: 'cover' }}
                  />
                  <Box ml={1.5} flex={1}>
                    <Typography style={{ fontWeight: 600, fontSize: '.9rem' }}>{each.attributes.headings}</Typography>
                    <Typography style={{ fontSize: '.7rem', color: '#979797', wordBreak: 'break-all' }}>{each.attributes.contents}</Typography>
                  </Box>
                </Box>
                <Typography
                  style={{ fontSize: '.6rem', color: '#7F8E9D', flex: .2, marginLeft: '.7rem', textAlign: 'right' }}
                >
                  {moment(each.attributes.created_at).fromNow()}
                </Typography>

                <Hidden smDown>
                  {each.id === hoverId && (
                    <Box onClick={(e) => { e.stopPropagation(); handleDelete(each.id) }}
                      style={{ position: 'absolute', right: 20, top: '50%', transform: 'translateY(-50%)' }}
                    >
                      <ClearIcon className="icon-animation" />
                    </Box>
                  )}
                </Hidden>
              </Box>

              <Hidden smUp>
                <Box display='flex' alignItems='center' justifyContent='flex-start'>
                  <Button color='secondary'> Swipe to delete </Button>
                </Box>
              </Hidden>

              <Divider />
            </SwipeableViews>
          )
        })
        }
      </InfiniteScroll>
    </Box >
  )
}

class Header extends Settings5Controller {

  constructor(props: any, routeMap: any) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  async componentDidMount() {
    this.getFriendConnectionList();
    this.getWalletCoins();
    this.getConnectedChatList();
    this.updateUnreadCount();
    this.fetchAllNotifications();
    this.handleFCMTokenForAlreadyLoggedInUsers();
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("focus", () => {
      this.setState(prev => ({
        notifications: {
          ...prev.notifications, currentPage: 1
        }
      }),
        this.fetchAllNotifications
      )
    });
    window.addEventListener("storage", this.handleNotificationCountChange, false);
  }

  async componentWillUnmount() {
    this.removeLisener();
  }

  handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  onclose = (value: boolean) => {
    this.context.changeCreateRoomModal(value)
    this.setState({ createRoomModal: value })
  }
  acceptReject(reqId: any, status: any) {
    this.acceptRejectFriendConnection(reqId, status);
  }

  acceptAllConnection() {
    const payload = new FormData();

    this.state?.friendConnectionList && this.state?.friendConnectionList?.forEach((item: any) => {
      payload.append("id[]", item.id);
    });

    this.AcceptAllConnectionRequest(payload)
  }

  renderFriendRequestList = (requestId: any, data: any, key: string) => {

    let time = moment(data.created_at).fromNow();
    // .format('LT'); 
    return (
      <Box
        component="div"
        display="flex"
        // justifyContent="space-evenly"
        alignItems="center"
        p={3}
        key={key}
      >
        <AlertBlock data={{ show: this.state.isMessagePop, close: this.handleCloseAlert, msg: this.msg, type: this.type, vertical: "top", horizontal: "center" }} />
        <Avatar
          alt="Remy Sharp"
          style={{ height: "50px", width: "50px", cursor: "pointer" }}
          src={data.profile_image as string}
          onClick={
            () => {
              this.handleClose()

              //@ts-ignore
              this.redirect(this.props?.history, 'UserProfile', {
                userId: data.sender_id
              })
            }}
        />
        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%"
        }}>
          <Typography
            component="section"
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "14px",
              marginLeft: "14px",
            }}
          >
            <Typography component="span" gutterBottom style={{ fontWeight: 600, }}
              onClick={
                () => {
                  this.handleClose()
                  //@ts-ignore
                  this.redirect(this.props?.history, 'UserProfile', {
                    userId: data.sender_id
                  })
                }}
            >
              <span style={{ cursor: "pointer" }}> {data.full_name}</span> <span style={{ cursor: "none" }}>sent you a request</span>
            </Typography>
            <Typography component="div" style={{ display: "flex" }}>
              <Typography
                onClick={() => {
                  this.acceptReject(requestId, "Accepted")
                }
                }
                style={{ color: "#514b9a", marginRight: "30px", fontWeight: 600, fontSize: "16px", cursor: "pointer" }}  >
                Accept
              </Typography>
              <Typography component="span"
                onClick={() => {
                  this.acceptReject(requestId, "Rejected")
                }}
                style={{ color: "#c9c8c8", fontWeight: 600, fontSize: "16px", cursor: "pointer" }}>
                Reject
              </Typography>
            </Typography>
          </Typography>
          <Typography
            component="span"
            style={{
              color: "grey",
              alignSelf: "start",
              textAlign: "right",
              minWidth: "100px",
              fontSize: "14px"
            }}
          >
            {time}
          </Typography>
        </div>
      </Box>
    );
  }

  handleClose = () => {
    this.setState({ friendsPopUp: null });
  };

  handleCloseAlert = () => {
    this.setState({ isMessagePop: false })
  }

  renderNotification = () => {
    return (
      <Box display="flex" style={{ position: 'relative' }}>
        <LightTooltip
          title={
            <NotificationList
              notifications={this.state.notifications}
              refetchNotificatons={() => this.setState(prev => ({
                notifications: {
                  ...prev.notifications, data: [], currentPage: 1, isLastPage: false
                }
              }), this.fetchAllNotifications)}
              fetchNextNotifications={this.fetchAllNotifications}
              handleClick={this.handleUnreadCount}
              handleDelete={this.handleDeleteNotification}
              handleMarkAllAsRead={this.handleMarkAllAsRead}
              unreadCount={this.state.unreadNotificationCount}
              history={this.props.history}
              isMobile={this.state.isMobile}
            />
          }
          arrow
          interactive
          PopperProps={{
            disablePortal: true,
            modifiers: {
              flip: {
                enabled: false,
              },
              preventOverflow: {
                enabled: true,
                boundariesElement: 'window',
              },
            },
          }}
        >
          <IconButton color="inherit">
            <img className="icon-style" src={Circle} alt="Circle Icon" />
            <img className="bell-icon-style" src={Bell} alt="Bell Icon" />
          </IconButton>
        </LightTooltip>

        {
          this.state.unreadNotificationCount > 0 && (
            <div className="notificationDot">
              {this.state.unreadNotificationCount}
            </div>
          )
        }
      </Box >
    )
  }

  renderMenu = () => {
    const { anchorEl } = this.state;
    const isMenuOpen = Boolean(anchorEl);

    return (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.handleMenuClose}>Profile</MenuItem>
        <MenuItem onClick={this.handleMenuClose}>My account</MenuItem>
      </Menu>
    )
  };

  renderCreateRoomModal = () => {
    return (
      <>
        {this.context.createRoomModal &&
          <CreateRoom
            {...this.props}
            selectedValue={this.context.selectedValues}
            getWalletAmount={this.getWalletCoins}
            updateYCoins={this.getWalletCoins}
            history={this.props.history}
            routeMap={this.props.routeMap}
            onclose={this.onclose}
            walletAmount={this.state.walletYCoins}
            getMyRooms={this.props.getMyRooms}
            navigation={this.navigate}
            prevData={null}
          />
        }
      </>
    )
  }

  friendsPopup = () => {
    const hasfriendConnectionList = this.state?.friendConnectionList?.length > 0;

    return (
      <Popover
        open={!!this.state.friendsPopUp}
        anchorEl={this.state.friendsPopUp}
        onClose={this.handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        <Typography
          component="main"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 20px",
            borderBottom: "1px solid #c9c8c8"
          }}
        >
          <Typography variant="h6" style={{ color: "#101038" }}>
            Requests
          </Typography>
          {hasfriendConnectionList ?
            <Typography variant="subtitle2" style={{ color: "deepskyblue", fontSize: "12px", cursor: "pointer" }}
              onClick={this.acceptAllConnection}
            >
              Accept All
            </Typography> : null}
        </Typography>
        <Divider />
        <Box component="section">
          {hasfriendConnectionList ? this.state?.friendConnectionList?.map((item: any, index: number) => {
            return this.renderFriendRequestList(item.id, item.attributes, `${item.id}-${index}`)
          }) :
            <div style={{
              fontSize: "15px", display: "flex",
              justifyContent: "space-between",
              fontWeight: 500,
              alignItems: "center",
              padding: "10px 20px"
            }}>
              <span>No request found</span>
            </div>
          }
          <Divider />
        </Box>
      </Popover>
    )
  }

  renderWebHeader = () => {
    // @ts-ignore
    const { classes } = this.props;
    const hasfriendConnectionList = this.state?.friendConnectionList?.length > 0;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      this.getFriendConnectionList();
      this.setState({ friendsPopUp: event.currentTarget });
    };

    return (
      <>
        {!this.state.isMobile && (
          <>
            <div className={classes.grow} />

            <div
              className={classes.search + " margin-spacing-right"}
              style={{ width: "500px" }}
            >
              <div className={classes.searchIcon}>
                <img src={searchIcon} alt="serchIcon" />
              </div>
              <div className="search-test">
                <CustomSearchField props={this.props} navigate={this.navigate}
                  data={this.state.selecteDropDownData}
                  searchCustomData={(e: string) => {
                    this.addCustomSearchData(e);
                  }}
                  searchApiCall={() => this.getSearchDropdownData('')}
                />
              </div>
            </div>

            <Tutorials
              current={this.context.tutorials.count}
              data={this.context.tutorials.data}
              onNext={this.context.onNextTutorial}
              isOpen={this.context.tutorials.isOpen && this.context.tutorials.count === 2}
              {...this.props}
            >
              <Button
                style={{ minWidth: "max-content" }}
                className="contained-btn margin-spacing-right"
                variant="contained"
                onClick={() => { this.onclose(true) }}
              >
                Create Room
              </Button>
            </Tutorials>

            <div className={classes.sectionDesktop}>
              <IconButton
                className={classes.creditButton + " margin-spacing-right"}
                onClick={() => {
                  this.props.history.push("/MyWallet");
                  //@ts-ignore
                  this.navigate(this.props.routeMap, this.props?.appProps.match, this.props.appProps.history, "MyWallet")
                }}
              >
                <img height="20px" src={wallet} alt="wallet" />
                <span className="appbar-credit"> {this?.context?.userCoinAmount || this.state.walletYCoins} </span>
              </IconButton>

              {this.friendsPopup()}

              <Box style={{ position: 'relative' }}>
                {hasfriendConnectionList &&
                  <div className={classes.Notification_dot}>
                    <img height="8px" width="8px" src={Notification_dot} alt="dot" />
                  </div>
                }
                <IconButton onClick={handleClick}>
                  <img className="icon-style" src={peopeIcon} />
                </IconButton>
              </Box>

              <Tutorials
                current={this.context.tutorials.count}
                data={this.context.tutorials.data}
                onNext={this.context.onNextTutorial}
                isOpen={this.context.tutorials.isOpen && this.context.tutorials.count === 3}
                {...this.props}
              >
                <Box display="flex" marginRight={(window.location.pathname !== '/Chat' &&
                  this.state.unreadMsgCount > 0) ? "-1rem" : 0}>
                  <IconButton
                    color="inherit"
                    onClick={() => this.props.history.push("/Chat")}
                  >
                    <img className="icon-style" src={Circle} />
                    <img className="message-icon-style" src={Message} />
                  </IconButton>
                  {(window.location.pathname !== '/Chat' &&
                    this.state.unreadMsgCount > 0) && <div className="notificationDot">
                      {this.state.unreadMsgCount}
                    </div>
                  }
                </Box>
              </Tutorials>

              {this.renderNotification()}

              <ProfileDropdown
                history={this.props.history}
                handleLogout={this.handleLogout}
              />
            </div>
            <div className={classes.sectionMobile + " margin-spacing-right"}>
              <IconButton
                aria-label="show more"
                aria-haspopup="true"
                onClick={this.handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>
          </>
        )
        }
      </>
    )
  }

  renderMobileHeader = () => {
    // @ts-ignore
    const { classes } = this.props;

    return (
      <>
        {this.state.isMobile && (
          <>
            <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
              <IconButton
                className={classes.creditButton}
                onClick={() => {
                  this.props.history.push("/MyWallet");
                  //@ts-ignore
                  this.navigate(this.props.routeMap, this.props?.appProps.match, this.props.appProps.history, "MyWallet")
                }}
              >
                <img height="20px" src={wallet} alt="wallet" />
                <span className="appbar-credit"> {this.context && this.context.userCoinAmount ? this.context.userCoinAmount : this.state.walletYCoins} </span>
              </IconButton>

              <Box display="flex" style={{ marginRight: '-.8rem' }}>
                <IconButton
                  color="inherit"
                  onClick={() => this.props.history.push("/Chat")}
                >
                  <img className="icon-style" src={Circle} />
                  <img className="message-icon-style" src={Message} />
                </IconButton>
                {window.location.pathname !== '/Chat' &&
                  this.state.unreadMsgCount > 0 && <div className="notificationDot">
                    {this.state.unreadMsgCount}
                  </div>
                }
              </Box>

              <Box display='flex'>
                <IconButton
                  color="inherit"
                  onClick={this.toggleMobileNotification}
                >
                  <img className="icon-style" src={Circle} />
                  <img className="bell-icon-style" src={Bell} style={{ right: '23px' }} />
                </IconButton>

                {this.state.unreadNotificationCount > 0 && (
                  <div className="notificationDot">
                    {this.state.unreadNotificationCount}
                  </div>
                )}
              </Box>
            </div>
          </>
        )}
      </>
    )
  }

  renderFriendRequestsForMobile = () => {
    return (
      <>
        {this.state.isFriendRequestOpen && (
          <div>
            <h1>Open friend request</h1>
          </div>
        )}
      </>
    )
  }

  renderAppBar = () => {
    // @ts-ignore
    const { classes } = this.props;

    return (
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar>
          {this.state.isMobile && (
            <>
              {this.state.isMobileMenuOpen ?
                <ClearIcon className={classes.mobileDropdownIcon}
                  onClick={() => this.toggleMobileMenu()}
                /> :
                <MenuIcon className={classes.mobileDropdownIcon}
                  onClick={() => this.toggleMobileMenu()}
                />
              }
            </>
          )}
          <img
            className="image-logo"
            height="40px"
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.props.history.push("/Rooms?type=upcoming");
            }}
            src={logo}
            alt="logo"
          />

          {this.renderWebHeader()}

          {this.renderMobileHeader()}

          {this.renderFriendRequestsForMobile()}
        </Toolbar>
      </AppBar>
    )
  }

  renderMobileSearchMenu = () => {
    // @ts-ignore
    const { classes } = this.props;

    return (
      <>
        {this.state.isMobile && (
          <Box style={{ marginTop: '20%' }}
            sx={{ pl: 2, pr: 2 }} display='flex' alignItems='center' justifyContent='space-between'
          >
            <div
              className={classes.search}
              style={{ marginRight: 0, flex: .97 }}
            >
              <div className={classes.searchIcon}>
                <img src={searchIcon} alt="serchIcon" />
              </div>
              <div className="search-test">
                <CustomSearchField props={this.props} navigate={this.navigate}
                  data={this.state.selecteDropDownData}
                  searchCustomData={(e: string) => {
                    this.addCustomSearchData(e);
                  }}
                  searchApiCall={() => this.getSearchDropdownData('')}
                  handleFocus={(value: boolean) => this.setState({ isSearchBarFocused: value })}
                />
              </div>

              {this.state.isSearchBarFocused && (
                <div
                  style={{ position: 'absolute', right: 10, top: 10, zIndex: 99999 }}
                  onClick={() => { this.setState({ isSearchBarFocused: false }) }}
                >
                  <HighlightOffOutlinedIcon
                    style={{ color: '#C1C0BC', opacity: .9 }}
                  />
                </div>
              )}
            </div>

            {!this.state.isSearchBarFocused && (
              <Box>
                <button
                  onClick={() => { this.onclose(true) }}
                  className="contained-btn mobile-btn"
                >
                  Create Room
                </button>
              </Box>
            )}
          </Box>
        )}
      </>
    );
  }

  MobileNotification = () => {
    const classes = modalStyles();

    return (
      <Modal
        open={this.state.openMobileNotification}
        onClose={this.toggleMobileNotification}
        className={classes.modal}
      >
        <Box className={classes.modalContent}>
          <Box textAlign='right' mt={1} mr={1} style={{ cursor: 'pointer' }} onClick={this.toggleMobileNotification}>
            <ClearIcon className="icon-animation" />
          </Box>
          <NotificationList
            notifications={this.state.notifications}
            refetchNotificatons={() => this.setState(prev => ({
              notifications: {
                ...prev.notifications, data: [], currentPage: 1
              }
            }), () => this.fetchAllNotifications())}
            fetchNextNotifications={this.fetchAllNotifications}
            handleClick={this.handleUnreadCount}
            handleDelete={this.handleDeleteNotification}
            handleMarkAllAsRead={this.handleMarkAllAsRead}
            unreadCount={this.state.unreadNotificationCount}
            history={this.props.history}
            isMobile={this.state.isMobile}
          />
        </Box>
      </Modal>
    )
  }

  render() {
    // @ts-ignore
    const { classes } = this.props;

    const localPicture = localStorage.getItem("profile_image");
    const profile_image = (!localPicture || localPicture === 'false') ? NoProfilePic : localPicture;

    const handleMobleNavigation = (path: string) => {
      if (path !== '#') {
        this.toggleMobileMenu();
        this.props.history.push(`/${path}`)
      } else {
        this.toggleFriendRequestShow();
      }
    }

    return (
      <>
        {(this.state.isMobile && this.state.isMobileMenuOpen) && (
          <Draggable
            axis="y"
            bounds={{ top: 0 }}
            onStop={this.toggleMobileMenu}
            handle=".drag-handle"
          >
            <Slide direction="up" in={this.state.isMobileMenuOpen}>
              <Box style={{
                position: 'absolute',
                background: '#fff',
                bottom: 0,
                zIndex: 1300,
                height: 'calc(100dvh - 140px)',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
                sx={{ pl: 2.2, pr: 2.2 }}
              >
                <div className="drag-handle" style={{ height: '3%' }} />

                <Box>
                  {mobileMenu.map((item, index: number) => {
                    return (
                      <Box
                        key={index}
                        display="flex" alignItems="center" sx={{ pb: 4.5 }}
                        onClick={() => handleMobleNavigation(item.link)}
                      >
                        <Box sx={{ pr: .5, width: '1.5rem' }} className={classes.mobileIcons}>
                          {item.icon}
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Typography style={{
                            marginLeft: '1rem', fontSize: '1.2rem',
                            color: '#27272A', fontWeight: 500
                          }}
                          >
                            {item.name}
                          </Typography>
                        </Box>
                      </Box>
                    )
                  })}

                  <Divider />

                  <Box display='flex' alignItems='center' sx={{ mt: 4.5 }}
                    onClick={() => handleMobleNavigation('UserPersonalProfile')}
                  >
                    <Box style={{ width: '1.5rem' }}>
                      <img
                        alt={localStorage.getItem("fullName") as string}
                        style={{
                          height: "30px", width: "30px",
                          cursor: "pointer", borderRadius: "50%",
                          border: '2px solid #FFCB00'
                        }}
                        src={profile_image as string}
                      />
                    </Box>

                    <Box>
                      <Typography style={{
                        marginLeft: '1rem', fontSize: '1.2rem',
                        color: '#010039', fontWeight: 500
                      }}
                      >
                        Profile
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box sx={{ mt: 'auto' }}>
                  <Divider />

                  <Box sx={{ pt: 3.5, pb: 3.5 }}>
                    <Box display="flex" alignItems="center"
                      onClick={this.handleLogout}>
                      <LogoutIcon />
                      <Typography style={{
                        marginLeft: '1rem', fontSize: '1.2rem',
                        color: '#DD5E4A', fontWeight: 500
                      }}
                      >
                        Logout
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Slide>
          </Draggable>
        )}

        <div className={classes.grow}>
          <AlertBlock data={{ show: this.state.isMessagePop, close: this.handleCloseAlert, msg: this.msg, type: this.type, vertical: "top", horizontal: "center" }} />

          {this.renderAppBar()}

          {this.renderMenu()}

          <this.MobileNotification />

          {window.location.pathname === "/Rooms" && this.renderMobileSearchMenu()}

          {this.renderCreateRoomModal()}
        </div >
      </>
    );
  }
}


// @ts-ignore
export default withStyles(useStyles, { withTheme: true })(withRouter(React.memo(Header)));

// Customizable Area End