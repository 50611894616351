// Customizable Area Start
import { IBlock } from '../../../../framework/src/IBlock';
import { Message } from '../../../../framework/src/Message';
import { BlockComponent } from '../../../../framework/src/BlockComponent';
import MessageEnum, {
    getName
} from '../../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../../framework/src/RunEngine';
import React, { FC, useEffect, useState } from 'react';
import { Box, Typography } from "@material-ui/core";
import { withStyles, Theme, WithStyles, createStyles } from '@material-ui/core/styles';
import { TimeIcon } from "../assets";
import { withRouter, RouteComponentProps } from 'react-router-dom';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            flexDirection: 'column',
            padding: '0 1.5rem',
        },
        icon: {
            marginBottom: 25,

            '& svg': {
                width: '20rem',
                height: '20rem'
            }
        },
        title: {
            fontWeight: 600,
            fontSize: '2rem',
            color: '#1E3354',
            [theme.breakpoints.down('xs')]: {
                fontSize: '1rem',
            },
        },
        message: {
            color: '#7F8E9D',
            textAlign: 'center',
            [theme.breakpoints.down('xs')]: {
                fontSize: '0.9rem',
            },
        },
        continueBtn: {
            padding: '1rem 7rem',
            borderRadius: '2rem',
            backgroundColor: '#ffcb00',
            marginTop: '1rem',
            fontWeight: 600,
            cursor: 'pointer',
            transition: 'all .3s ease-in-out',

            '&:hover': {
                scale: 1.05,
                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)',
            },

            "&:active": {
                scale: 0.9,
            }
        }
    });

interface CProps {
    current_dt: string;
}

const CurrentDT: FC<CProps> = ({ current_dt }) => {
    const [date, setDate] = useState(current_dt);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setDate(prevDate => {
                const currentDateTime = new Date(prevDate);
                currentDateTime.setSeconds(currentDateTime.getSeconds() + 1);
                return currentDateTime.toLocaleString();
            });
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [current_dt]);

    return (
        <Box mt={2} height="7rem" minWidth="15rem" textAlign="center">
            <Box
                sx={{
                    mt: 3,
                    mb: 3,
                    border: '1px solid #E0E0E0',
                    p: 1,
                    borderRadius: 10,
                    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)',
                }}
            >
                <Typography
                    style={{
                        color: '#010039',
                        fontWeight: 600,
                        wordBreak: 'break-word',
                    }}
                >
                    {date}
                </Typography>
                <Typography
                    style={{
                        fontSize: '.8rem',
                        color: 'gray',
                    }}
                >
                    Actual date & time
                </Typography>
            </Box>
        </Box>
    );
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps { }
interface S {
    actualDT: string;
}
interface SS { }

type LocationState = {
    state: S
}

class IncorrectTime extends BlockComponent<Props, S, SS> {

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            actualDT: '',
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount(): Promise<void> {
        const actualDT = (this.props.location.state as LocationState).state.actualDT;

        this.setState({ actualDT })
    }

    handleClicked = () => {
        sessionStorage.setItem('isTimeZoneWrong', 'true');

        const redirectRoute = sessionStorage.getItem('redirect-route');
        
        setTimeout(() => {
            if(redirectRoute) {
                this.props.history.push(redirectRoute);
            } else {
                this.props.history.push('/Rooms'); 
            }
        }, 1000);
    }

    render(): React.ReactNode {
        const { classes } = this.props;

        return (
            <Box className={classes.root}>
                <Box className={classes.icon}>
                    <TimeIcon />
                </Box>
                <Typography className={classes.title}>Your device time is incorrect.</Typography>
                <Box mt={2}>
                    <Typography className={classes.message}>Please update your device's time to continue using the app.</Typography>
                </Box>

                {this.state.actualDT && <CurrentDT current_dt={this.state.actualDT} />}

                <Box className={classes.continueBtn} onClick={this.handleClicked}>
                    Continue 
                </Box>
            </Box>
        )
    }
}

export default withStyles(styles)(withRouter(IncorrectTime));

// Customizable Area End
