// Customizable Area Start
import React from 'react';
import { Box, Tooltip, Typography, Zoom, Divider, Hidden } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import './Chat.css';
import Conversations from './Conversations.web';
import {
  profile, EditIcon, MorOptionIcon, SendMessageIcon, MessageBubbles, SearchIcon,
} from '../assets';
import Skeleton from '@material-ui/lab/Skeleton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import ChatController, { Props } from '../Chat/ChatController';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { withRouter } from 'react-router-dom';
import Messages from './Messages.web';
import ConfirmationModal from './ConfirmationModal';
import CreateIcon from '@material-ui/icons/Create';
const crypto = require('crypto');
// Customizable Area End

const CustomToolTip = withStyles({
  arrow: {
    fontSize: 25,

    '&::before': {
      border: '1px solid #ccc',
      backgroundColor: '#fff',
    },
  },
  tooltip: {
    color: '#acaba9',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    width: '15rem',
    padding: 0,
    borderRadius: '.5rem',
  },
})(Tooltip);

interface OptionsProps {
  onDisconnect: () => void;
  showRooms: () => void;
}

const MoreOptions: React.FC<OptionsProps> = ({ onDisconnect, showRooms }) => {
  return (
    <Box>
      <Box p={1.5} style={{ cursor: 'pointer' }} onClick={onDisconnect}>
        <Typography style={{ fontWeight: 500 }}>Disconnect</Typography>
      </Box>
      <Divider style={{ margin: 0 }} />
      <Box p={1.5} style={{ cursor: 'pointer' }} onClick={showRooms}>
        <Typography style={{ fontWeight: 500 }}>Their Rooms</Typography>
      </Box>
    </Box>
  );
};

function generateRandomNumber() {
  const min = 5;
  const max = 7;
  const range = max - min + 1;
  const randomBytes = crypto.randomBytes(4); // 4 bytes = 32 bits
  const randomInt = randomBytes.readUInt32LE(0); // read as a 32-bit little-endian integer
  return min + (randomInt % range);
}


function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function displaySearchBar(
  isVisible: boolean,
  searchKeyword: string,
  onSearch: (_: string) => void
) {
  return (
    <>
      {isVisible && (
        <Box
          display="flex"
          alignItems="center"
          mt={1.5}
          bgcolor="#FBFBFB"
          p={1.5}
          border="1px solid #E0E0E0"
          borderRadius="9px"
        >
          <SearchIcon style={{ marginRight: '.5rem' }} />
          <input
            type="text"
            placeholder="Search..."
            style={{
              border: 'none',
              outline: 'none',
              width: '100%',
              background: 'inherit',
            }}
            className="search_input"
            value={searchKeyword}
            onChange={(e) => onSearch(e.target.value)}
            autoFocus
          />
        </Box>
      )}
    </>
  )
}

function loadUserSkeleton(isUsersLoading: boolean, randomNumber: number) {
  return (
    <>
      {isUsersLoading && (
        <Box mt={3}>
          {Array(randomNumber).fill(0).map((_, i) => (
            <Box mb={1} key={i}
              display='flex' bgcolor='#efefef'
              pt={1.5} pb={1.5} pl={1} pr={1} borderRadius='.7rem'
            >
              <Skeleton variant="circle" width={50} height={50}
                style={{ marginRight: '.5rem' }}
              />
              <Box flex={1}>
                <Box flex={1} display='flex' justifyContent='space-between' mt={1}>
                  <Skeleton variant="rect" width={120} height={15} animation="wave" />
                  <Skeleton variant="rect" width={100} height={15} animation="wave" />
                </Box>
                <Skeleton variant="rect" width={150} height={10}
                  style={{ marginTop: '1rem' }}
                />
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </>
  )
}

function renderNoChatsFound(isMobile: boolean, isUsersLoading: boolean, filterUsers: any[]) {
  return (
    <>
      {isMobile &&
        (!isUsersLoading && filterUsers.length === 0) && (
          <Box mt={3}>
            <Typography variant="h6" align="center">
              No Conversations Found.
            </Typography>
            <Typography variant="body1" align="center" style={{ color: '#ccc' }}>
              Make some connections to start chatting.
            </Typography>
          </Box>
        )}
    </>
  )
}

function showSelectConversation(selectedConversation: any, props: Props, state: any) {
  return (
    <>
      {!state.hasAlreadyChat &&
        selectedConversation && (
          <Conversations
            {...props}
            navigation={props.navigation}
            profile_img={state.chattingWith.profile_image}
            user_name={state.chattingWith.full_name}
            last_msg={state.chattingWith.last_message}
            isSelected={true}
            isTyping={
              state.typing.conversation_id ===
              state.chattingWith.conversation_id &&
              state.typing.isTyping
            }
          />
        )}
    </>
  )
}

function showSendMessage(
  selectedConversation: any,
  isMobile: boolean, handleChange: any, sendMessage: any,
  inputRef: React.RefObject<HTMLInputElement>,
  handleMobileSend: any
) {
  const profile_pic = localStorage.getItem('profile_image');

  return (
    <>
      {selectedConversation && (
        <Box
          pl={isMobile ? 1.5 : 3}
          component="form"
          className="chatBoxBottom"
          mb={5}
          height={60}
          onSubmit={sendMessage}
          // use none, so it'll override the default CSS
          style={{
            width: isMobile ? '94%' : 'none',
            marginLeft: isMobile ? '.8rem' : 'none',
            marginBottom: isMobile ? '.8rem' : 'none',
          }}
        >
          <img
            src={
              profile_pic === 'false'
                ? profile
                : (profile_pic as string)
            }
            alt="user_img"
            style={{
              width: '2.5rem',
              height: '2.5rem',
              borderRadius: '50%',
              objectFit: 'cover',
            }}
          />

          <input
            type="text"
            placeholder="Your Message..."
            style={{
              marginLeft: '.5rem',
              border: 'none',
              outline: 'none',
              width: '100%',
            }}
            onChange={handleChange}
            autoFocus
            ref={inputRef}
          />

          <button
            type="submit"
            className="sendbutton"
            onClick={handleMobileSend}
          >
            <SendMessageIcon />
          </button>
        </Box>
      )}
    </>
  )
}

function showFallbackMobile(isMobile: boolean) {
  return (
    <>
      {isMobile && (
        <Box textAlign='center' mt={1}>
          <Typography style={{ color: '#acaba9', fontSize: '.8rem' }}>
            Chats Visible only for 24 hours
          </Typography>
        </Box>
      )}</>
  )
}

function showFallbackWeb(selectedConversation: any, users: any[]) {
  return (
    <>
      {!selectedConversation && (
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <MessageBubbles />
          <Typography
            style={{
              fontSize: '1.5rem',
              fontWeight: 500,
              color: '#0F0F0F',
              lineHeight: '2.25rem',
            }}
          >
            Choose a conversation to start.
          </Typography>
          <Typography
            style={{
              color: '#CCCCCC',
              fontSize: '1rem',
              fontWeight: 500,
              lineHeight: '1.5rem',
              marginTop: '.3rem',
            }}
          >
            {users.length > 0
              ? 'You can choose a conversation by clicking the user name.'
              : 'Make some connections to start chatting.'}
          </Typography>
        </Box>
      )}
    </>
  )
}

function showMessages(selectedConversation: any, state: any) {
  return (
    <>
      {selectedConversation && (
        <Messages
          messages={state.chattingWith.messages}
          isLoading={state.loading}
          selected_user={state.chattingWith}
        />
      )}
    </>
  )
}

function showTypingIndicator(state: any, isMobile: boolean) {
  const first_name = state.chattingWith.full_name?.split(' ')[0];

  return (
    <>
      {state.chattingWith.user_id &&
        state.typing.conversation_id ===
        state.chattingWith.conversation_id &&
        state.typing.isTyping && (
          <Box pl={isMobile ? 2 : 5} display="flex" alignItems="center">
            <CreateIcon
              style={{
                fill: '#fed227',
                fontSize: '20',
                marginRight: '.5rem',
              }}
            />
            <img
              src={state.chattingWith.profile_image || profile}
              alt={`${state.chattingWith.user_id}_profile_pic`}
              style={{
                width: '2rem',
                height: '2rem',
                borderRadius: '50%',
                objectFit: 'cover',
              }}
            />
            <Typography
              style={{ marginLeft: '.5rem', fontWeight: 600 }}
            >
              {first_name.length > 7 ? first_name.slice(0, 7) : first_name ||
                state.chattingWith ||
                'Someone'}{' '}
              is typing....
            </Typography>
          </Box>
        )}
    </>
  )
}

class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  randomNumber: number;
  async componentDidMount() {
    // setting up current user
    const user = this.props.history?.location?.state?.user;
    this.randomNumber = generateRandomNumber();


    if (user) {
      const c_id = sessionStorage.getItem('conversation_sid') as string;
      const user_id = Number(localStorage.getItem('selectedUserId') as string);
      this.setState(
        (prev) => ({
          chattingWith: {
            ...prev.chattingWith,
            profile_image: user.profile_image,
            user_name: user.user_name,
            conversation_id: c_id,
            full_name: user.full_name,
            user_id,
          },
          coming_from_profile: true,
        }),
        () => {
          this.loadMessages();
        }
      );
    }

    // load users
    this.setState({ isUsersLoading: true });
    this.loadUsers();
    // fetch user friends
    this.fetchFriends();

    // ask for notification permission
    Notification.requestPermission().then((perm) => {
      if (perm === 'denied') return;
    });

    // set Listener for window focus
    document.addEventListener('visibilitychange', this.handleVisibilityChange);

    // mobile responsive
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }
 
  async componentWillUnmount() {
    // clean Event Listener
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange
    );

    // clean twilo connection if exists
    if (this.conversationsClient) {
      this.conversationsClient.removeAllListeners();
      this.conversationsClient = null;
    } 

    window.removeEventListener('resize', this.handleResize);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const profile_pic = localStorage.getItem('profile_image');
    const { isMobile, selectedConversation } = this.state;

    // left side component
    const LeftSide = () => (
      <Box flex={1} pr={isMobile ? 1.5 : 3}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography style={{ fontSize: '1.1rem', fontWeight: 500 }}>
            Messages
          </Typography>
          <EditIcon
            style={{ cursor: 'pointer' }}
            onClick={this.toggleSearchBar}
          />
        </Box>

        {/* Search Bar */}
        {displaySearchBar(this.state.search.isVisible, this.state.search.searchKeyword, this.onSearch)}

        {/* user loading Skeleton */}
        {loadUserSkeleton(this.state.isUsersLoading, this.randomNumber)}

        {/* Users Lists */}
        <Box pt={2} pr={1} height="95%" style={{ overflowY: 'auto' }}>
          {showSelectConversation(selectedConversation, this.props, this.state)}

          {this.state.filterUsers.map((user) => {
            const isSelected = this.state.chattingWith.conversation_id
              ? user.conversation_sid ===
              this.state.chattingWith.conversation_id
              : false;

            if (!user.is_connected) return;

            return (
              <Conversations
                key={user.user_data.id}
                {...this.props}
                navigation={this.props.navigation}
                profile_img={user.profile_image}
                user_name={user.user_data.full_name}
                last_msg={
                  isSelected
                    ? this.state.chattingWith.last_message || user.body
                    : user.body
                }
                isSelected={isSelected}
                handleClick={this.handleSelectConversation}
                conversation_sid={user.conversation_sid}
                last_msg_time={user?.created_at || ''}
                isTyping={
                  this.state.typing.conversation_id ===
                  user.conversation_sid && this.state.typing.isTyping
                }
                unread_count={user?.unread || 0}
                user_id={user.user_data.id}
              />
            );
          })}

          {renderNoChatsFound(isMobile, this.state.isUsersLoading, this.state.filterUsers)}
        </Box>
      </Box>
    )

    const RideSide = () => {
      const first_name = this.state.chattingWith.full_name?.split(' ')[0];
      return (
        <Box flex={4} bgcolor="#fff" pt={2} pr={isMobile ? 1.5 : 3}>
          {!isMobile && (
            <div
              id="border-left"
              style={{
                borderLeft: '1px solid #D7D7D7',
                position: 'absolute',
                top: '30%',
                bottom: 0,
              }}
            />
          )}

          {selectedConversation && (
            <>
              <Box
                display="flex"
                justifyContent={isMobile ? 'flex-start' : "space-between"}
                alignItems="center"
                pl={isMobile ? 1 : 3}
              >
                {/* back button for mobile */}
                {isMobile && (
                  <Box mr={1}>
                    <KeyboardBackspaceIcon
                      onClick={() => this.setState(prev => ({
                        selectedConversation: null,
                        chattingWith: ({ ...prev.chattingWith, conversation_id: '' })
                      }))}
                    />
                  </Box>
                )}

                <Box
                  display="flex"
                  alignItems="center"
                  onClick={() =>
                    this.props.history.push('UserProfile', {
                      userId: this.state.chattingWith.user_id,
                    })
                  }
                  style={{ cursor: 'pointer' }}
                >
                  <img
                    src={this.state.chattingWith.profile_image || profile}
                    alt="user_img"
                    style={{
                      width: '3rem',
                      height: '3rem',
                      borderRadius: '50%',
                      objectFit: 'cover',
                    }}
                  />
                  <Box pl={1}>
                    <Typography style={{ fontWeight: 'bold' }}>
                      {this.state.chattingWith.full_name}
                    </Typography>
                    {this.state.typing.conversation_id ===
                      this.state.chattingWith.conversation_id &&
                      this.state.typing.isTyping && (
                        <Typography
                          style={{
                            fontSize: '90%',
                            color: '#fed227',
                            fontWeight: 500,
                          }}
                        >
                          Typing....
                        </Typography>
                      )}
                  </Box>
                </Box>
                {!isMobile && (
                  <Box mt="auto">
                    <Typography style={{ color: '#acaba9' }}>
                      Chats Visible only for 24 hours
                    </Typography>
                  </Box>
                )}
                <CustomToolTip
                  title={
                    <MoreOptions
                      onDisconnect={this.toggleConfirmation}
                      showRooms={this.handleShowRooms}
                    />
                  }
                  open={this.state.isMenuOpen}
                  onClick={this.handleMenuOpen}
                  onClose={this.handleMenuClose}
                  TransitionComponent={Zoom}
                  arrow
                  interactive
                >
                  <MorOptionIcon style={{ cursor: 'pointer', marginLeft: isMobile ? 'auto' : 'inherit' }} />
                </CustomToolTip>
              </Box>
            </>
          )}

          {showFallbackMobile(isMobile)}

          {/* chats */}
          <Box
            pt={2}
            pl={isMobile ? 1.5 : 3}
            className={this.state.typing.isTyping ? 'isTyping' : 'messages'}
            sx={{ bgcolor: '#fff', pr: isMobile ? 0 : '1rem' }}
            style={{ overflowY: 'auto' }}
          >
            {showMessages(selectedConversation, this.state)}

            {showFallbackWeb(selectedConversation, this.state.users)}
          </Box>

          {/* typing indicator */}
          {this.state.chattingWith.user_id &&
            this.state.typing.conversation_id ===
            this.state.chattingWith.conversation_id &&
            this.state.typing.isTyping && (
              <Box pl={5} display="flex" alignItems="center">
                <CreateIcon
                  style={{
                    fill: '#fed227',
                    fontSize: '20',
                    marginRight: '.5rem',
                  }}
                />
                <img
                  src={this.state.chattingWith.profile_image || profile}
                  alt={`${this.state.chattingWith.user_id}_profile_pic`}
                  style={{
                    width: '2rem',
                    height: '2rem',
                    borderRadius: '50%',
                    objectFit: 'cover',
                  }}
                />
                <Typography
                  style={{ marginLeft: '.5rem', fontWeight: 600 }}
                >
                  {first_name.length > 7 ? first_name.slice(0, 7) : first_name ||
                    this.state.chattingWith ||
                    'Someone'}{' '}
                  is typing....
                </Typography>
              </Box>
            )}

          {/* send message */}
          {selectedConversation && (
            <Box
              pl={isMobile ? 1.5 : 3}
              component="form"
              className="chatBoxBottom"
              mb={5}
              height={60}
              onSubmit={this.sendMessage}
              // use none, so it'll override the default CSS
              style={{
                width: isMobile ? '94%' : 'none',
                marginLeft: isMobile ? '.8rem' : 'none',
                marginBottom: isMobile ? '.8rem' : 'none',
              }}
            >
              <img
                src={
                  profile_pic === 'false'
                    ? profile
                    : (profile_pic as string)
                }
                alt="user_img"
                style={{
                  width: '2.5rem',
                  height: '2.5rem',
                  borderRadius: '50%',
                  objectFit: 'cover',
                }}
              />

              <input
                type="text"
                placeholder="Your Message..."
                style={{
                  marginLeft: '.5rem',
                  border: 'none',
                  outline: 'none',
                  width: '100%',
                }}
                onChange={this.handleChange}
                value={this.state.senderMsg}
                autoFocus
                ref={this.inputRef}
              />

              <button
                type="submit"
                className="sendbutton"
                disabled={!this.state.senderMsg.trim().length}
              >
                <SendMessageIcon />
              </button>
            </Box>
          )}
        </Box>
      )
    }

    // dynamic padding for mobile screen
    let pl;
    if (isMobile && selectedConversation) {
      pl = 0;
    } else if (isMobile) {
      pl = 1.5;
    } else {
      pl = 3;
    }

    return (
      <>
        <Box>
          {/* confirmation Modal */}
          {this.state.isConfirmationOpen && (
            <ConfirmationModal
              isOpen={this.state.isConfirmationOpen}
              handleClose={this.toggleConfirmation}
              userChoice={this.handleDisconnect}
            />
          )}

          {/* main container */}
          <Box
            sx={{ pt: 10, pl, bgcolor: '#fbfbfb', height: '100%' }}
            display="flex"
          >
            {/* For Desktop Screen */}
            <Hidden smDown>
              <LeftSide />
              <RideSide />
            </Hidden>

            {/* For Mobile Screen */}
            <Hidden mdUp>
              {selectedConversation ? <RideSide /> : <LeftSide />}
            </Hidden>
          </Box>
        </Box>

        <Snackbar
          open={this.state.snackbar.isOpen} // Client don't want to show any progress alert.
          autoHideDuration={5000}
          onClose={this.handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={this.handleClose} severity={this.state.snackbar.type}>
            {this.state.snackbar.message}
          </Alert>
        </Snackbar>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// @ts-ignore
export default withRouter(Chat);
// Customizable Area End
