// Customizable Area Start
import React from 'react';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import MenuList from './menuList.web';
import './VideoChatScreen.css';
import DropdownmenuController, { Props } from './dropdownmenuController';
import { FiUserX } from 'react-icons/fi';
import { withRouter } from 'react-router-dom';

export const checkDifference = (start_time: Date, current_time: Date) => {
	let diffInMs = start_time.getTime() - current_time.getTime();
	let diffInMins = Math.floor(diffInMs / 60000); // Divide by 60,000 to convert milliseconds to minutes and round down

	return Math.abs(diffInMins);
};

class Dropdownmenu extends DropdownmenuController {
	constructor(props: Props) {
		super(props);
	}

	alertOptions = [
		'Going off-topic',
		'Speaking for too long',
		'Being insensitive',
		'Interrupting',
		'Unsolicited advice'
	];
	askToLeaveOptions = [
		'Offensive language or gesture',
		'Arguing / debating / ridiculing',
		'Harassing / bullying',
		'Having no relevance to the topic '
	];

	alertMenu = (shouldShowOptions: boolean,
		is_alert_count_less_than_two: boolean,
		is_self_leave_count_zero: boolean
	) => {
		const diff = checkDifference(new Date(this.props.startTime), new Date());
		let is15MinPassed = false;
		if (diff > 15) {
			is15MinPassed = true;
		}

		return (
			<div>
				<MenuList
					color={is_alert_count_less_than_two ? 'black' : '#D3D3D3'}
					icon={<ErrorOutlineIcon fontSize='small' />}
					value='Alert'
					handlerFunction={this.handleAlert}
					param='alert'
					lastOne={!shouldShowOptions || !is15MinPassed}
				/>
				{!shouldShowOptions ? null : !is15MinPassed ? null :
					this.state.leaveRequest ? (
						<MenuList
							color={'black'}
							icon={<FiUserX size={20} />}
							value='Retract leave request'
							handlerFunction={this.handleRetractRequest}
							param='retract'
							lastOne={true}
						/>
					) : (
						<MenuList
							color={is_self_leave_count_zero ? 'black' : '#D3D3D3'}
							icon={<FiUserX size={20} />}
							value='Ask to leave'
							handlerFunction={
								is_self_leave_count_zero
									? this.handleMenu
									: this.props.handleMaxLeave
							}
							param='leave'
							lastOne={true}
						/>
					)}
			</div>
		)
	}

	mainMenu = (shouldShowOptions: boolean) => {
		return (
			<Menu
				id='simple-menu'
				anchorEl={this.state.anchorEl}
				open={Boolean(this.state.anchorEl)}
				onClose={this.handleClose}
				getContentAnchorEl={null}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				{this.state.activeMenu === 'main' && this.alertMenu(
					shouldShowOptions, this.state.alert_request_sent_count <= 2,
					this.props.selfLeaveCount === 0
				)}

				{this.state.activeMenu === 'alert' &&
					this.alertOptions.map((option: string, i: number, arr: any) => (
						<MenuList
							key={i}
							color={'black'}
							icon={null}
							value={option}
							handlerFunction={this.handleSubMenu}
							param={option}
							lastOne={arr.length - 1 === i}
						/>
					))}

				{this.state.activeMenu === 'leave' &&
					this.askToLeaveOptions.map((option: string, i: number, arr: any) => (
						<MenuList
							key={i}
							color={'black'}
							icon={null}
							value={option}
							handlerFunction={this.handleSubMenu}
							param={option}
							lastOne={arr.length - 1 === i}
						/>
					))}
			</Menu>
		)
	}

	render() {
		const isCurrentUser = parseInt(localStorage.getItem('userId') || "0") === this.props.accountId;
		let is_host: boolean = false;
		let is_moderator: boolean = false;
		if (this.props.currentUser) {
			is_host = this.props.currentUser.is_host;
			is_moderator = this.props.currentUser.is_moderator;
		}
		const shouldShowOptions = !this.props.isRoomModarated ? true : this.props.isRoomModarated && is_host && is_moderator;

		return (
			<div>
				{!isCurrentUser &&
					<button id='kebab-menu' onClick={this.handleClick} style={{ cursor: "pointer" }}>
						<MoreVertIcon fontSize='inherit' />
					</button>
				}

				{this.state.anchorEl && (
					this.mainMenu(shouldShowOptions)
				)}
			</div>
		);
	}
}

export default withRouter(Dropdownmenu);
// Customizable Area End
